import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ConstantImage } from 'app/shared/enum/constant-image';
import { LogEventName } from 'app/shared/enum/log-event-name';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Favicons } from 'app/favicons';
import { LogEventService, Util } from 'app/shared';
import { ApiService } from 'app/shared/service/api.service';
import { FirebaseSavePointService } from 'app/shared/service/firebase-save-point.service';
import { LogoutDialogComponent } from 'app/user_app/mobile-account-menu/log-out-dialog/logout-dialog.component';

@Component({
    selector: 'app-mobile-account-menu',
    templateUrl: './mobile-account-menu.component.html',
    styleUrl: './mobile-account-menu.component.scss',
})
export class MobileAccountMenuComponent implements OnInit {
    currentUser;
    primaryPrincipal;
    listType: string;
    removeCloseBtn = false;
    constantImage = ConstantImage;
    orgSetting = JSON.parse(localStorage.getItem('orgSetting'));
    logEventName: any = LogEventName;
    expandList = localStorage.getItem('myAccountListExpanded')
        ? JSON.parse(localStorage.getItem('myAccountListExpanded'))
        : false;
    activeMyAccountList = [];
    showHelpMyAccount: boolean = false;
    otherMyAccountList = [
        {
            label: this.translateService.instant('LEARNER.TROUBLESHOOT_NOTIF'),
            imageSrc: 'assets/img/troubleshootNotification.png',
            routerLink: '/app/troubleshootNotification',
            logEvent: this.logEventName.TROUBLESHOOT_NOTIFICATION,
        },
        {
            label: this.translateService.instant('LEARNER.ABOUT_RAPL'),
            imageSrc: 'assets/img/aboutRapLIcon.png',
            routerLink: '/app/aboutRapl',
            logEvent: this.logEventName.ABOUT_RAPL,
        },
    ];
    constructor(
        public api: ApiService,
        private readonly _bottomSheetRef: MatBottomSheetRef<MobileAccountMenuComponent>,
        public favicons: Favicons,
        public dialog: MatDialog,
        public router: Router,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        public translateService: TranslateService,
        public sanitizer: DomSanitizer,
        public firebaseSavePointService: FirebaseSavePointService,
        public logEventService: LogEventService,
    ) {}

    closeBottomSheet(): void {
        this.removeCloseBtn = true;
        this._bottomSheetRef.dismiss();
    }

    ngOnInit() {
        this.listType = this.data.listType;
        this.currentUser = JSON.parse(localStorage.getItem('current_user'));
        if (this.currentUser) {
            this.primaryPrincipal = this.currentUser.org.primary_principal;
        }
        this.api.getChangeNotification().subscribe(() => {
            this.getMyAccountList();
        });
        this.getMyAccountList();
    }

    getMyAccountList() {
        this.orgSetting = JSON.parse(localStorage.getItem('orgSetting'));
        this.activeMyAccountList = [
            {
                viewValue: 'Profile',
                label: this.translateService.instant('LEARNER.PROFILE'),
                imageSrc: 'assets/img/profileIcon.png',
                routerLink: '/app/myProfile',
                logEvent: this.logEventName.MY_ACCOUNT_FROM_MENU,
            },
            {
                viewValue: 'History',
                label: this.translateService.instant('LEARNER.HISTORY'),
                imageSrc: 'assets/img/myAccMenuHistory.png',
                routerLink: '/app/history',
                logEvent: this.logEventName.MY_LEARNING,
            },
            {
                viewValue: 'Help',
                label: this.translateService.instant('LEARNER.HELP'),
                imageSrc: 'assets/img/reportAnIssueIcon.png',
                routerLink: '/app/reportIssue',
                logEvent: this.logEventName.HELP_IN_ACCOUNT_PROFILE,
            },
        ];
        const formProperty = Util.filterArray(this.orgSetting.settings, 'property', 'enable_form');

        if (formProperty) {
            this.activeMyAccountList.push({
                viewValue: 'Forms',
                label: this.translateService.instant('LEARNER.FORMS'),
                imageSrc: 'assets/img/formsIcon.png',
                routerLink: '/app/forms',
                logEvent: this.logEventName.FORM_CLICKED,
            });
        }
        const subscriptionProperty = Util.filterArray(
            this.orgSetting.settings,
            'property',
            'subscription_enabled',
        );
        if (subscriptionProperty?.value) {
            this.activeMyAccountList.push({
                viewValue: 'Subscription',
                label: this.translateService.instant('LEARNER.SUBSCRIPTIONS'),
                imageSrc: 'assets/img/subscription.png',
                routerLink: '/app/mysubscription',
                logEvent: this.logEventName.MY_SUBSCRIPTION,
            });
        }
        const feedbackProperty = Util.filterArray(
            this.orgSetting.settings,
            'property',
            'feedback_enabled',
        );
        if (feedbackProperty?.value) {
            this.activeMyAccountList.push({
                viewValue: 'Feedback',
                label: this.translateService.instant('LEARNER.FEEDBACK'),
                imageSrc: 'assets/img/feedback.png',
                routerLink: '/app/myfeedback',
                logEvent: this.logEventName.FEEDBACK_FROM_MENU,
            });
        }
        const vacationsProperty = Util.filterArray(
            this.orgSetting.settings,
            'property',
            'allow_self_vacation_request',
        );
        if (vacationsProperty?.value) {
            this.activeMyAccountList.push({
                viewValue: 'Pause learning',
                label: this.translateService.instant('LEARNER.PAUSE_LEARNING'),
                imageSrc: 'assets/img/myVacationEmptyImg.png',
                routerLink: '/app/pauseLearning',
                logEvent: this.logEventName.MY_VACATION,
            });
        }
        const eventsProperty = Util.filterArray(
            this.orgSetting.settings,
            'property',
            'enable_events',
        );
        if (eventsProperty?.value) {
            this.activeMyAccountList.push({
                viewValue: 'Events',
                label: this.translateService.instant('LEARNER.EVENTS'),
                imageSrc: 'assets/img/eventsEmptyMSGImg.png',
                routerLink: '/app/events',
                logEvent: this.logEventName.EVENT_CLICKED,
            });
        }
        this.activeMyAccountList.sort((a, b) => a.viewValue.localeCompare(b.viewValue));

        return this.activeMyAccountList;
    }

    checkExpandView(index: number) {
        return this.expandList || index < 4;
    }

    expandCollapseList() {
        this.expandList = !this.expandList;
        localStorage.setItem('myAccountListExpanded', this.expandList.toString());
    }

    mobileLogoutModal() {
        this.logEventService.logEvent(this.logEventName.LOGOUT_REQUESTED, {});
        const dialogRef = this.dialog.open(LogoutDialogComponent, {
            maxWidth: '90vw',
            width: '300px',
            panelClass: 'mobie-logout-dialog',
            minHeight: '200px',
        });
        dialogRef.afterClosed().subscribe(() => {
            this.closeBottomSheet();
        });
    }

    logEvent(listItem) {
        if (listItem.logEvent) {
            this.logEventService.logEvent(listItem.logEvent, {});
        }
        this.closeBottomSheet();
    }

    enableMobileHeaderAndFooter() {
        return (
            (this.isHomeActive(true) ||
                this.isLearnerInsightActive(true) ||
                this.isLibraryActive(false) ||
                this.isMySocreActive(false) ||
                this.isMobileInvidLbActive(true) ||
                this.isMobileTeamLbActive(true) ||
                this.isProfileActive(true)) &&
            JSON.parse(localStorage.getItem('hide_mobile_top_bottom_tab')) !== true
        );
    }

    isHomeActive(exact) {
        return this.router.isActive('/app/home', exact);
    }

    isLearnerInsightActive(exact) {
        return this.router.isActive('/app/learnerInsight', exact);
    }

    isLibraryActive(exact) {
        return this.router.isActive('/app/library', exact);
    }

    isMySocreActive(exact) {
        return this.router.isActive('/app/myScore', exact);
    }

    isMobileInvidLbActive(exact) {
        return this.router.isActive('/app/mobile/individualleaderboard', exact);
    }

    isMobileTeamLbActive(exact) {
        return this.router.isActive('/app/mobile/teamleaderboard', exact);
    }

    isProfileActive(exact) {
        return this.router.isActive('/app/myAccount', exact);
    }
}
