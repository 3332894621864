import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UploadSpinnerService } from './upload-spinner.service';
@Component({
    selector: 'app-upload-spinner-component',
    templateUrl: './upload-spinner.component.html',
})
export class UploadSpinnerComponent {
    public active: boolean;
    constructor(
        public uploadSpinner: UploadSpinnerService,
        public router: Router,
    ) {
        uploadSpinner.status.subscribe((status: boolean) => {
            this.active = status;
        });
    }
    isManageActive() {
        return this.router.isActive('/admin', false);
    }
}
