import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionService } from 'app/shared/service/permission.service';
import { ChunkInitializationService } from './chunk-initialization.service';

@Component({
    selector: 'app-chunk-initialization-component',
    templateUrl: './chunk-initialization.component.html',
    styleUrls: ['./chunk-initialization.component.scss'],
})
export class ChunkInitializationComponent {
    activeModule;
    public active: boolean;
    public constructor(
        public chunkService: ChunkInitializationService,
        public router: Router,
        public permission: PermissionService,
    ) {}

    getChunkStatus() {
        return localStorage.getItem('showChunkInitialization');
    }
}
