import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'app/shared';
import { SessionTimeoutService } from 'app/shared/service/session-timeout.service';

@Component({
    selector: 'app-session-timer-modal',
    templateUrl: './session-timer-modal.component.html',
    styleUrls: ['./session-timer-modal.component.scss'],
})
export class SessionTimerModalComponent implements OnInit, OnDestroy {
    sessionTimeOutLeftText: any;
    sessionTimerLeft: any;
    constructor(
        public data: DataService,
        public sessionTimeoutService: SessionTimeoutService,
    ) {}
    ngOnInit() {
        document.querySelector('html').classList.add('global-scrollblock');
        this.getSessionTimeLeft();
    }
    ngOnDestroy() {
        document.querySelector('html').classList.remove('global-scrollblock');
        localStorage.removeItem('showSessionTimeoutModal');
    }
    getSessionTimeLeft() {
        const sessionTimerCount = setInterval(() => {
            if (localStorage.getItem('sessionTimeoutReminder') === null) {
                clearInterval(sessionTimerCount);
            } else {
                if (localStorage.getItem('sessionTimeoutDateTime') === null) {
                    clearInterval(sessionTimerCount);
                }
                const sessionTimeoutDateTime: any = new Date(
                    localStorage.getItem('sessionTimeoutDateTime'),
                );
                const currDateTime: any = new Date();
                let delta = Math.abs(sessionTimeoutDateTime - currDateTime) / 1000;
                const minutes = Math.floor(delta / 60) % 60;
                delta -= minutes * 60;
                const seconds = Math.floor(delta % 60);
                this.sessionTimeOutLeftText = `${minutes} min ${seconds}  secs`;
                const sessionDiff = Math.floor(
                    (sessionTimeoutDateTime.getTime() - currDateTime.getTime()) / 1000,
                );
                if (sessionDiff <= 0) {
                    clearInterval(sessionTimerCount);
                    this.closeSessionModal('logout');
                }
            }
        }, 1000);
    }

    closeSessionModal(event) {
        localStorage.removeItem('showSessionTimeoutModal');
        if (event === 'logout') {
            this.sessionTimeoutService.sessionLogout();
        } else {
            const currDateTime = new Date();
            localStorage.setItem('lastActiveTime', currDateTime.toString());
            localStorage.removeItem('sessionTimeoutDateTime');
            this.sessionTimeoutService.resetSessionTimer();
        }
    }
}
