import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Favicons } from 'app/favicons';
import { ApiService } from 'app/shared/service/api.service';
import { TransService } from 'app/shared/translate/translate.service';

@Component({
    templateUrl: './logout-dialog.component.html',
    styleUrls: ['./logout-dialog.component.scss'],
})
export class LogoutDialogComponent {
    constructor(
        public translate: TransService,
        public api: ApiService,
        public favicons: Favicons,
        public dialogRef: MatDialogRef<LogoutDialogComponent>,
    ) {}
    logout() {
        this.api.deleteFcmToken();
        this.api.post('logout', {}, { show_success: true, show_loader: true }).subscribe({
            next: () => {
                this.translate.changeLang('EN');
                this.favicons.activate('default');
                this.api.logout();
            },
            error: (err: any) => {
                console.log('Logout api error', err);
                this.favicons.activate('default');
                this.api.logout();
            },
        });
        this.dialogRef.close();
    }
    close() {
        this.dialogRef.close();
    }
}
