import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
@Injectable()
export class TitleService {
    public constructor(public titleService: Title) {}
    // setting the ttile
    setDocTitle(title: string) {
        this.titleService.setTitle(title);
    }
}
