@if (show) {
  <div class="top-notification confirm-box fixed-top">
    <div class="confirm-box-outer" [ngClass]="{ 'confirm-ease-out-animation': showDismissPopUpAnimation, 'confirm-ease-in-animation': !showDismissPopUpAnimation }">
      <div
        class="media"
        [ngClass]="{
          'warning-confirm':
            confirm.dialogType === 'delete-cancel' ||
            confirm.dialogType === 'remove-cancel' ||
            confirm.dialogType === 'inactive-cancel' ||
            confirm.dialogType === 'archive-cancel' ||
            confirm.dialogType === 'reset-cancel',
          'warning-confirm-box': confirm.dialogType === 'warning-confirm-cancel' || confirm.dialogType === 'keep-leave' || confirm.dialogType === 'warning-got-it',
        }">
        <div class="media-body">
          <h5 class="mt-0 rapl-bold">{{ confirm.dialogHeader | translate }}</h5>
          <p class="mb-4" [innerHTML]="confirm.dialogMessage | translate"></p>
          @if (confirm.dialogNote) {
            <p class="alert alert-primary">{{ confirm.dialogNote | translate }}</p>
          }
          @if (confirm.showCheckBox) {
            <div class="custom-checkbox">
              <mat-checkbox name="checkBoxLabel" class="clone-checkbox-label" [(ngModel)]="confirm.checkBoxValue">
                <small class="px-0 mx-0">{{ confirm.checkBoxLabel }}</small>
              </mat-checkbox>
            </div>
          }
          @if (!confirm.dialogType || confirm.dialogType === 'warning-confirm-cancel') {
            <span class="row m-0 justify-content-end">
              <div class="col-md-12 text-end">
                <button
                  type="button"
                  class="btn btn-secondary btn-link me-1 sentence-case btn-link-ripple"
                  [innerHtml]="shouldTranslate() ? ('LEARNER.ACTION_BTN.DISMISS' | translate) : 'Dismiss'"
                  (click)="confirmButton(false); showDismissPopUpAnimation = true">
                  Dismiss
                </button>
                <button
                  type="button"
                  class="btn btn-primary mt-0 sentence-case"
                  [innerHtml]="shouldTranslate() ? ('LEARNER.ACTION_BTN.CONFIRM' | translate) : 'Confirm'"
                  (click)="confirmButton(true)">
                  Confirm
                </button>
              </div>
            </span>
          }
          @if (confirm.dialogType === 'warning-got-it') {
            <span class="row m-0 justify-content-end">
              <div class="col-md-12 text-end">
                <button
                  type="button"
                  class="btn btn-primary mt-0 sentence-case"
                  [innerHtml]="shouldTranslate() ? ('LEARNER.ACTION_BTN.GOT_IT' | translate) : 'Confirm'"
                  (click)="confirmButton(false); showDismissPopUpAnimation = true">
                  Got it
                </button>
              </div>
            </span>
          }
          @if (confirm.dialogType === 'yes-no') {
            <span>
              <button
                type="button"
                class="btn btn-link btn-primary sentence-case"
                [innerHtml]="shouldTranslate() ? ('LEARNER.ACTION_BTN.YES' | translate) : 'yes'"
                (click)="confirmButton(true)">
                yes
              </button>
              <button
                type="button"
                class="btn btn-link btn-secondary sentence-case"
                [innerHtml]="shouldTranslate() ? ('LEARNER.ACTION_BTN.NO' | translate) : 'No'"
                (click)="confirmButton(false)">
                No
              </button>
            </span>
          }
          @if (confirm.dialogType === 'save-discard') {
            <span class="row m-0 justify-content-end">
              <div class="col-md-12 text-end">
                <button
                  type="button"
                  class="btn btn-secondary btn-link rapl-bold sentence-case btn-link-ripple me-1"
                  [innerHtml]="shouldTranslate() ? ('LEARNER.DISCARD' | translate) : 'DISCARD'"
                  (click)="confirmButton(false)">
                  DISCARD
                </button>
                <button
                  type="button"
                  class="btn btn-primary rapl-bold sentence-case"
                  [innerHtml]="shouldTranslate() ? ('LEARNER.ACTION_BTN.SAVE' | translate) : 'Save'"
                  (click)="confirmButton(true)">
                  Save
                </button>
              </div>
            </span>
          }

          @if (confirm.dialogType === 'save-apply-discard') {
            <span class="row m-0 justify-content-end">
              <div class="col-md-12 text-end">
                <button type="button" class="btn btn-secondary btn-link rapl-bold sentence-case btn-link-ripple me-1" (click)="confirmButton(false)">DISCARD</button>
                <button type="button" class="btn btn-primary rapl-bold sentence-case" (click)="confirmButton(true)">Save & Apply</button>
              </div>
            </span>
          }

          @if (confirm.dialogType === 'discard-stayhere') {
            <span class="row m-0 justify-content-end">
              <div class="col-md-12 text-end">
                <button
                  type="button"
                  class="btn btn-secondary btn-link me-1 sentence-case btn-link-ripple"
                  [innerHtml]="shouldTranslate() ? ('LEARNER.STAY_HERE' | translate) : 'DISCARD'"
                  (click)="confirmButton(false)">
                  Stay here
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary mt-0 sentence-case"
                  [innerHtml]="shouldTranslate() ? ('LEARNER.DISCARD' | translate) : 'DISCARD'"
                  (click)="confirmButton(true)">
                  Discard
                </button>
              </div>
            </span>
          }

          @if (
            confirm.dialogType === 'delete-cancel' ||
            confirm.dialogType === 'remove-cancel' ||
            confirm.dialogType === 'archive-cancel' ||
            confirm.dialogType === 'inactive-cancel' ||
            confirm.dialogType === 'reset-cancel'
          ) {
            <span class="row m-0 justify-content-end">
              <div class="col-md-12 text-end">
                <button
                  type="button"
                  class="btn btn-secondary btn-link me-1 sentence-case btn-link-ripple"
                  [innerHtml]="shouldTranslate() ? ('LEARNER.ACTION_BTN.CANCEL' | translate) : 'Cancel'"
                  (click)="confirmButton(false); showDismissPopUpAnimation = true">
                  Cancel
                </button>
                <button type="button" class="btn btn-danger-outline mt-0 sentence-case" [innerHtml]="getActionText(confirm.dialogType)" (click)="confirmButton(true)">
                  Delete / Remove / Inactive / Archive
                </button>
              </div>
            </span>
          }
          @if (confirm.dialogType === 'refresh-update') {
            <span class="row m-0 justify-content-end">
              <div class="col-md-12 text-end">
                <button type="button" class="btn btn-secondary btn-link rapl-bold sentence-case btn-link-ripple me-1" (click)="confirmButton(false)">FORCE UPDATE</button>
                <button type="button" class="btn btn-primary rapl-bold sentence-case" (click)="confirmButton(true)">REFRESH</button>
              </div>
            </span>
          }
          @if (confirm.dialogType === 'keep-leave') {
            <span>
              <div class="col-md-12 text-end">
                <button type="button" class="btn btn-secondary btn-link me-1 sentence-case btn-link-ripple" (click)="confirmButton(false)">Keep Editing</button>
                <button type="button" class="btn btn-warning-outline mt-0 sentence-case" (click)="confirmButton(true)">Leave</button>
              </div>
            </span>
          }
        </div>
      </div>
    </div>
  </div>
}
