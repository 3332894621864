import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appSanitizeInput]',
})
export class SanitizeInputDirective {
    constructor(private ngControl: NgControl) {}

    processInput(value: any) {
        return value.replace(/<(?=\S)/g, '< ');
    }
    @HostListener('input', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;
        const value = this.processInput(input.value);
        this.ngControl.control.patchValue(value);
    }
}
