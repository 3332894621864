export const preLoginConstants = {
    ADMIN: 'Admin',
    CHNG_ACC: 'Change account',
    CHOOSE_ACC: 'Choose an account',
    CONFIRM: 'CONFIRM',
    CREATE_YOUR_PW: 'Create your Password',
    CREATE_NEW_PW: 'Create New Password',
    DID_NOT_GET_OTP: 'Didn’t get an OTP? Click here',
    ENT_EMAIL_PHONE_NUMBER: 'Enter your email or mobile number',
    ENT_OTP: 'Enter the One Time Password (OTP) we sent to',
    ENT_VAL_EMAIL_PHONE: 'Enter valid email or mobile number',
    ENT_ORG: 'Enter your Organisation',
    INVALID_FIRST_NAME: 'The first name cannot start with special characters like @, = and +',
    INVALID_LAST_NAME: 'The last name cannot start with special characters like @, = and +',
    FORGOT_PW: 'Forgot Password?',
    LEARNER: 'Learner',
    MONITOR: 'Monitor',
    NEXT: 'NEXT',
    ORG_REQ: 'Organisation is required',
    PASSWORD: 'password',
    PROCEED: 'Proceed',
    REVIEWER: 'Reviewer',
    PW_REQ: 'Password is required',
    REQ: 'Required',
    SECONDS_REMAINING: 'Seconds Remaining :',
    SIGN_IN: 'Sign in',
    SIGN_IN_TO: 'Sign in to ',
    SIGN_IN_TO_TEAM: 'Sign in to your team',
    SIGN_IN_RAPL_ACC: 'SIGN IN WITH YOUR RAPL ACCOUNT',
    SIGN_IN_SSO: 'Sign In With SSO',
    TEXT: 'text',
    TO_RESET_PW:
        'To reset your password, we will send you a link on your registered mobile or email address.',
    USE_ANOTHER_ACC: 'Use another account',
};
