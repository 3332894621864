import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { AuthGuard } from '../auth.guard';
import { LogEventName } from '../enum/log-event-name';
import { Util } from '../utils';
import { ApiService } from './api.service';
import { LogEventService } from './LogEvent.service';
import { PermissionService } from './permission.service';
import { SubDomainService } from './sub-domain.service';
import { TitleService } from './title.service';

@Injectable()
export class UpdateService {
    logEventName: any = LogEventName;
    constructor(
        public auth: AuthGuard,
        public updates: SwUpdate,
        public logEventService: LogEventService,
        private api: ApiService,
        private subDomain: SubDomainService,
        public permission: PermissionService,
        private readonly titleService: TitleService,
    ) {
        updates.unrecoverable.subscribe((event) => {
            document.location.reload();
        });
        if (updates.isEnabled) {
            interval(900000).subscribe(() =>
                updates.checkForUpdate().then(() => console.log('checking for updates')),
            );
        }
    }

    public checkForUpdates(): void {
        this.updates.versionUpdates.subscribe((event: any) => {
            if (event.type === 'VERSION_READY') {
                if (this.auth.isLoggedIn() === true) {
                    this.logEventService.logEvent(this.logEventName.WEB_APP_SHOW_UPDATE_POPUP, {
                        current_version: Util.appVersion,
                        available_version: event.latestVersion['appData']['version'],
                    });
                    localStorage.setItem('newVersionAvailable', 'true');
                    localStorage.setItem('versionAvailableDropDown', 'true');
                } else {
                    localStorage.setItem('newVersionAvailable', 'true');
                }
            }
        });
    }

    playAudio() {
        const audio = new Audio();
        audio.src = 'assets/audio/app_update_available.wav';
        audio.load();
        audio.play();
    }

    handleAppUpdate(org, user) {
        this.logEventService.logEvent(this.logEventName.UPDATE_APP_CLICKED, {});
        localStorage.removeItem('newVersionAvailable');
        localStorage.removeItem('versionAvailableDropDown');
        const domain = this.subDomain.getSubDomain();
        if (domain) {
            const body = { domain: domain };
            const options = { show_success: true, show_loader: true };
            this.api.post('login/lookup/org', body, options).subscribe({
                next: (data: any) => {
                    this.api.setScheduleOrgRefreshAt();
                    localStorage.setItem('orgSetting', JSON.stringify(data));
                    const orgTheme = Util.filterArray(org.settings, 'property', 'org_theme').value;
                    if (orgTheme !== null) {
                        Util.applytheme(orgTheme);
                    }
                    this.titleService.setDocTitle(data.name + ' | RapL');
                    this.getOrgEmojis();
                    this.getCurrentUserWhenAppUpdated(user);
                },
                error: (err: any) => {
                    console.log(err);
                },
            });
        }
    }
    getCurrentUserWhenAppUpdated(user) {
        const tempUser = {
            type: user.type,
            principal: user.principal,
        };
        this.api.get('profile', { show_success: false, show_loader: true }).subscribe({
            next: (data: any) => {
                if (user && user['user_id'] === data['user_id']) {
                    const orgSetting = JSON.parse(localStorage.getItem('orgSetting'));
                    user.org = orgSetting;
                    user.type = tempUser.type;
                    user.principal = tempUser.principal;
                    localStorage.setItem('current_user', JSON.stringify(user));
                    this.permission.reload();
                    this.reloadWebApplication();
                }
            },
            error: (err: any) => {
                console.log(err);
            },
        });
    }

    reloadWebApplication() {
        this.updates.activateUpdate().then(() => document.location.reload());
    }

    getOrgEmojis() {
        this.api.get('admin/orgs/emojis').subscribe({
            next: (data: any) => {
                localStorage.setItem('orgEmojis', JSON.stringify(data));
            },
            error: (err: any) => {
                console.log('Get Org Emojis Error', err);
            },
        });
    }
}
