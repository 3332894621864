import { Injectable } from '@angular/core';

@Injectable()
export class AdminActiveTabService {
    static getActiveTab(getActiveTab: any) {
        throw new Error('Method not implemented.');
    }
    activeTabCount;
    public getActiveTab(count: any): any {
        this.activeTabCount = count;
        localStorage.setItem('adminActiveSubTab', count);
    }
}
