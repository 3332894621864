import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class HttpCancelService {
    private readonly cancelPendingRequests$ = new Subject<void>();

    /** Cancels all pending Http requests. */
    public cancelPendingRequests() {
        console.log('cancel pending request');
        this.cancelPendingRequests$.next();
    }

    public onCancelPendingRequests() {
        return this.cancelPendingRequests$.asObservable();
    }
}
