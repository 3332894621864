<app-confirm-component></app-confirm-component>
<app-reload-box-component></app-reload-box-component>
<app-spinner-component></app-spinner-component>
<app-chunk-initialization-component></app-chunk-initialization-component>
<app-upload-spinner-component></app-upload-spinner-component>
<ng-template appBackWarning>
  <app-back-warning-component></app-back-warning-component>
</ng-template>
<app-pre-loader-component></app-pre-loader-component>
<router-outlet></router-outlet>
<div id="snack-toast">
  <div id="snack-img">
    <span id="snack-img-icon"></span>
  </div>
  <div id="snack-desc">
    <span id="offlineText" class="d-none" [translate]="'LEARNER.NO_INTERNET'"></span>
    <span id="onlineText" class="d-none" [translate]="'LEARNER.BACK_ONLINE'"></span>
  </div>
</div>
@if (showSessionReminderModal()) {
  <app-session-timer-modal></app-session-timer-modal>
}
