export const monitorConstants = {
    ACTIVATION: 'ACTIVATION',
    ACT: 'Activation',
    ACT_TREND: 'Activation Trend',
    ACTIVATED: 'Activated',
    ACTIVATED_DATE:
        'Date when the user was activated in the system.</br><span class="local-time-zone">(Times are shown in the local timezone)</span>',
    ACTIVATION_IN_USR: 'Activation In User',
    ACTIVATION_IN_PRE_USR: 'Activation In Privileged User',
    ACTIVATED_USR: 'Activated',
    ACT_HLT: 'Activation Health',
    ACT_INF: '% of users who have signed in and activated their accounts.',
    NOT_ACT_INF: '% of users who have not activated their accounts.',
    ACT_USAGE_TITLE: 'Maintain behavior and push further',
    ACTIVE_CONTENT_AVAILABLE_INFO:
        'States if the user has active content available for consumption',
    ACTIVE_QUIZ_AVAILABLE_INFO: 'States if the user has scenarios available for consumption',
    ALL_ROLS: 'All Roles',
    ALL_USRS: 'All',
    ATTEMPTED: 'Attempted',
    ATTEMPTED_USRS: 'Attempted Users',
    ATTEP_ONE_QUES: 'Represents if the user has attempted at least one question in each week.',
    AVERAGE_VIEWS_INFO: 'Average number of views per person',
    ASSIGNED: 'Assigned',
    ASSIGNMENT: 'ASSIGNMENT',
    ASSIGNMENTS: 'ASSIGNMENTS',
    BRK_DOWN_ACROSS_ORG_INFO:
        'This shows you a breakdown of how the users across ' +
        'the organisation have answered the question over time - it could include multiple attempts for the same question.',
    CATEGORY: 'Category',
    CERTAIN: 'Certain',
    CORRECT: 'Correct',
    CREATED:
        'Date when the user was first created in the system.</br><span class="local-time-zone">(Times are shown in the local timezone)</span>',
    CPS: 'Collapse',
    COPY_TO_CLIPBOARD: 'Copy to clipboard',
    COPIED_TO_CLIPBOARD: 'Copied to clipboard',
    COMPLETED: 'Completed',
    COMPLETION_INFO:
        'Scenarios completed by users divided by total number of scenarios X total number of users',
    COMPLETION: 'Completion',
    COMPLETED_PERCENTAGE: 'Completion Percentage',
    CONSUMING: 'Consuming',
    ACTIVE_ASSIG_USERS: 'Active Assignment User',
    ACTIVE_ASSIG_USERS_INFO: 'Count of Users with Active Assignments',
    DATE_INVITED_INFO:
        'Date when the user was first invited to sign in from the system.</br><span class="local-time-zone">(Times are shown in the local timezone)</span>',
    DASHBOARD_LEARNERS_USER_EMPTY_MSG: 'Sorry, you are not monitoring any learners at the moment',
    DASHBOARD_PREV_USER_EMPTY_MSG:
        'Sorry, you are not monitoring any privileged users at the moment',
    DORMANT: 'Dormant',
    DOUBTFUL: 'Doubtful',
    EMAIL: 'Email',
    EXPAND: 'Expand',
    EMPT_ACTIVE_USRS_INF: '<p>There are no active users under the selected group</p>',
    EMPT_GRP_INF: 'There are no groups under the selected group',
    EMPT_LB: '<p>Leaderboard yet to schedule</p>',
    EMPT_LB_Mobile: 'Leaderboard yet to schedule',
    EMPT_LB_TEAM: '<p>There are no team under the selected leaderboard</p>',
    EMPT_LB_TEAM_MOBILE: 'There are no team under the selected leaderboard',
    EMPT_LB_USRS: '<p>There are no users under the selected leaderboard</p>',
    EMPT_LB_USRS_MOBILE: 'There are no users under the selected leaderboard',
    EMPT_MASTERLB: 'There are no leaderboard',
    EMPT_PENDING_USRS_INF: '<p>There are no pending users under the selected group</p>',
    EMPT_PENDING_USRS_GROUP_ROLE_INF:
        '<p>There are no pending users under the selected role via group</p>',
    EMPT_ROL_INF: 'There are no roles under the selected role',
    EMPT_USRS_INF: '<p>There are no users under the selected group</p>',
    ENGAGEMENT_DORMANT: '"Dormant" users do not have active quizzes assigned to them',
    ENGAGEMENT_DORMANT_OVERALL: '"Dormant" users do not have active contents assigned to them',
    ENGAGEMENT_NOTUSING:
        '"Not using" refers to users who have not attempted any question this week',
    ENGAGEMENT_NOTUSING_OVERALL: '"Not using" refers to users who have not used this week',
    EMPT_USRS_SEL_TOPCS: 'There are no users under the selected quiz.',
    EMPT_USRS_SEL_TEST: 'There are no users under the selected test.',
    EMPT_USRS_SEL_FEEDBACK: 'There are no users under the selected feedback.',
    EMPT_USRS_SEL_POLL: 'There are no users under the selected poll.',
    EMPT_USRS_SEL_ASSIGNMENT: 'There are no users under the selected assignment.',
    EMPT_USRS_SEL_PATH: 'There are no users under the selected path.',
    ENGAGEMENT_USING: '"Using" are users who have attempted a question this week',
    ENGAGEMENT_USING_OVERALL: '"Using" are users who have used this week',
    ENGAGEMENT_USERS_DORMANT:
        '"Dormant"  is if the user has no available scenarios for consumption',
    ENGAGEMENT_USERS_DORMANT_OVERALL:
        '"Dormant"  is if the user has no available content for consumption',
    ENGAGEMENT_USERS_NOTUSING:
        '"Not using" is if the user has not attempted a scenario in this week',
    ENGAGEMENT_USERS_NOTUSING_OVERALL: '"Not using" is if the user has not used in this week',
    ENGAGEMENT_USERS_NOTUSING_OVERALL_FOR_PRE:
        '"Not using" is if the privileged users who have visited the monitoring screens',
    ENGAGEMENT_USERS_USING: '"Using" is if the user has attempted a scenario in this week',
    ENGAGEMENT_USERS_USING_OVERALL: '"Using" is if the user has used in this week',
    ENGAGEMENT_USERS_USING_OVERALL_FOR_PRE:
        '"Using" is if the privileged users who have visited the monitoring screens',
    EXD_THR_LMT_ACT_INFO: 'Activation must be greater than',
    EXD_THR_LMT_USAGE_INFO: 'Regular usage needs improvement',
    EXPORT: 'Export',
    EXP_ALL_QUIZ_PERF: 'Export All Quizzes Performance',
    EXP_ASSIG_RES: 'Export Assignment Response',
    EXP_FEEDBACK_DET: 'Export Feedback Details',
    EXP_FEEDBACK_SUM: 'Export Feedback Summary',
    EXP_THIS_QUIZ_PERF: 'Export Quiz Performance',
    EXP_PATH_COMP: 'Export Path Completion',
    EXP_QUIZ_SYM: 'Export Quiz Summary',
    EXP_TEST_ATTEMPT_SYM: 'Export Attempt Wise Summary',
    EXP_TEST_RES: 'Export Test Response',
    EXP_TSET_SYM: 'Export Test Summary',
    FEEDBACK: 'FEEDBACK',
    FILE: 'FILE',
    FILES: 'FILES',
    FILTER_BY: 'FILTER BY',
    FILTER_BY_CAT: 'Filter By Category',
    FILTER_BY_ROL: 'Filter By Role',
    FILTER_BY_STATUS: 'Filter By Status',
    FILTER_BY_TAGS: 'Filter By Tag',
    FIRST_NAME: 'First Name',
    FIVE_DAY_USAGE: 'This Week Engagement',
    FIVE_WEEK_USAGE: '5 Week Usage',
    FIVE_WEEK_USAGE_TREND_INFO:
        'Represents the percentage of users who attempted atleast one scenario in each week - shown for the past 5 weeks. Week ending Sunday midnight.',
    FIVE_WEEK_OVERALL_USAGE_TREND_INFO:
        'Represents the percentage of users who used atleast once in each week - shown for the past 5 weeks. Week ending Sunday midnight.',
    INACTIVE: 'Inactivated',
    INCORRECT: 'Incorrect',
    INDIVIDUAL_LEADERBOARD: 'Individual Leaderboard',
    ITEMS: 'Items',
    ITEMS_COMPLETION: 'Items Completion',
    HIERARCHY: 'Hierarchy',
    LAST_ATTEMPTED_ON_INFO:
        'Date when the user last attempted a scenario.</br><span class="local-time-zone">(Dates are shown in the local timezone)</span>',
    LAST_ATTEMPTED_ON_INFO_OVERALL:
        'Date when the user last used.</br><span class="local-time-zone">(Dates are shown in the local timezone)</span>',
    LAST_CHANGE: 'Last Change',
    LAST_COMPUTED: 'Last computed',
    LAST_INVITED_INFO:
        'Date when an invite was last sent to the user.</br><span class="local-time-zone">(Times are shown in the local timezone)</span>',
    LAST_NAME: 'Last Name',
    LIBRARY: 'LIBRARY',
    LOAD_MORE: 'Load More',
    LB: 'Leaderboard',
    LP: 'Learning path',
    LP_PROGRESS: 'Learning Path Progress',
    LPS: 'Learning paths',
    LIVE: 'LIVE',
    LIST_ALL_GRP_INFO: 'This shows you a list of all the groups to which the user belongs.',
    LOCAL_TIME_ZONE_INFO:
        '<span class="local-time-zone">(Times are shown in the local timezone)</span>',
    MASTERED: 'Mastered',
    MASTERED_QUES: 'Mastered Questions',
    MOBILE_NO: 'Mobile Number',
    MONITOR: 'Monitor',
    MISINFORMED: 'Misinformed',
    NAME: 'Name',
    NO_DATA: 'No Data Available',
    NO_RESULTS: 'No results found for',
    NO_ROL_AVAILABLE: 'There are no roles available.',
    NO_ROL_FOUND: 'No roles found',
    NO_FILES_AVAILABLE: 'There are no files available.',
    NO_GROUPS_AVAILABLE: 'There are no groups available.',
    NO_GROUPS_SEL_FILE: 'There are no groups under the selected file',
    NO_QUES_ATTED: 'No questions attempted',
    NO_QUES_AVL: 'No questions available',
    NO_TAGS_FOUND: 'No Tags Found',
    NO_QUIZ_IN_USR: 'There are no Quizzes under the selected user',
    NOT_ACTIVATED: 'Not activated',
    NOT_APPLD: 'Not Applicable',
    NOT_ATTEMPTED: 'Not Attempted',
    NOT_RELEASED: 'Not Released',
    NOT_USING: 'Not Using',
    NO_USERS_SEL_FILE: 'There are no users under the selected file.',
    NO_TEST_AVAIL: 'There are no tests available.',
    NO_QUES_SEL_TOPC: 'There are no questions under the selected quiz',
    NO_QUIZ_IN_WEEK: 'No Quizzes available in the week',
    NO_RES_FOUND: 'No result found for',
    NO_USERS_FOUND: 'No Users Found',
    OVERALL: 'OVERALL',
    PATH: 'Path',
    PATHS: 'Paths',
    PATH_COMPLETION: 'Path Completion',
    PATH_PROGRESS: 'Path Progress',
    PASSED: 'Passed',
    PASSED_USERS: 'Passed Users',
    PENDING_URS: 'Pending',
    PERFORMANCE_SPLIT: 'Performance Split',
    POLL: 'POLL',
    POLLS: 'POLLS',
    QUESTION: 'Questions',
    QUES_ATTED: 'Questions attempted',
    QUIZ: 'Quiz',
    QUIZ_ASD_TO: 'Quiz assigned to',
    QUIZZES: 'QUIZZES',
    RELEASED_ITEM: 'Released items',
    RESET_FILTER: 'Reset Filter',
    RESPONSE_SPLIT: 'Response Split',
    SEL_ROL: 'Selected Role',
    SHORT_CODE: 'Short Code',
    SHOWS_HOW_LNG_NON_ACT_USR: 'Shows you how long non-activated users have been on the system.',
    STATUS: 'Status',
    SORT_BY: 'SORT BY',
    SUB_GRPS: 'Sub Groups',
    SURVEY: 'SURVEY',
    SURVEYS: 'SURVEYS',
    SMY: 'Summary',
    SURVEY_COMPLETION: 'SURVEY COMPLETION',
    SUBMISSION: 'Submission',
    TAGS: 'Tag',
    TAKE_ACTION: 'Take action',
    TODAYS_ACTIVE_USERS: "Today's active users",
    TEAM_LEADERBOARD: 'Team Leaderboard',
    TEST: 'TEST',
    TESTS: 'TESTS',
    TOP_LVL_GRP: 'Top level group',
    TOPIC_YET_TO_START: 'Total number of users yet to start this quiz',
    TOPIC_IN_PROGRESS: 'Total number of users currently consuming this quiz',
    TOT_NO_GRPS_TEST_ASG: 'Total number of groups to which the test is assigned',
    USAGE: 'Usage',
    USAGE_HEALTH: 'Usage Health',
    USAGE_IN_USER: 'Usage In User',
    USAGE_IN_PRE_USER: 'Usage In Privileged User',
    USAGE_TREND: 'Usage Trend',
    VIEW_PERCENTAGE: 'View Percentage',
    VIEWED: 'Viewed',
    VIEWED_INFO: 'Number of users who have viewed this file',
    VIEW_PERCENTAGE_FILE_CONTENT: '% of content files viewed.',
    VIEW_PERCENTAGE_FILE_CONTENT_COMPLETION: 'Percentage of users who have viewed this file',
    WITHIN_THR_LMT_ACT_INFO: 'Learners have adopted everyday learning via RapL.',
    WITHIN_THR_LMT_USAGE_INFO: 'Learners are maintaining a healthy usage.',
    WITHIN_THR_LMT_ADMIN_USAGE_INFO: 'Users are maintaining a healthy usage.',
    TOT_NO_GRPS_TOPC_ASG: 'Total number of groups to which the quiz is assigned',
    PERC_USRS_ACROSS_ORG:
        'This is the percentage of users across the organisation who have mastered the particular question.',
    TOT_NO_GRPS_TOPC_ACCESS: 'Total number of users who have accessed the quiz.',
    TOT_NO_USRS_TOPC_MASTRD: 'Total number of users who have mastered the quiz.',
    TOT_NO_USRS_COM_PATH: 'Total number of users who have completed the path',
    QUES_MAST_INFO: `% of questions mastered by users divided by total number of questions available to all
    users - this may not reach 100% if the mastery criteria for the quiz is less than total questions in the quiz.`,
    NO_TOPC_AVAIL: 'There are no quizzes available.',
    UNANSWERED: 'Unanswered',
    USRS: 'Users',
    USERS_OPENED_TODAY: 'No. of users who have opened RapL today.',
    USR_RANK_INFO: `This is the rank of the user for the quiz under consideration. Rank is calculated for the quiz across all users.
    It is based on how quickly a user is able to master the quiz.`,
    USR_ANS_QUES_INFO: `This shows you how users have answered questions in the quiz over time.
        You can click here to sort by descending order of 'certain' (users who knew the content best) or
        descending order of 'misinformed' (users who were most misinformed during the process).`,
    COMPLETION_QUIZ_GROUP_PERFORMANCE_SPLIT_INFO: `This shows you how users in a group have answered questions in the quiz over time.
        You can click here to sort by descending order of 'certain' (users who knew the content best) or
        descending order of 'misinformed' (users who were most misinformed during the process).`,
    USR_MAST_QUES_PER_INFO: `% of users who have mastered the quiz. This only includes those users who have reached the criteria for mastery in the quiz.
    This bar may be 0 in the beginning of any quiz release.`,
    USR_STATUS: 'User status:',
    USR_STATUS_LISTING: 'User Status Listing',
    USING: 'Using',
    VIEW_CONTENT: 'View Content',
    EXP_QUIZ_RES: 'Export Quiz Response',
    EXP_SURVEY_RES: 'Export Survey Response',
    PER_ASS_ACT_ACC: 'Percentage of assigned users who have activated their accounts',
    ACT_TRND:
        'Shows % of users activated on y-axis against the last day of each week on the x-axis',
    NO_USRS_ASG_GRP_ROL: 'Usage information of activated users either assigned to groups or roles',
    USRS_ATMPT_QUE_FIVE_DAYS: 'Users who have used this week',
    USRS_NOT_HAV_TOP_ASG: 'Users do not have active content assigned to them',
    USRS_ATMPT_ANY_QUE_FIVE_DAYS: 'Users who have not used this week',
    USG_TRND: 'Shows % of users usage on y-axis against the last day of each week on the x-axis',
    QUIZ_ASG_TO_ROL_GRP: 'Total number of quizzes assigned to groups & roles',
    SCENARIOS_COMPLETION:
        'Scenarios completed by users divided by total number of scenarios multiplied by total number of users',
    MASTERED_ALL_QUIZZ: '% of users who have mastered all quizzes',
    TEST_ASG_TO_ROL_GRP: 'Total number of tests assigned to groups & roles',
    TESTS_PASSES:
        'Tests passed by users divided by total number of tests multiplied by total number of users',
    COMLETED_ALL_TESTES: '% of users who have completed all tests',
    POLL_ASG_TO_ROL_GRP: 'Total number of polls assigned to groups & roles',
    POLL_ATTEMPTED:
        'Polls completed by users divided by total number of polls multiplied by total number of users',
    POLL_COMPLETION: '% of users who have completed all polls',
    SURVEY_ASG_TO_ROL_GRP: 'Total number of surveys assigned to groups & roles',
    SURVEY_ATTEMPTED:
        'Surveys completed by users divided by total number of surveys multiplied by total number of users',
    SURVEY_COMPLETE: '% of users who have completed all surveys',
    FILE_ASG_TO_ROL_GRP: 'Total number of files assigned to groups & roles',
    FILE_VIEWED:
        'Files viewed by users divided by total number of files multiplied by total number of users',
    VIWED_ALL_FILES: '% of users who viewed all files',
    FILE_VIEW_PERCENTAGE: '% of files viewed across users',
    ASSIGN_ASG_TO_ROL_GRP: 'Total number of assignments assigned to groups & roles',
    ASSIGN_ATTEMPTED:
        'Assignments completed by users divided by total number of assignments multiplied by total number of users',
    SUBMIT_ALL_USERS: '% of users who have completed all assignments',
    PERCENTAGE_VIEWED: 'Viewed',
    FILE_MASTERS: 'File Masters',
    QUIZ_COMPLETE_PERCENTAGE: 'Quiz Completion %',
    QUIZ_MASTERS: 'Quiz Masters',
    TEST_PASS_PERCENTAGE: 'Test Pass %',
    TEST_MASTERS: 'Test Masters',
    POLL_COMPLETION_PERCENATGE: 'Poll Completion %',
    POLL_MASTERS: 'Poll Masters',
    SURVEY_COMPLETION_PERCENTAGE: 'Survey Completion %',
    SURVEY_MASTERS: 'Survey Masters',
    ASSIGNEMNT_SUBMISSION_PERCENTAGE: 'Assignment Submission %',
    ASSIGNMENT_MASTERS: 'Assignment Masters',
};
