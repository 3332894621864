import { Injectable } from '@angular/core';

@Injectable()
export class DayService {
    public getWeekDays() {
        return [
            {
                index: 1,
                day: 'MON',
                isChecked: false,
                text: 'MONDAY',
                translate: 'LEARNER.MONDAY',
                function() {
                    return this.index;
                },
                dayKey: 'Mo',
                dayInitialKey: 'M',
            },
            {
                index: 2,
                day: 'TUE',
                isChecked: false,
                text: 'TUESDAY',
                translate: 'LEARNER.TUESDAY',
                function() {
                    return this.index;
                },
                dayKey: 'Tu',
                dayInitialKey: 'T',
            },
            {
                index: 3,
                day: 'WED',
                isChecked: false,
                text: 'WEDNESDAY',
                translate: 'LEARNER.WEDNESDAY',
                function() {
                    return this.index;
                },
                dayKey: 'We',
                dayInitialKey: 'W',
            },
            {
                index: 4,
                day: 'THU',
                isChecked: false,
                text: 'THURSDAY',
                translate: 'LEARNER.THURSDAY',
                function() {
                    return this.index;
                },
                dayKey: 'Th',
                dayInitialKey: 'T',
            },
            {
                index: 5,
                day: 'FRI',
                isChecked: false,
                text: 'FRIDAY',
                translate: 'LEARNER.FRIDAY',
                function() {
                    return this.index;
                },
                dayKey: 'Fr',
                dayInitialKey: 'F',
            },
            {
                index: 6,
                day: 'SAT',
                isChecked: false,
                text: 'SATURDAY',
                translate: 'LEARNER.SATURDAY',
                function() {
                    return this.index;
                },
                dayKey: 'Sa',
                dayInitialKey: 'S',
            },
            {
                index: 0,
                day: 'SUN',
                isChecked: false,
                text: 'SUNDAY',
                translate: 'LEARNER.SUNDAY',
                function() {
                    return this.index;
                },
                dayKey: 'Su',
                dayInitialKey: 'S',
            },
        ];
    }
}
