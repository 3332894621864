import { Injectable } from '@angular/core';
import {
    Router,
    NavigationStart,
    NavigationEnd,
    NavigationCancel,
    ActivatedRoute,
} from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable()
export class AppHandleFilterParams {
    currentUrl;
    constructor(
        public router: Router,
        public route: ActivatedRoute,
    ) {
        this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationStart ||
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel,
                ),
            )
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.currentUrl = this.router.url.split('?')[0];
                    if (!this.currentUrl.includes(sessionStorage.getItem('filterComponent'))) {
                        this.removeFilterSession();
                    }
                }
            });
    }

    public handleDtParams(dt, compRoute) {
        if (
            sessionStorage.getItem('filterParams') &&
            sessionStorage.getItem('filterComponent') === compRoute
        ) {
            sessionStorage.setItem('filterParams', JSON.stringify(dt.params));
        } else {
            sessionStorage.setItem('filterParams', JSON.stringify(dt.params));
            sessionStorage.setItem('filterComponent', compRoute);
        }
    }

    public handleLearnerParams(params, compRoute) {
        if (
            sessionStorage.getItem('filterParams') &&
            sessionStorage.getItem('filterComponent') === compRoute
        ) {
            sessionStorage.setItem('filterParams', JSON.stringify(params));
        } else {
            sessionStorage.setItem('filterParams', JSON.stringify(params));
            sessionStorage.setItem('filterComponent', compRoute);
        }
    }

    public handleFileBreadCrumb(folder, Index, folderBreadcrumb) {
        sessionStorage.setItem('selectedBreadCrumbFolder', JSON.stringify(folder));
        sessionStorage.setItem('selectedBreadCrumbIndex', Index);
        sessionStorage.setItem('folderBreadcrumb', JSON.stringify(folderBreadcrumb));
    }

    public removeFilterSession() {
        sessionStorage.removeItem('filterParams');
        sessionStorage.removeItem('filterComponent');
        sessionStorage.removeItem('selectedBreadCrumbFolder');
        sessionStorage.removeItem('selectedBreadCrumbIndex');
        sessionStorage.removeItem('folderBreadcrumb');
    }
}
