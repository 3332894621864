import { Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class PreLoaderService {
    public status: Subject<boolean> = new Subject();
    public _active = false;
    public clickStream = new Subject<boolean>();
    @Output() observable = this.clickStream.asObservable();
    public get active(): boolean {
        return this._active;
    }
    public set active(v: boolean) {
        this._active = v;
        this.status.next(v);
    }
    public show(): void {
        this.active = true;
        localStorage.setItem('showPreLoader', 'true');
        setTimeout(() => {
            this.close();
        }, 2000);
    }
    close() {
        this.active = false;
        localStorage.removeItem('showPreLoader');
        this.clickStream.next(true);
    }
}
