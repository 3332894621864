import { Injectable } from '@angular/core';

@Injectable()
export class DataService {
    private _activeData: any = [];
    public firstName: string;

    public getSharedData(key: string): any {
        return this._activeData[key];
    }

    public setSharedData(key: string, v: any) {
        this._activeData[key] = v;
    }

    public removeSharedData(key: string) {
        delete this._activeData[key];
    }

    public removeAll() {
        this._activeData = [];
    }
}
