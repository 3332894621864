import { Injectable } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { SnackbarProperties } from '../enum/snackbar-properties';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SnackbarService {
    snackbarDuration: number = SnackbarProperties.duration;
    horizontalPosition: MatSnackBarHorizontalPosition = SnackbarProperties.horizontalPosition;
    verticalPosition: MatSnackBarVerticalPosition = SnackbarProperties.verticalPosition;
    constructor(
        public snackBar: MatSnackBar,
        public translateService: TranslateService,
    ) {}

    default(message) {
        this.snackBar.open(message, '', {
            duration: this.snackbarDuration,
            panelClass: ['default-snackbar'],
        });
    }

    success(message, customClass?: any) {
        this.snackBar.open(message, 'X', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['success-snackbar', customClass],
            duration: this.snackbarDuration,
        });
    }

    error(message, customClass?: any) {
        this.snackBar.open(message, 'X', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['error-snackbar', customClass],
            duration: this.snackbarDuration,
        });
    }

    warning(message, customClass?: any) {
        this.snackBar.open(message, 'X', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['warning-snackbar', customClass],
            duration: this.snackbarDuration,
        });
    }

    offline() {
        this.snackBar.open(this.translateService.instant('LEARNER.OFFLINE_ALERT'), '', {
            duration: this.snackbarDuration,
        });
    }

    successPop(message) {
        this.snackBar.open(message, 'X', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['success-snackbar', 'override-snackbar-in-popup'],
            duration: this.snackbarDuration,
        });
    }

    errorPop(message) {
        this.snackBar.open(message, 'X', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['error-snackbar', 'override-snackbar-in-popup'],
            duration: this.snackbarDuration,
        });
    }
}
