import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmState } from 'app/back-warning-modal/back-warning-modal.service';

@Component({
    selector: 'app-back-warning-component',
    templateUrl: './back-warning-modal.component.html',
    styleUrls: ['./back-warning-modal.component.scss'],
})
export class BackWarningComponent {
    constructor(
        public state: ConfirmState,
        public router: Router,
    ) {}

    yes() {
        this.state.isWarningModalOpen = false;
        this.state.modal.close(true);
    }

    no() {
        this.state.isWarningModalOpen = false;
        this.state.modal.close(false);
    }

    isTakeQuizActive() {
        return this.router.isActive('/app/takequiz', false);
    }

    isMyGenieActive() {
        return this.router.isActive('/app/myGenie', true);
    }
}
