import { Injectable } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { AuthGuard, LogEventService, ApiService } from '..';
import { WebSessionTimeoutReminder } from '../enum/web-session-reminder';
import { LogEventName } from '../enum/log-event-name';
import { Favicons } from 'app/favicons';
import { TransService } from '../translate/translate.service';
@Injectable()
export class SessionTimeoutService {
    WebSessionReminder = WebSessionTimeoutReminder.WebSessionReminder;
    logEventName: any = LogEventName;

    constructor(
        public api: ApiService,
        public auth: AuthGuard,
        private readonly userIdle: UserIdleService,
        public favicons: Favicons,
        public logEventService: LogEventService,
        public translate: TransService,
    ) {}
    // setting the ttile
    detectUserInactivity(userWebSessionTimeout) {
        this.userIdle.stopWatching();
        const userWebSessionTimeoutSecs = (userWebSessionTimeout - this.WebSessionReminder) * 60;
        this.userIdle.setConfigValues({
            idle: userWebSessionTimeoutSecs,
            timeout: userWebSessionTimeout * 60,
            ping: 10,
        });
        // Start watching for user inactivity.
        this.userIdle.startWatching();
        localStorage.removeItem('showSessionTimeoutModal');
        localStorage.removeItem('sessionTimeoutDateTime');
        localStorage.removeItem('sessionTimeoutReminder');
        const currDateTime = new Date();
        localStorage.setItem('lastActiveTime', currDateTime.toString());
        // Call when user inactivity reached.
        this.userIdle.onTimerStart().subscribe((count) => {
            const currentDateTime = new Date();
            const lastActiveTime = new Date(localStorage.getItem('lastActiveTime'));
            const differenceInTime = (currentDateTime.getTime() - lastActiveTime.getTime()) / 1000;
            if (differenceInTime > userWebSessionTimeoutSecs) {
                if (this.auth.isLoggedIn() === true) {
                    localStorage.setItem('showSessionTimeoutModal', 'true');
                    if (localStorage.getItem('sessionTimeoutReminder') === null) {
                        localStorage.setItem('sessionTimeoutReminder', 'true');
                        const idleTimeoutDateTime = new Date();
                        idleTimeoutDateTime.setMinutes(
                            idleTimeoutDateTime.getMinutes() + this.WebSessionReminder,
                        );
                        localStorage.setItem(
                            'sessionTimeoutDateTime',
                            idleTimeoutDateTime.toString(),
                        );
                    }
                }
            } else {
                localStorage.removeItem('sessionTimeoutReminder');
            }
        });
        this.userIdle.onIdleStatusChanged().subscribe((status) => {
            if (status && localStorage.getItem('showSessionTimeoutModal') === null) {
                const currentTime = new Date();
                localStorage.setItem('lastActiveTime', currentTime.toString());
            }
        });
    }

    resetSessionTimer() {
        this.userIdle.resetTimer();
    }
    sessionLogout() {
        this.api.deleteFcmToken();
        this.api.logout();
    }
}
