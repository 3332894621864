import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppCustomPreloader } from './app-routing-loader';
import { LoginUrlComponent } from './login-url/login-url.component';
import { SubscriptionUrlComponent } from './subscription-url/subscription-url.component';
import { Four04Component } from './user_app/not_found/four04.component';
import { UnderMaintenanceComponent } from './user_app/under_maintenance/under-maintenance.component';
import { LearnerPermissionGuard, PermissionGuard } from './shared';
const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./security/security.module').then((m) => m.SecurityModule),
    },
    {
        path: 'security',
        loadChildren: () => import('./security/security.module').then((m) => m.SecurityModule),
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    },
    {
        path: 'app',
        loadChildren: () => import('./user_app/main.module').then((m) => m.MainModule),
    },
    {
        path: 'monitor',
        loadChildren: () => import('./monitor/monitor.module').then((m) => m.MonitorModule),
    },
    {
        path: 'review',
        loadChildren: () => import('./review/review.module').then((m) => m.ReviewModule),
        canActivate: [LearnerPermissionGuard],
    },
    {
        path: 'form',
        loadChildren: () => import('./form/form.module').then((m) => m.FormModule),
        data: { privileges: ['form:view'] },
        canActivate: [PermissionGuard],
    },
    { path: 'subscribe-url', component: SubscriptionUrlComponent },
    { path: 'login-url', component: LoginUrlComponent },
    { path: 'undermaintenance', component: UnderMaintenanceComponent },
    { path: '**', component: Four04Component },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: AppCustomPreloader })],
    exports: [RouterModule],
    providers: [AppCustomPreloader],
})
export class AppRoutingModule {}
