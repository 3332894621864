import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, AuthGuard, Util } from 'app/shared';
import { SubDomainService } from 'app/shared/service/sub-domain.service';
import { TitleService } from 'app/shared/service/title.service';

@Component({
    templateUrl: './subscription-url.component.html',
    selector: 'app-subscription-url',
    styleUrls: ['./subscription-url.component.scss'],
})
export class SubscriptionUrlComponent implements OnInit {
    org: any;
    constructor(
        public api: ApiService,
        public route: ActivatedRoute,
        public auth: AuthGuard,
        public router: Router,
        private readonly titleService: TitleService,
        public subDomain: SubDomainService,
    ) {}
    ngOnInit() {
        if (this.auth.isLoggedIn()) {
            setTimeout(() => {
                this.setSubscriptionInfo('/app/myAccount/subscriptions');
            }, 200);
        } else {
            this.getOrgSetting();
        }
    }
    setSubscriptionInfo(redirectRoute: any) {
        this.route.queryParamMap.subscribe((queryParams: any) => {
            const body: any = {
                principal: decodeURI(queryParams.params.email),
                type: 'EMAIL',
                fname: decodeURI(queryParams.params.fname),
            };
            if (queryParams.get('email') !== null) {
                if (queryParams.params.lname !== undefined) {
                    body.lname = decodeURI(queryParams.params.lname);
                }
                localStorage.setItem('selfRegistrationUser', JSON.stringify(body));
            }
            if (queryParams.get('id') !== null) {
                localStorage.setItem('subscriptionId', queryParams.get('id'));
                if (this.auth.isLoggedIn()) {
                    this.api.applySubscription(localStorage.getItem('subscriptionId'));
                }
                this.router.navigate([redirectRoute]);
            }
        });
    }
    getOrgSetting() {
        const domain = this.subDomain.getSubDomain();
        if (domain) {
            const body = { domain: domain };
            const options = { show_success: true, show_loader: true };
            this.api.post('login/lookup/org', body, options).subscribe({
                next: (data: any) => {
                    this.org = data;
                    this.api.setScheduleOrgRefreshAt();
                    localStorage.setItem('orgSetting', JSON.stringify(data));
                    const orgTheme = Util.filterArray(
                        this.org.settings,
                        'property',
                        'org_theme',
                    ).value;
                    if (orgTheme !== null) {
                        Util.applytheme(orgTheme);
                    }
                    this.titleService.setDocTitle(this.getOrg().name + ' | RapL');
                    const userRegType = Util.filterArray(
                        this.org.settings,
                        'property',
                        'user_reg_type',
                    );
                    if (userRegType.value === 'SELF' || userRegType.value === 'SUBSCRIPTION') {
                        this.setSubscriptionInfo('/security/signup');
                    } else {
                        this.setSubscriptionInfo('/security/login');
                    }
                },
                error: (err: any) => {
                    console.log(err);
                },
            });
        } else {
            this.router.navigateByUrl('/404', { skipLocationChange: true });
        }
    }
    getOrg() {
        return JSON.parse(localStorage.getItem('orgSetting'));
    }
}
