import { Directive, Injectable, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
@Injectable()
export class ConfirmState {
    modal: NgbModalRef;
    template: TemplateRef<any>;
    isWarningModalOpen = false;
}

@Injectable()
export class BackWarningService {
    config: NgbModalOptions = {
        backdrop: 'static',
        windowClass: 'exit-warning-modal',
        keyboard: false,
    };
    constructor(
        public modalService: NgbModal,
        public state: ConfirmState,
    ) {}
    confirm(): Promise<any> {
        if (!this.state.isWarningModalOpen) {
            this.state.isWarningModalOpen = true;
            this.state.modal = this.modalService.open(this.state.template, this.config);
        }
        return this.state.modal.result;
    }
}

@Directive({
    selector: 'ng-template[appBackWarning]',
})
export class BackWarningModalDirective {
    constructor(backWarningTemplate: TemplateRef<any>, state: ConfirmState) {
        state.template = backWarningTemplate;
    }
}
