import { Schema } from './schema';

export const environment: Schema = {
    production: true,
    domainUrl: 'getrapl.com',
    serverUrl: 'https://{domain}.getrapl.com/api/',
    siteUrl: 'getrapl.com',
    firebaseAnalytics: true,
    firebase: {
        apiKey: 'AIzaSyBeDpEs1KZlJ5LFbicycAIcnXtpeX0TZYs',
        authDomain: 'rapl-16c79.firebaseapp.com',
        databaseURL: 'https://rapl-16c79.firebaseio.com/',
        projectId: 'rapl-16c79',
        storageBucket: 'rapl-16c79.appspot.com',
        messagingSenderId: '875198860032',
        appId: '1:875198860032:web:bce1682a7e539773',
        measurementId: 'G-LQHKCR7DQW',
    },
    myGenieUrl: 'https://ask.rapl.ai/core/api/',
    craftUrl: 'https://{domain}.getrapl.com/api/',
    formUrl: 'https://form.getrapl.com/',
};
