import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class UploadSpinnerService {
    public status: Subject<boolean> = new Subject();
    private _active = false;
    public text;

    public get active(): boolean {
        return this._active;
    }

    public set active(v: boolean) {
        this._active = v;
        this.status.next(v);
    }

    public start(uploadText?: string): void {
        this.active = true;
        this.text = uploadText;
    }

    public stop(): void {
        this.active = false;
    }
}
