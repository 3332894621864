<div class="mobile-view-nav-back-arrow d-block d-lg-none">
  <span class="cursor-pointer me-2" [routerLink]="['/app/home']">
    <span class="mdi mdi-arrow-left me-2"></span>
  </span>
</div>
<div class="text-center empty-msg-layout page-not-found-outer">
  <h2 app-translate="LEARNER.OOPS" [translate]="'LEARNER.OOPS'">Sorry</h2>
  <h5>Page Not Found!</h5>
  <p class="pb-1">Seems you're looking for something that doesn't exist.</p>
  <img alt="Page Not Found" class="img-fluid pt-2 ms-auto me-auto" src="assets/img/404_error.png" draggable="false" />
</div>
