// file size in MB
export enum MaximumFileSize {
    AUDIO = 15,
    PDF = 15,
    SCORM = 80,
    THUMBNAIL = 5,
    VIDEO = 200,
    IMAGE = 5,
    FILE = 15,
    CERTIFICATEIMAGE = 2,
}
