<div class="modal-header">
  <h6 class="text-center warning-header rapl-bold m-0 sentence-case" [translate]="'LEARNER.WARNING'">WARNING</h6>
</div>
<div class="text-center mt-3">
  @if (isTakeQuizActive()) {
    <h3 class="msg-content rapl-bold" [translate]="'LEARNER.WANT_TO_EXIT'">Are you sure you want to quit this topic?</h3>
  } @else {
    <h3 class="msg-content rapl-bold" [translate]="'LEARNER.WANT_TO_EXIT'">Are you sure you want to exit?</h3>
  }
</div>
<div class="mt-3">
  <div class="container">
    <div class="row mb-4">
      <div class="col-6 text-center pe-4">
        <button cdkFocusInitial class="exit-cancel-btn cursor-pointer rapl-bold btn-link-ripple sentence-case" (click)="no()" [translate]="'LEARNER.ACTION_BTN.NO'">NO</button>
      </div>
      <div class="col-6 text-center pe-4">
        <button class="exit-btn cursor-pointer rapl-bold btn-link-ripple sentence-case" (click)="yes()" [translate]="'LEARNER.ACTION_BTN.YES'">YES</button>
      </div>
    </div>
  </div>
</div>
