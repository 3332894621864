import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    userPrivileges: string[] = [];
    public constructor() {
        this.register();
    }

    public has(name: string | string[]): boolean {
        if (typeof name === 'string') {
            return this.verify([name], false);
        }

        return this.verify(name, false);
    }

    public hasAll(list: string[]): boolean {
        return this.verify(list, true);
    }

    public reload(): void {
        this.reset();
        this.register();
    }

    public reset(): void {
        this.userPrivileges = [];
    }

    private register(): void {
        try {
            const currentUser = JSON.parse(localStorage.getItem('current_user'));
            if (currentUser && typeof currentUser.privileges === 'object') {
                this.userPrivileges = currentUser.privileges;
            }
        } catch (e) {
            this.reset();
        }
    }

    private verify(privileges: string[], and: boolean): boolean | undefined {
        if (!this.userPrivileges) {
            return false;
        }

        const allow: boolean[] = [];

        for (const privilege of privileges) {
            if (this.userPrivileges.indexOf(privilege) !== -1) {
                allow.push(true);
            }

            let entity = privilege.split(':')[0];

            while (entity.length > 0) {
                if (this.userPrivileges.indexOf(`${entity}:*`) !== -1) {
                    allow.push(true);
                }

                const segments = entity.split('.');
                segments.pop();
                entity = segments.join('.');
            }

            if (and) {
                return allow.indexOf(false) !== -1;
            }
        }
        return allow.indexOf(true) !== -1;
    }
}
