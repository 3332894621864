import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackbarService } from 'app/shared/service/snack-bar.service';
import { EMPTY, Observable, Subject, throwError, timer } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { ApiService } from './service/api.service';
import { HttpCancelService } from './service/http-cancel.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(
        public api: ApiService,
        private readonly httpCancelService: HttpCancelService,
        public snackbarService: SnackbarService,
    ) {}
    pendingHTTPRequests$ = new Subject<void>();

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const requestUrl = request.url.slice(request.url.lastIndexOf('/') + 1);

        return timer(
            this.api.tokenRefreshApiCall === true && requestUrl !== 'refresh' ? 3000 : 0,
        ).pipe(
            switchMap(() =>
                next.handle(this.getHeader(request)).pipe(
                    takeUntil(this.httpCancelService.onCancelPendingRequests()),
                    catchError((apiError: HttpErrorResponse) => {
                        if (apiError.status === 401) {
                            if (apiError.error.code === 1103 || apiError.error.code === 1104) {
                                this.snackbarService.error(
                                    'Unauthenticated request, Please login again.',
                                );
                                this.httpCancelService.cancelPendingRequests();
                                this.api.deleteFcmToken();
                                this.api.logout();
                                return EMPTY;
                            }
                        }
                        return throwError(apiError);
                    }),
                ),
            ),
        );
    }
    private getHeader(request) {
        return request.clone({ headers: this.api.setHeader(request.body instanceof FormData) });
    }
}
