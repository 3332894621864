<div>
  <div class="mobile-modal-header mb-0 pb-0" mat-dialog-title>
    <h6 class="text-center logout-header rapl-bold" app-translate="LEARNER.LOGOUT" [translate]="'LEARNER.LOGOUT'">Sign Out</h6>
  </div>
  <div mat-dialog-content class="text-center">
    <h3 class="logout-content mb-0" app-translate="LEARNER.LOGOUT_MSG" [translate]="'LEARNER.LOGOUT_MSG'">Are you sure you want to sign out from your account?</h3>
  </div>
  <div mat-dialog-actions>
    <div class="container">
      <div class="row">
        <div class="col-6 text-end">
          <button cdkFocusInitial class="logout-btn rapl-bold" (click)="logout()" app-translate="LEARNER.ACTION_BTN.I_AM_SURE" [translate]="'LEARNER.ACTION_BTN.I_AM_SURE'">
            I AM SURE
          </button>
        </div>
        <div class="col-6 text-center">
          <button class="logout-cancel-btn rapl-bold" (click)="close()" app-translate="LEARNER.ACTION_BTN.CANCEL" [translate]="'LEARNER.ACTION_BTN.CANCEL'">CANCEL</button>
        </div>
      </div>
    </div>
  </div>
</div>
