import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Injectable({
    providedIn: 'root',
})
export class PushNotificationService {
    constructor(private afMessaging: AngularFireMessaging) {}

    receiveMessages() {
        return this.afMessaging.messages;
    }
}
