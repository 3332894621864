import { Injectable } from '@angular/core';

@Injectable()
export class FAQService {
    faqKey;
    public set(key: string): any {
        this.faqKey = key;
    }

    public get(): any {
        return this.faqKey;
    }
}
