import { saveAs } from 'file-saver';
import { SpinnerService } from 'app/spinner/spinner.service';
import { PrincipalType } from 'app/shared/enum/principal-type';
import { MobileNumberService } from 'app/shared/service/mobile-number.service';
import { ConstantImage } from 'app/shared/enum/constant-image';

declare function require(moduleName: string): any;

export class LearnerUtil {
    static readonly constantImage = ConstantImage;
    static readonly spinner = new SpinnerService();
    static readonly mobileNumberService = new MobileNumberService();
    static getNotifImage(notif: any) {
        const images = {
            FIRST_LOGIN: 'assets/img/raplLogo.png',
            NODE_ASSOCIATION: 'assets/img/users.png',
            MASTERED_TOPIC: 'assets/img/trophy.png',
            INACTIVATE_TOPIC: 'assets/img/inactive.png',
            NEW_QUESTION: 'assets/img/iconNotifyAction.png',
            ORANGE_ALERT: 'assets/img/speedUp.png',
            RED_ALERT: 'assets/img/topicQuestionsWaiting.png',
            WELL_DONE: 'assets/img/thumbsUp.png',
            REGULARITY_CREDIT: 'assets/img/points.png',
            NEW_BADGE: 'assets/img/badge.png',
            NEW_CERTIFICATE: 'assets/img/newCertificate.png',
            TOP_LEARNER: 'assets/img/individualLeaderBoard.png',
            TOP_LEARNER_GROUP: 'assets/img/teamLeaderBoard.png',
            TOP_LEARNER_ROLE: 'assets/img/teamLeaderBoard.png',
            ACTIVATION_ALERT: 'assets/img/activationAlert.png',
            USAGE_ALERT: 'assets/img/usageAlert.png',
            TEST_PENDING_PRELIM: 'assets/img/topicTestUnseenFiles.png',
            OPINION_REMINDER:
                notif?.payload?.survey_type === 'SURVEY'
                    ? 'assets/img/surveyReminder.png'
                    : 'assets/img/pollReminder.png',
            UNSEEN_FILES: 'assets/img/unseenFiles.png',
            LEARNER_RANK: 'assets/img/learnerRank.png',
            NEW_TEST: 'assets/img/newTestReleased.png',
            TEST_UNLOCKED: 'assets/img/testUnlocked.png',
            TEST_REMINDER: 'assets/img/testReminder.png',
            RETAKE_TEST: 'assets/img/retakeTest.png',
            TEST_ANSWERS_REVEALED: 'assets/img/testAnswerRevealed.png',
            TEST_AUTO_SUBMITTED: 'assets/img/testSubmitted.png',
            NEW_ASSIGNMENT: 'assets/img/newAndRetakeAssignmentNotif.png',
            ASSIGNMENT_RESUBMISSION_REQUEST: 'assets/img/assignmentSubmitted.png',
            ASSIGNMENT_GRADED: 'assets/img/assignmentGraded.png',
            FEEDBACK_DISAGREED: 'assets/img/feedbackDisagreed.png',
            FEEDBACK_SUBMITTED: 'assets/img/newFeedback.png',
            TOPIC_PENDING_PRELIM: 'assets/img/topicTestUnseenFiles.png',
            NEW_FILE: 'assets/img/newFilesAssigned.png',
            NEW_OPINION: 'assets/img/newOpinionReleased.png',
            FORM_RELEASED: 'assets/img/formsIcon.png',
            FORM_REMINDER: 'assets/img/formDueSoon.png',
            EVENT_REMINDER: 'assets/img/eventReminder.png',
        };
        if (Object.prototype.hasOwnProperty.call(images, notif.scenario)) {
            return images[notif.scenario];
        } else {
            return 'assets/img/raplLogo.png';
        }
    }

    static convertToBlob(dataURI: any) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = dataURI.split(',')[1];
        }
        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }

    static blobToFile(blob: Blob, fileName: any, options?: FilePropertyBag): File {
        const b: any = blob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return b as File;
    }

    static getCurrentStatus(validUntil: any): string {
        const current = new Date();
        const valid = new Date(validUntil);
        
        const currentDate = new Date(
            current.getFullYear(),
            current.getMonth(),
            current.getDate(),
            current.getHours(),
            current.getMinutes(),
            current.getSeconds(),
        );
        
        const validUntilDate = new Date(
            valid.getFullYear(),
            valid.getMonth(),
            valid.getDate(),
            valid.getHours(),
            valid.getMinutes(),
            valid.getSeconds(),
        );
        if (validUntilDate < currentDate) {
            return 'EXPIRED';
        } else {
            return '';
        }
    }

    static checkOtpPrincipal(userUpdated: any) {
        if (JSON.parse(localStorage.getItem('rememberPrincipalOtp'))) {
            const rememberPrincipalVerify = JSON.parse(
                localStorage.getItem('rememberPrincipalOtp'),
            );
            if (
                this.filterArray(rememberPrincipalVerify, 'principal', userUpdated.value) !==
                undefined
            ) {
                const currentOtpPrincipal = this.filterArray(
                    rememberPrincipalVerify,
                    'principal',
                    userUpdated.value,
                );
                const expireOtpTimer = new Date(currentOtpPrincipal.timer);
                const currentTimer = new Date();
                if (Math.floor((expireOtpTimer.getTime() - currentTimer.getTime()) / 1000) > 0) {
                    return true;
                } else {
                    rememberPrincipalVerify.splice(
                        rememberPrincipalVerify.indexOf(currentOtpPrincipal),
                        1,
                    );
                    if (rememberPrincipalVerify.length === 0) {
                        localStorage.removeItem('rememberPrincipalOtp');
                    } else {
                        localStorage.setItem(
                            'rememberPrincipalOtp',
                            JSON.stringify(rememberPrincipalVerify),
                        );
                    }
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    static downloadFile(data: any, remoteConfig) {
        const spinner = document.createElement('div');
        spinner.classList.add('my-loader');
        document.body.appendChild(spinner);
        let blob = null;
        const xhr = new XMLHttpRequest();
        if (data.metaData) {
            xhr.open('GET', data.metaData.sources[0].source);
        } else {
            xhr.open('GET', data.source);
        }
        xhr.responseType = 'blob'; // force the HTTP response, response-type header to be blob
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.setRequestHeader(
            'Access-Control-Allow-Headers',
            'Origin, X-Requested-With, Content-Type, Accept',
        );
        const token = JSON.parse(localStorage.getItem('auth_token'));
        const authToken = `${token.token_type} ${token.access_token}`;
        let axiosConfig: any;
        xhr.setRequestHeader('Authorization', authToken);
        xhr.onload = function () {
            if (xhr.status === 200) {
                document.body.removeChild(spinner);
            }
            blob = xhr.response; // xhr.response is now a blob object
            if (data.metaData) {
                saveAs(blob, `${data.metaData.title}.${data.metaData.sources[0].file_type}`);
            } else {
                saveAs(blob, `${data?.namePrefix}${data.name}.${data.file_type}`);
            }
        };
        xhr.send();
    }

    static getFileSize(bytes: any): string {
        if (bytes < 1024) {
            return `${bytes} Bytes`;
        } else if (bytes < 1048576) {
            return `${(bytes / 1024).toFixed(2)} KB`;
        } else if (bytes < 1073741824) {
            return `${(bytes / 1048576).toFixed(2)} MB`;
        } else {
            return `${(bytes / 1073741824).toFixed()} GB`;
        }
    }

    static getFileDuration(fileDuration: any): string | undefined {
        const seconds = fileDuration / 1000;
        if (seconds < 60) {
            return `${Math.ceil(seconds).toString()} s`;
        } else if (seconds / 60 < 60) {
            if ((seconds % 60).toFixed(0).toString() === '0') {
                return `${this.getRoundOff(seconds / 60).toString()}m`;
            } else {
                return `${this.getRoundOff(seconds / 60).toString()}m ${(seconds % 60).toFixed(0).toString()}s`;
            }
        } else if (seconds / 60 / 60 < 60) {
            return `${(seconds / 60 / 60).toString()}h${((seconds / 60) % 60).toString()}m`;
        }
    }

    static isMobileView() {
        const width = window.innerWidth;
        if (width <= 768) {
            return 'true';
        }
    }

    static getLearnerUnlockCriteriaULPText() {
        return [
            {
                criterion: 'ON_TOPIC_MASTERY',
                function(value: any) {
                    if (value) {
                        return 'On quiz mastery';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_NTH_ITERATION',
                function(value: any = 'N') {
                    return `On iteration ${value} release`;
                },
                value: 'N',
            },
            {
                criterion: 'ON_MASTERING_NTH_QUESTION',
                function(value: any = 'N') {
                    return `On ${value} questions mastery`;
                },
                value: 'N',
            },
            {
                criterion: 'ON_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${value} ${value === 1 ? 'day' : 'days'} from`;
                    } else {
                        return `On ${value} ${value === 1 ? 'day' : 'days'} after release`;
                    }
                },
                value: 'N',
            },
            {
                criterion: 'ON_ASSESSMENT_PASS',
                function(value: any) {
                    if (value) {
                        return 'On test pass';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_ASSESSMENT_NTH_ITERATION',
                function(value: any = 'N') {
                    return `On iteration ${value} release`;
                },
                value: 'N',
            },
            {
                criterion: 'ON_ASSESSMENT_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${value} ${value === 1 ? 'day' : 'days'} from`;
                    } else {
                        return `On ${value} ${value === 1 ? 'day' : 'days'} after release`;
                    }
                },
                value: 'N',
            },
            {
                criterion: 'ON_FILE_VIEW',
                function(value: any) {
                    if (value) {
                        return 'On file view';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_FILE_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${value} ${value === 1 ? 'day' : 'days'} from`;
                    } else {
                        return `On ${value} ${value === 1 ? 'day' : 'days'} after release`;
                    }
                },
                value: 'N',
            },
            {
                criterion: 'ON_POLL_ATTEMPT',
                function(value: any) {
                    if (value) {
                        return 'On poll response';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_POLL_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${value} ${value === 1 ? 'day' : 'days'} from`;
                    } else {
                        return `On ${value} ${value === 1 ? 'day' : 'days'} after release`;
                    }
                },
                value: 'N',
            },
            {
                criterion: 'ON_SURVEY_ATTEMPT',
                function(value: any) {
                    if (value) {
                        return 'On survey response';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_SURVEY_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${value} ${value === 1 ? 'day' : 'days'} from`;
                    } else {
                        return `On ${value} ${value === 1 ? 'day' : 'days'} after release`;
                    }
                },
                value: 'N',
            },
            {
                criterion: 'ON_ASSIGNMENT_FIRST_SUBMISSION',
                function(value: any) {
                    if (value) {
                        return 'On assignment submission';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_ASSIGNMENT_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${value} ${value === 1 ? 'day' : 'days'} from`;
                    } else {
                        return `On ${value} ${value === 1 ? 'day' : 'days'} after release`;
                    }
                },
                value: 'N',
            },
        ];
    }

    static isNthDayUnlockCriteria(criterion) {
        const nthReleaseCriterionObj = [
            'ON_NTH_DAY_AFTER_RELEASE',
            'ON_ASSESSMENT_NTH_DAY_AFTER_RELEASE',
            'ON_FILE_NTH_DAY_AFTER_RELEASE',
            'ON_POLL_NTH_DAY_AFTER_RELEASE',
            'ON_SURVEY_NTH_DAY_AFTER_RELEASE',
            'ON_ASSIGNMENT_NTH_DAY_AFTER_RELEASE',
        ];
        return nthReleaseCriterionObj.some((key) => key.includes(criterion));
    }

    static isNthIterationUnlockCriteria(criterion) {
        const nthReleaseCriterionObj = ['ON_NTH_ITERATION', 'ON_ASSESSMENT_NTH_ITERATION'];
        return nthReleaseCriterionObj.some((key) => key.includes(criterion));
    }

    static isNthMasteringUnlockCriteria(criterion) {
        const nthReleaseCriterionObj = ['ON_MASTERING_NTH_QUESTION'];
        return nthReleaseCriterionObj.some((key) => key.includes(criterion));
    }

    static addNumberToDate(date, daysToBeAdded) {
        const getDate = new Date(date);
        return getDate.setDate(getDate.getDate() + parseInt(daysToBeAdded));
    }

    static getRoundOff(value) {
        return Math.floor(value);
    }

    static updateRemUser(user: any) {
        const remUser = JSON.parse(localStorage.getItem('remember_user'));
        if (remUser?.length) {
            const filteredUsers = this.filterArray(remUser, 'user_id', user.user_id);
            filteredUsers.first_name = user.first_name;
            filteredUsers.last_name = user.last_name;
            filteredUsers.avatar = user.avatar;
            if (filteredUsers) {
                remUser.splice(remUser.indexOf(filteredUsers), 1);
                remUser.unshift(filteredUsers);
            }
            localStorage.setItem('remember_user', JSON.stringify(remUser));
        }
    }

    static validateUsername(uname: any) {
        const numberFormat = this.mobileNumberService.validateMobileNumber(uname);
        if (numberFormat) {
            return PrincipalType[PrincipalType.MOBILE];
        }
        const mailFormat =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        if (mailFormat.test(uname)) {
            return PrincipalType[PrincipalType.EMAIL];
        }
    }

    static filterArray(arr: any[], key: string, value: any) {
        if (arr && arr.length > 0) {
            const obj = arr.filter((trans) => trans[key] === value);
            return obj[0];
        }
        return {};
    }
}
