<div class="pre-login-layout">
  <div class="row security-outer">
    <div class="col-12 col-md-12 col-lg-3"></div>
    <div class="col-12 col-md-12 col-lg-6 my-auto security-login-outer ps-0 pe-0">
      <div class="security-form-outer">
        @if (getOrg()) {
          <div class="text-center org-branding-logo-outer" [ngClass]="{ 'org-branding-name-outer': !getOrg().logo }">
            @if (getOrg().logo) {
              <img width="100" height="100" alt="RapL" draggable="false" class="" [src]="getOrg().logo" onError="this.onerror=null;this.src='assets/img/loader.gif';" />
            }
            @if (!getOrg().logo) {
              <h1 class="loginheading">{{ getOrg()?.name }}</h1>
            }
          </div>
        }
        <div class="prelogin-outer">
          <div class="form-field-layout inner-content">
            @if (apiLoading) {
              <div class="mt-4">
                <div class="text text-center">Fetching your learning materials...</div>
                <div class="box">
                  <div class="comp"></div>
                  <div class="loader"></div>
                  <div class="con"></div>
                  <div class="byte"></div>
                  <div class="server"></div>
                </div>
              </div>
            } @else {
              @if (errMessage) {
                <div class="error-section text-center">
                  <span class="mdi mdi-alert"></span>
                  <h4 class="mb-3">Sorry!</h4>
                  <h4 class="mb-3">Something went wrong!</h4>
                  <div class="use-another-account text-center border-0">
                    <a (click)="backToLogin()" class="cursor-pointer mt-2">Try after sometime</a>
                  </div>
                </div>
              } @else {
                <div class="success-section text-center mt-4">
                  <span class="mdi mdi-check-circle"></span>
                  <div class="use-another-account text-center border-0">
                    <p class="text-center mb-0 success-info">Success</p>
                  </div>
                </div>
              }
              <ng-template #success>
                <div class="success-section text-center mt-4">
                  <span class="mdi mdi-check-circle"></span>
                  <div class="use-another-account text-center border-0">
                    <p class="text-center mb-0 success-info">Success</p>
                  </div>
                </div>
              </ng-template>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-12 col-lg-3"></div>
  </div>
</div>
