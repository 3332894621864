import { Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ReloadBoxService {
    // display confim
    public status: Subject<boolean> = new Subject();
    public countDown = 10;
    public _active = false;
    // button Observable
    public clickStream = new Subject<boolean>();
    @Output() observable = this.clickStream.asObservable();
    public get active(): boolean {
        return this._active;
    }
    public set active(v: boolean) {
        this._active = v;
        this.status.next(v);
    }
    public show(): void {
        this.active = true;
        this.countDown = 10;
        this.updateTimer();
        setTimeout(() => {
            // After 10 sec close reload box and call bind function
            this.confirmed(true);
        }, 10000);
    }
    public updateTimer() {
        const counter = setInterval(() => {
            this.countDown = this.countDown - 1;
            if (this.countDown < 0) {
                clearInterval(counter);
            }
        }, 1000);
    }
    public hide(): void {
        this.active = false;
    }
    confirmed(accept: boolean) {
        this.active = false;
        this.clickStream.next(accept);
    }
}
