import { Injectable } from '@angular/core';
import { Util } from '../utils';
declare let require: any;

@Injectable()
export class LanguageService {
    language = require('./../json/language.json');
    public getSupportedLanguage() {
        this.language.filter(function (lang) {
            lang.default = false;
        });
        const currentUser = JSON.parse(localStorage.getItem('current_user'));
        const settings = currentUser.org.settings;
        const supportedLang = Util.filterArray(
            settings,
            'property',
            'supported_languages',
        ).value.split(',');
        Util.sortByKey(this.language, 'languageKey');
        return this.language.filter(function (lang) {
            return supportedLang.indexOf(lang.value) > -1;
        });
    }
}
