import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanDeactivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { BackWarningService } from 'app/back-warning-modal/back-warning-modal.service';
import { Favicons } from 'app/favicons';
import { PermissionService } from 'app/shared/service/permission.service';
import { TakeQuizComponent } from 'app/user_app/take-quiz/take-quiz.component';
import { NotificationService } from './service/notification.service';
import { Util } from './utils';
import { MyGenieComponent } from 'app/user_app/ask-rapl/ask-rapl.component';
import { FormFillingComponent } from 'app/user_app/my-forms/form-filling/form-filling.component';
import { AuthGuardService } from './service/auth-guard.service';

@Injectable()
export class AuthGuard implements CanActivate {
    public isLogin: boolean;
    public isLoginArr = [];
    constructor(
        public router: Router,
        public favicons: Favicons,
        public notificationService: NotificationService,
    ) {}

    canActivate(): Observable<boolean> | boolean {
        return this.checkLogin();
    }

    isLoggedIn(): boolean {
        const activatedRoute = this.router.url;
        if (Util.checkValidAuthToken()) {
            
            if (
                !activatedRoute.startsWith('/app') &&
                !activatedRoute.startsWith('/monitor') &&
                !activatedRoute.startsWith('/admin') &&
                activatedRoute.startsWith('/security') &&
                !activatedRoute.startsWith('/review')
            ) {
                this.isLoginArr.push(true);
                this.navigateAfterLogin();
            }
            return true;
        } else {
            if (
                localStorage.getItem('org') &&
                activatedRoute !== '/' &&
                !activatedRoute.startsWith('/security')
            ) {
                this.favicons.activate('default');
                this.router.navigate(['/security/login']);
            }
            return false;
        }
    }

    navigateAfterLogin() {
        if (this.isLoginArr.length > 2) {
            this.isLoginArr = [];
            if (localStorage.getItem('user_selected_language_menu')) {
                this.router.navigate(['/app/profile/language']);
            } else {
                if (this.notificationService.newNotif) {
                    this.favicons.activate('notify');
                } else {
                    this.favicons.activate('default');
                }
                Util.privilegeBasedModule(this.router);
            }
        }
    }

    checkLogin(): boolean {
        if (!this.isLoggedIn()) {
            this.router.navigate(['/security/login']);
        }
        return this.isLoggedIn();
    }
}

@Injectable()
export class SecurityGuard implements CanActivate {
    constructor(public router: Router) {}

    canActivate(): Observable<boolean> | boolean {
        const isLogin = Boolean(localStorage.getItem('auth_token'));
        if (isLogin) {
            Util.privilegeBasedModule(this.router);
        }
        return !isLogin;
    }
}

@Injectable()
export class PermissionGuard implements CanActivate {
    ROUTE_PATHS = {
        ORG_SETTING: 'orgSetting',
        CERTIFICATE: 'certificate',
        FEEDBACK: 'feedBack',
        MANAGE_PAUSE_LEARNING: 'managePauseLearning',
        EMIAL_TEMPLATE: 'emailTemplate',
        CRAFT: 'manageCraft',
        GENIE: 'manageContent/genie',
        FORM: 'form',
    };

    SETTINGS = {
        ENABLE_CERTIFICATES: 'enable_certificates',
        FEEDBACK_ENABLED: 'feedback_enabled',
        ENABLE_VACATIONS: 'enable_vacations',
        CUSTOMIZE_EMAIL_TEMPLATE: 'customize_email_template',
        ENABLE_CRAFT: 'enable_craft',
        ENABLE_FORMS: 'enable_form',
    };

    constructor(
        public router: Router,
        public permission: PermissionService,
        public authGuardService: AuthGuardService,
    ) {}
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        this.permission.reload();
        const currentUser = JSON.parse(localStorage.getItem('current_user'));
        if (currentUser['privileges'].length > 0) {
            const privileges = route.data['privileges'];
            if (privileges) {
                const validPermission = this.permission?.has(privileges);
                if (!validPermission) {
                    return this.redirectTo404();
                }
                const routePath = route.routeConfig?.path;
                if (this.isRestrictedRoute(routePath, currentUser.role)) {
                    return this.redirectTo404();
                }
                return validPermission;
            }
        } else {
            this.router.navigateByUrl('/404', { skipLocationChange: true });
            return false;
        }
    }

    redirectTo404(): boolean {
        this.router.navigateByUrl('/404', { skipLocationChange: true });
        return false;
    }
    isRestrictedRoute(path: string, role: string) {
        return (
            (path === this.ROUTE_PATHS.ORG_SETTING && role !== 'RAPL_ADMIN') ||
            (path === this.ROUTE_PATHS.CERTIFICATE &&
                !this.authGuardService.isSettingEnabled(this.SETTINGS.ENABLE_CERTIFICATES)) ||
            (path === this.ROUTE_PATHS.FEEDBACK &&
                !this.authGuardService.isSettingEnabled(this.SETTINGS.FEEDBACK_ENABLED)) ||
            (path === this.ROUTE_PATHS.MANAGE_PAUSE_LEARNING &&
                !this.authGuardService.isSettingEnabled(this.SETTINGS.ENABLE_VACATIONS)) ||
            (path === this.ROUTE_PATHS.EMIAL_TEMPLATE &&
                !this.authGuardService.isSettingEnabled(this.SETTINGS.CUSTOMIZE_EMAIL_TEMPLATE)) ||
            (path.includes(this.ROUTE_PATHS.CRAFT) &&
                !this.authGuardService.isSettingEnabled(this.SETTINGS.ENABLE_CRAFT)) ||
            (path === this.ROUTE_PATHS.FORM &&
                !this.authGuardService.isSettingEnabled(this.SETTINGS.ENABLE_FORMS))
        );
    }
}

@Injectable()
export class LearnerPermissionGuard implements CanActivate {
    ROUTE_PATHS = {
        MY_SUBSCRIPTION: 'mysubscription',
        MY_FORMS: 'forms',
        MY_FEEDBACK: 'myfeedback',
        MY_CERTIFICATES: 'myCertificates',
        MY_GENIE: 'myGenie',
        GENIE: 'manageContent/genie',
        REVIEW: 'review',
        MY_VACATION: 'pauseLearning',
        EVENTS: 'events',
        PAST_EVENTS: 'pastEvents',
    };

    SETTINGS = {
        ENABLE_SUBSCRIPTION: 'subscription_enabled',
        ENABLE_FORMS: 'enable_form',
        FEEDBACK_ENABLED: 'feedback_enabled',
        ENABLE_CERTIFICATES: 'enable_certificates',
        GENIE_EXCLUDED_ROLES: 'excluded_roles_from_genie',
        REVIEW_ENABLED: 'review_enabled',
        VACATION_ENABLED: 'enable_vacations',
        EVENTS_ENABLED: 'enable_events',
    };

    constructor(
        public router: Router,
        public authGuardService: AuthGuardService,
    ) {}
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        const currentUser = JSON.parse(localStorage.getItem('current_user'));
        const routePath = route.routeConfig?.path;
        if (this.isRestrictedRoute(routePath, currentUser.role)) {
            return this.redirectTo404();
        }
    }

    redirectTo404(): boolean {
        this.router.navigateByUrl('/404', { skipLocationChange: true });
        return false;
    }
    isRestrictedRoute(path: string, role: string) {
        return (
            (path === this.ROUTE_PATHS.MY_SUBSCRIPTION &&
                !this.authGuardService.isSettingEnabled(this.SETTINGS.ENABLE_SUBSCRIPTION)) ||
            (path === this.ROUTE_PATHS.MY_FORMS &&
                !this.authGuardService.isSettingEnabled(this.SETTINGS.ENABLE_FORMS)) ||
            (path === this.ROUTE_PATHS.MY_FEEDBACK &&
                !this.authGuardService.isSettingEnabled(this.SETTINGS.FEEDBACK_ENABLED)) ||
            (path === this.ROUTE_PATHS.MY_CERTIFICATES &&
                !this.authGuardService.isSettingEnabled(this.SETTINGS.ENABLE_CERTIFICATES)) ||
            (path === this.ROUTE_PATHS.MY_VACATION &&
                !this.authGuardService.isSettingEnabled(this.SETTINGS.VACATION_ENABLED)) ||
            ((path.includes(this.ROUTE_PATHS.EVENTS) || path === this.ROUTE_PATHS.PAST_EVENTS) &&
                !this.authGuardService.isSettingEnabled(this.SETTINGS.EVENTS_ENABLED)) ||
            ((path === this.ROUTE_PATHS.MY_GENIE || path === this.ROUTE_PATHS.GENIE) &&
                this.authGuardService.isGenieRestricted(
                    this.SETTINGS.GENIE_EXCLUDED_ROLES,
                    role,
                )) ||
            (path === this.ROUTE_PATHS.REVIEW &&
                !this.authGuardService.isSettingEnabled(this.SETTINGS.REVIEW_ENABLED))
        );
    }
}

@Injectable()
export class FormPermissionGuard implements CanActivate {
    constructor(
        public router: Router,
        public permission: PermissionService,
    ) {}
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        this.permission.reload();
        const currentUser = JSON.parse(localStorage.getItem('current_user'));
        if (currentUser['privileges'].length > 0) {
            const privileges = route.data['privileges'];
            const validPermission = this.permission.has(privileges);
            if (!validPermission) {
                this.router.navigateByUrl('/404', { skipLocationChange: true });
            }
            if (!this.isFormEnabled()) {
                this.router.navigateByUrl('/404', { skipLocationChange: true });
                return false;
            }
            return validPermission;
        } else {
            this.router.navigateByUrl('/404', { skipLocationChange: true });
            return false;
        }
    }
    isFormEnabled() {
        const orgSetting = JSON.parse(localStorage.getItem('orgSetting'));
        if (Util.filterArray(orgSetting.settings, 'property', 'enable_form') !== undefined) {
            return Util.filterArray(orgSetting.settings, 'property', 'enable_form').value;
        } else {
            return false;
        }
    }
}

@Injectable()
export class AdminPermissionGuard implements CanActivate {
    constructor(public router: Router) {}
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        const currentUser = JSON.parse(localStorage.getItem('current_user'));
        if (currentUser['privileges'].length > 0) {
            return true;
        } else {
            this.router.navigateByUrl('/404', { skipLocationChange: true });
            return false;
        }
    }
}

// Take Quiz Conformation Warning modal.
@Injectable()
export class QuizConfirmDeactivateGuard implements CanDeactivate<TakeQuizComponent> {
    constructor(public BackWarning: BackWarningService) {}
    canDeactivate(component: TakeQuizComponent): Promise<boolean> {
        if (!component.quizBackWarningModal()) {
            return this.BackWarning.confirm();
        }
        return Promise.resolve(true);
    }
}

// Ask Rapl Conformation Warning modal.
@Injectable()
export class MyGenieConfirmDeactivateGuard implements CanDeactivate<MyGenieComponent> {
    constructor(public BackWarning: BackWarningService) {}
    canDeactivate(component: MyGenieComponent): Promise<boolean> {
        if (!component.myGenieBackWarningModal()) {
            return this.BackWarning.confirm();
        }
        return Promise.resolve(true);
    }
}

@Injectable({ providedIn: 'root' })
export class FormConfirmDeactivateGuard implements CanDeactivate<FormFillingComponent> {
    canDeactivate(
        component: FormFillingComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return component.canDeactivate();
    }
}
