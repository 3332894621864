import { Injectable } from '@angular/core';
import { PermissionService } from 'app/shared/service/permission.service';

@Injectable()
export class ChunkInitializationService {
    public permission = new PermissionService();
    public async downloadModule(module, router): Promise<boolean> {
        switch (module) {
            case 'monitor':
                try {
                    await import('app/monitor/monitor.module');
                    return true;
                } catch (error) {
                    router.navigate(['/app/home']);
                    this.stop();
                    return false;
                }
            case 'admin':
                try {
                    await import('app/admin/admin.module');
                    return true;
                } catch (error) {
                    this.stop();
                    router.navigate(['/app/home']);
                    return false;
                }
            case 'review':
                try {
                    await import('app/review/review.module');
                    return true;
                } catch (error) {
                    router.navigate(['/app/home']);
                    this.stop();
                    return false;
                }
            case 'learner':
                try {
                    await import('app/user_app/main.module');
                    return true;
                } catch (error) {
                    router.navigate(['/app/home']);
                    this.stop();
                    return false;
                }
            case 'form':
                try {
                    await import('app/form/form.module');
                    return true;
                } catch (error) {
                    router.navigate(['/app/home']);
                    this.stop();
                    return false;
                }
            default:
                router.navigate(['/app/home']);
                this.stop();
                break;
        }
    }

    public start(): void {
        localStorage.setItem('showChunkInitialization', 'true');
    }

    public stop(): void {
        localStorage.removeItem('showChunkInitialization');
    }
}
