<div class="modal-header">
  <h4 class="modal-title w-100">
    {{ selectedFeedback?.name }}
    <button
      type="button"
      class="btn-close float-end"
      data-dismiss="modal"
      aria-label="Close"
      (click)="activeModal.close(modalType === 'nodeSubmitFeedback' || modalType === 'bundleSubmitFeedback' ? true : false)"></button>
  </h4>
</div>
<div class="modal-body p-lg-3 p-0">
  @if (!selectedFeedback) {
    <div class="my-loader"></div>
  }
  <form #feedbackTemplateForm="ngForm" (ngSubmit)="feedbackSubmission(feedbackTemplateForm)" novalidate>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-lg-8 col-sm-8 card-outer mb-2">
          @for (question of feedbackQuestion; track question; let n = $index) {
            <div class="card mb-4">
              @if (question.fileUploading) {
                <div class="processing-outer d-flex align-items-center justify-content-center w-100">
                  <div class="processing" app-translate="LEARNER.PREPARING" [translate]="'LEARNER.PREPARING'">
                    <div class="processing-box position-relative">
                      <p class="preparing">Preparing...</p>
                      <span>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </span>
                    </div>
                  </div>
                </div>
              }
              <div class="card-body">
                <h6 class="mb-3 d-flex question-title text-justify">
                  <span class="me-1"> {{ n + 1 }}. </span>
                  <markdown class="markdown-viewer" [data]="question.title"></markdown>
                  @if (question.is_mandatory) {
                    <span class="mandatory-question ms-1"> [*] </span>
                  }
                </h6>
                <div>
                  @switch (question.questionType) {
                    @case ('fileUpload') {
                      <div class="ms-3">
                        <!-- PDF fileUpload -->
                        @if (modalType === 'nodeSubmitFeedback' || modalType === 'bundleSubmitFeedback') {
                          <div class="form-group col-md-12">
                            <div class="container">
                              <div class="row">
                                <div class="col-sm-4 ps-0">
                                  <span matIconSuffix>
                                    <label
                                      [ngClass]="{
                                        'btn-outline-danger': feedbackTemplateFormSubmit && question.is_mandatory && !question.selectedFile?.size,
                                        'hover-red': feedbackTemplateFormSubmit && question.is_mandatory && !question.selectedFile?.size,
                                      }"
                                      for="upload-file{{ question.question_id }}"
                                      class="btn btn-outline-primary mb-0">
                                      <span [ngClass]="{ 'text-red': feedbackTemplateFormSubmit && question.is_mandatory && !question.selectedFile?.size }">
                                        + Add Attachment
                                      </span>
                                    </label>
                                    <input
                                      type="file"
                                      #attachment
                                      (change)="fileChangeListener($event, question)"
                                      attachment.value=""
                                      class="file-input"
                                      name="upload-avatar"
                                      id="upload-file{{ question.question_id }}"
                                      hidden />
                                  </span>
                                </div>
                                <div class="col-sm-8 ps-0 pe-0">
                                  @if (question.answers.name && question.selectedFile.size) {
                                    <div class="selected-file">
                                      <span>
                                        @if (getFileType(question.selectedFile) === supportedFeedbackFileTypes.PDF) {
                                          <span class="mdi mdi-24px text-muted align-middle me-1 mdi-file-pdf-box"></span>
                                        }
                                        @if (getFileType(question.selectedFile) === supportedFeedbackFileTypes.IMAGE) {
                                          <span class="mdi mdi-24px text-muted align-middle me-1 mdi-file-image"></span>
                                        }
                                      </span>
                                      {{ question.answers.name }}
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        @if (modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse') {
                          <div class="response-file">
                            @if (question.answers.name && question.answers.source) {
                              <div>
                                <span>
                                  <span
                                    class="mdi mdi-24px text-muted align-middle me-1"
                                    [ngClass]="{
                                      'mdi-file-question': isSupportedFileType(question.answers),
                                      'mdi-file-pdf-box': question.answers.type === supportedFeedbackFileTypes.PDF,
                                      'mdi-file-image': question.answers.type === supportedFeedbackFileTypes.IMAGE,
                                    }"></span>
                                </span>
                                {{ question.answers.name }} ({{ getFileSize(question.answers.file_size) }})
                                <button
                                  type="button"
                                  class="btn btn-sm align-middle rounded-3 d-inline-flex align-items-center download-file"
                                  (click)="downloadFile(question.answers)">
                                  <span [class]="isSupportedFileType(question.answers) ? 'mdi-download-off disabled' : 'mdi-download'" class="fs-4 mdi me-1"> </span>
                                  <span>Download</span>
                                </button>
                              </div>
                            }
                            @if (!question.answers.name && !question.answers.source) {
                              <div>No file found</div>
                            }
                          </div>
                        }
                      </div>
                    }
                    @case ('multipleChoice') {
                      <div class="ms-3">
                        <ul class="list-group multiple-choice">
                          <!-- multipleChoice options -->
                          @for (choice of question.answers['choices']; track indexTracker(n, choice); let n = $index) {
                            <li class="list-group-item p-0">
                              <div class="form-group mb-0">
                                <mat-checkbox
                                  name="option{{ choice.choice_id }}"
                                  class="ms-3 mt-1"
                                  [disabled]="modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse'"
                                  [(ngModel)]="choice.selected">
                                  <span class="ms-2 mt-2 checkbox-outer">
                                    <markdown class="markdown-viewer" [data]="choice.title"></markdown>
                                  </span>
                                </mat-checkbox>
                              </div>
                            </li>
                          }
                          <!-- multipleChoice others -->
                          @if (question.answers.other?.title) {
                            <li class="list-group-item p-0">
                              <div class="form-group mb-0">
                                <mat-checkbox
                                  name="optionOther{{ question.answers.other.other_id }}"
                                  class="ms-3 mt-1"
                                  (change)="multipleChoiceOtherChange(question, $event.checked)"
                                  [disabled]="modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse'"
                                  [(ngModel)]="question.answers['other'].selected">
                                  <span class="ms-2 mt-2 checkbox-outer">{{ question.answers['other'].title }}</span>
                                </mat-checkbox>
                                <!-- multipleChoice other text box -->
                                @if (question.answers['other'].title) {
                                  <div class="form-group other-text-box">
                                    <mat-form-field class="float-start w-100">
                                      <mat-label>{{
                                        modalType !== 'nodeFeedbackResponse' && modalType !== 'bundleFeedbackResponse' && modalType !== 'notifyFeedbackResponse'
                                          ? 'Enter your other text'
                                          : ''
                                      }}</mat-label>
                                      <input
                                        appSanitizeInput
                                        matInput
                                        name="multipleChoiceOther{{ question.answers['other'].other_id }}"
                                        [disabled]="
                                          !question.answers['other'].selected ||
                                          modalType === 'nodeFeedbackResponse' ||
                                          modalType === 'bundleFeedbackResponse' ||
                                          modalType === 'notifyFeedbackResponse'
                                        "
                                        [hidden]="modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse'"
                                        [required]="question.answers.other.selected"
                                        autofocus
                                        #multipleChoiceOther
                                        #name="ngModel"
                                        [(ngModel)]="question.answers['other'].text"
                                        maxlength="100" />
                                      @if (question.answers.other?.text) {
                                        <div
                                          [hidden]="modalType !== 'nodeFeedbackResponse' && modalType !== 'bundleFeedbackResponse' && modalType !== 'notifyFeedbackResponse'"
                                          class="other-option-text choice-option"
                                          [ngClass]="{
                                            'other-option-disabled':
                                              modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse',
                                          }">
                                          <markdown class="markdown-viewer" [data]="question.answers.other?.text"></markdown>
                                        </div>
                                      }
                                      <mat-hint
                                        align="end"
                                        [hidden]="modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse'">
                                        {{ multipleChoiceOther.value.length }} / 100
                                      </mat-hint>
                                      @if (name.errors && feedbackTemplateFormSubmit) {
                                        <mat-hint>
                                          <div class="form-control-feedback">
                                            <span [hidden]="!name.errors.required">{{ adminConstant.REQ }}</span>
                                            <span [hidden]="!name.errors.maxlength">{{ adminConstant.GRP_MAX_CHAR }}</span>
                                          </div>
                                        </mat-hint>
                                      }
                                    </mat-form-field>
                                  </div>
                                }
                              </div>
                            </li>
                          }
                        </ul>
                      </div>
                    }
                    @case ('multipleTextboxes') {
                      <div class="ms-3 multiple-textboxes-outer">
                        <!-- multipleTextboxes -->
                        @for (row of question.answers['rows']; track indexTracker(n, row); let n = $index) {
                          <div class="form-group">
                            <div class="d-flex align-items-center">
                              <label class="multiple-textboxes-label">
                                <markdown class="markdown-viewer" [data]="row.title"></markdown>
                              </label>
                              @if (modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse') {
                                <markdown class="markdown-viewer" [data]="row.text"></markdown>
                              }
                              @if (modalType !== 'nodeFeedbackResponse' && modalType !== 'bundleFeedbackResponse' && modalType !== 'notifyFeedbackResponse') {
                                <mat-form-field class="w-75">
                                  <mat-label>{{
                                    modalType !== 'nodeFeedbackResponse' && modalType !== 'bundleFeedbackResponse' && modalType !== 'notifyFeedbackResponse'
                                      ? 'Enter your text'
                                      : ''
                                  }}</mat-label>
                                  <input
                                    appSanitizeInput
                                    matInput
                                    name="multipleTextBox{{ row.row_id }}"
                                    autofocus
                                    #multipleTextBox
                                    #name="ngModel"
                                    [(ngModel)]="row.text"
                                    [disabled]="modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse'"
                                    [required]="question.is_mandatory"
                                    maxlength="100" />
                                  <mat-hint
                                    align="end"
                                    [hidden]="modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse'">
                                    {{ multipleTextBox.value.length }} / 100</mat-hint
                                  >
                                  @if (name.errors && feedbackTemplateFormSubmit) {
                                    <mat-hint>
                                      <div class="form-control-feedback">
                                        <span [hidden]="!name.errors.required">{{ adminConstant.REQ }}</span>
                                        <span [hidden]="!name.errors.maxlength">{{ adminConstant.GRP_MAX_CHAR }}</span>
                                      </div>
                                    </mat-hint>
                                  }
                                </mat-form-field>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    }
                    @case ('singleAnswer') {
                      <div class="ms-3">
                        <!-- singleAnswer text box -->
                        <div class="form-group">
                          @if (modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse') {
                            <markdown class="markdown-viewer" [data]="question.answers.text"></markdown>
                          }
                          @if (modalType !== 'nodeFeedbackResponse' && modalType !== 'bundleFeedbackResponse' && modalType !== 'notifyFeedbackResponse') {
                            <mat-form-field class="float-start w-100">
                              <mat-label>Enter your text</mat-label>
                              <input
                                appSanitizeInput
                                matInput
                                name="singleAnswer{{ question.question_id }}"
                                autofocus
                                #singleAnswer
                                #name="ngModel"
                                [(ngModel)]="question.answers.text"
                                maxlength="100"
                                [required]="question.is_mandatory" />
                              <mat-hint align="end">{{ singleAnswer.value.length }} / 100</mat-hint>
                              @if (name.errors && feedbackTemplateFormSubmit) {
                                <mat-hint>
                                  <div class="form-control-feedback">
                                    <span [hidden]="!name.errors.required">{{ adminConstant.REQ }}</span>
                                    <span [hidden]="!name.errors.maxlength">{{ adminConstant.GRP_MAX_CHAR }}</span>
                                  </div>
                                </mat-hint>
                              }
                            </mat-form-field>
                          }
                        </div>
                      </div>
                    }
                    @case ('singleChoice') {
                      <div class="ms-3">
                        <ul class="list-group">
                          <mat-radio-group aria-labelledby="radio-group" class="radio-group">
                            <!-- singleChoice choice -->
                            @for (choice of question.answers['choices']; track indexTracker(n, choice); let n = $index) {
                              <mat-radio-button
                                [value]="choice.choice_id"
                                [checked]="choice.selected"
                                [disabled]="modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse'"
                                (change)="singleChoiceChange(question, 'choice', $event.value)">
                                <span class="radio-outer">
                                  <markdown class="markdown-viewer" [data]="choice.title"></markdown>
                                </span>
                              </mat-radio-button>
                            }
                            <!-- singleChoice other -->
                            @if (question.answers.other?.title) {
                              <mat-radio-button
                                [ngClass]="{ 'other-radio-btn': question?.answers?.other?.selected }"
                                [value]="question.answers['other'].other_id"
                                [checked]="question.answers['other'].selected"
                                [disabled]="modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse'"
                                (change)="singleChoiceChange(question, 'other', $event.value)">
                                <span class="other-radio-outer">
                                  <markdown class="markdown-viewer" [data]="question.answers['other'].title"></markdown>
                                </span>
                                <!--singleChoice other text box -->
                                @if (question.answers['other'].title) {
                                  <div class="form-group">
                                    @if (question.answers['other'].selected) {
                                      <mat-form-field class="float-start w-100 mt-4">
                                        <mat-label>{{
                                          modalType !== 'nodeFeedbackResponse' && modalType !== 'bundleFeedbackResponse' && modalType !== 'notifyFeedbackResponse'
                                            ? 'Enter your other text'
                                            : ''
                                        }}</mat-label>
                                        <input
                                          class="mt-1"
                                          appSanitizeInput
                                          matInput
                                          name="singleChoiceOther{{ question.answers['other'].other_id }}"
                                          [required]="question.answers.other.selected"
                                          autofocus
                                          [disabled]="!question.answers['other'].selected || modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse'"
                                          [hidden]="modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse'"
                                          #singleChoiceOther
                                          #name="ngModel"
                                          [(ngModel)]="question.answers['other'].text"
                                          maxlength="100" />
                                        @if (question.answers.other?.text) {
                                          <div
                                            [hidden]="modalType !== 'nodeFeedbackResponse' && modalType !== 'bundleFeedbackResponse' && modalType !== 'notifyFeedbackResponse'"
                                            class="other-option-text choice-option">
                                            <markdown class="markdown-viewer" [data]="question.answers.other?.text"></markdown>
                                          </div>
                                        }
                                        <mat-hint
                                          align="end"
                                          [hidden]="modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse'">
                                          {{ singleChoiceOther.value.length }} / 100
                                        </mat-hint>
                                        @if (name.errors && feedbackTemplateFormSubmit) {
                                          <mat-hint>
                                            <div class="form-control-feedback">
                                              <span [hidden]="!name.errors.required">{{ adminConstant.REQ }}</span>
                                              <span [hidden]="!name.errors.maxlength">{{ adminConstant.GRP_MAX_CHAR }}</span>
                                            </div>
                                          </mat-hint>
                                        }
                                      </mat-form-field>
                                    }
                                  </div>
                                }
                              </mat-radio-button>
                            }
                          </mat-radio-group>
                        </ul>
                      </div>
                    }
                    @case ('textArea') {
                      <div class="ms-3">
                        <!-- textArea -->
                        @if (modalType !== 'nodeFeedbackResponse' && modalType !== 'bundleFeedbackResponse' && modalType !== 'notifyFeedbackResponse') {
                          <mat-form-field class="w-100" [ngClass]="{ 'has-danger mat-form-field-invalid': feedbackTemplateFormSubmit && !question.answers.text }">
                            <mat-label>{{
                              modalType !== 'nodeFeedbackResponse' && modalType !== 'bundleFeedbackResponse' && modalType !== 'notifyFeedbackResponse' ? 'Enter your text' : ''
                            }}</mat-label>
                            <textarea
                              appSanitizeInput
                              matInput
                              mdTextareaAutosize
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize"
                              class="feedback-essay-type"
                              maxlength="5000"
                              #textArea
                              name="textArea{{ question.question_id }}"
                              #name="ngModel"
                              [required]="question.is_mandatory"
                              [(ngModel)]="question.answers.text"
                              [ngClass]="{
                                'text-area-auto-expand': modalType !== 'nodeFeedbackResponse' && modalType !== 'bundleFeedbackResponse' && modalType !== 'notifyFeedbackResponse',
                              }">
                            </textarea>
                            <mat-hint
                              align="end"
                              [hidden]="modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse'">
                              {{ textArea.value.length }} / 5000
                            </mat-hint>
                            @if (feedbackTemplateFormSubmit && !question.answers.text) {
                              <mat-hint>
                                <div class="form-control-feedback">
                                  {{ adminConstant.REQ }}
                                </div>
                              </mat-hint>
                            }
                          </mat-form-field>
                        }
                        @if (modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse') {
                          <markdown class="markdown-viewer" [data]="question.answers.text"></markdown>
                        }
                      </div>
                    }
                  }
                </div>
              </div>
            </div>
          }
        </div>
        @if (feedbackQuestion) {
          <div class="col-12 col-sm-4 col-lg-4 pe-0">
            <div class="card">
              <div class="card-body">
                <small class="text-muted">Feedback for</small>
                <h5 class="user-name mt-2">
                  <img class="me-1 user-avatar rounded-circle" appSecureImg [url]="selectedUser?.avatar ?? 'assets/img/user.svg'" alt="" width="20" height="20" />
                  {{ selectedUser?.first_name }} {{ selectedUser?.last_name }}
                </h5>
                @if (
                  feedbackResponse?.submission?.acknowledgement?.status &&
                  (modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse')
                ) {
                  <div class="mt-3">
                    <small class="text-muted">Acknowledgement status</small>
                    @if (feedbackResponse.submission.acknowledgement.status === 'ACKNOWLEDGED' && feedbackResponse.submission.acknowledgement.response === 'AGREED') {
                      <h6 class="mt-2 feedback-status feedback-ack-acknowledged mb-0">
                        <span class="mdi mdi-check-circle-outline me-1"></span>
                        <span class="date">Agreed</span>
                      </h6>
                    }
                    @if (feedbackResponse.submission.acknowledgement.status === 'ACKNOWLEDGED' && feedbackResponse.submission.acknowledgement.response === 'DISAGREED') {
                      <h6 class="mt-2 feedback-status feedback-ack-disagree mb-0">
                        <span class="mdi mdi-alert-circle-outline me-1"></span>
                        <span class="date">Disagreed</span>
                      </h6>
                    }
                    @if (feedbackResponse.submission.acknowledgement.status === 'NOT_REQUIRED') {
                      <h6 class="mt-2 feedback-status feedback-ack-unack mb-0">
                        <span class="mdi mdi-check-circle-outline me-1"></span>
                        <span class="date">Acknowledgement not required</span>
                      </h6>
                    }
                    @if (feedbackResponse.submission.acknowledgement.status === 'PENDING') {
                      <h6 class="mt-2 feedback-status feedback-ack-pending mb-0">
                        <span class="mdi mdi-check-circle-outline me-1"></span>
                        <span class="date">Pending</span>
                      </h6>
                    }
                  </div>
                }
                @if (modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse') {
                  <div class="mb-0 mt-3">
                    @if (feedbackResponse.submission?.acknowledgement?.acknowledged_at) {
                      <small class="text-muted">Acknowledged at</small>
                    }
                    <h5 class="acknowledged-at mt-2">
                      {{
                        feedbackResponse.submission?.acknowledgement?.acknowledged_at
                          | date
                            : 'dd MMM y,
                      hh:mm a'
                      }}
                    </h5>
                  </div>
                }
                @if (modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse') {
                  <div class="mb-0 mt-3">
                    @if (feedbackResponse?.submission?.submitted_at) {
                      <small class="text-muted">Submitted at</small>
                    }
                    <h5 class="submitted-at mt-2">
                      {{ feedbackResponse?.submission?.submitted_at | date: 'dd MMM y, hh:mm a' }}
                    </h5>
                  </div>
                }
                @if (modalType === 'nodeFeedbackResponse' || modalType === 'bundleFeedbackResponse' || modalType === 'notifyFeedbackResponse') {
                  <div class="mb-0 mt-3">
                    @if (feedbackResponse?.submission?.submitted_by) {
                      <small class="text-muted">Submitted by</small>
                    }
                    <h5 class="submitted-by mt-2">
                      @if (currentUser.user_id !== feedbackResponse?.submission?.submitted_by['user_id']) {
                        <div>{{ feedbackResponse?.submission?.submitted_by['first_name'] }} {{ feedbackResponse?.submission?.submitted_by['last_name'] }}</div>
                      } @else {
                        {{ adminConstant.YOU }}
                      }
                    </h5>
                  </div>
                }
                <div class="d-grid gap-2">
                  @if (this.modalType === 'nodeSubmitFeedback' || this.modalType === 'bundleSubmitFeedback') {
                    <button type="submit" class="btn btn-block mt-4 mb-0 btn-primary">
                      <span>SUBMIT FEEDBACK</span>
                    </button>
                  }
                </div>
              </div>
            </div>
            @if (feedbackResponse?.submission?.acknowledgement?.status === 'ACKNOWLEDGED' && feedbackResponse?.submission?.acknowledgement?.remarks) {
              <div class="card mt-4 mb-3">
                <div class="card-body">
                  <markdown class="markdown-viewer" [data]="feedbackResponse.submission.acknowledgement.remarks"></markdown>
                </div>
              </div>
            }
          </div>
        }
      </div>
    </div>
  </form>
</div>
<div class="modal-footer"></div>
