import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import axios from 'axios';
import { RemoteConfigService } from 'app/shared/service/remote-config.service';

@Directive({
    selector: '[appSecureImg]',
})
export class SecureImgDirective implements OnInit {
    @Input() url: any;
    constructor(
        public el: ElementRef,
        public remoteConfigService: RemoteConfigService,
    ) { }
    ngOnInit() {
        this.el.nativeElement.src = 'assets/img/user.svg';
        this.setImageSrc();
    }
    setImageSrc() {
        const token = JSON.parse(localStorage.getItem('auth_token'));
        const authToken = `${token.token_type} ${token.access_token}`;
        let axiosConfig: any;
        axiosConfig = {
            headers: {
                Authorization: authToken,
            },
            responseType: 'blob',
        };
        axios
            .get(this.url, axiosConfig)
            .then((response) => {
                const reader = new window.FileReader();
                reader.readAsDataURL(response.data);
                reader.onload = (obj: any) => {
                    this.el.nativeElement.src = obj.target.result;
                };
            })
            .catch((err) => {
                this.el.nativeElement.src = this.url;
            });
    }
}
