@if (show) {
  <div class="top-notification confirm-box fixed-top animate__animated animate__slideInDown">
    <div class="media">
      <div class="media-body">
        <h5 class="mt-0">Your privilege has been changed</h5>
        <p class="mb-1">You will be redirected to home page automatically in {{ reloadbox.countDown }} sec</p>
        <button type="button" class="btn btn-link btn-primary" (click)="confirmButton(true)">Redirect now</button>
      </div>
    </div>
  </div>
}
