import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, AuthGuard, Util } from 'app/shared';
import { MessagingService } from 'app/shared/service/messaging.service';
import { NotificationService } from 'app/shared/service/notification.service';
import { PreLoaderService } from 'app/shared/service/preloader.service';
import { RemoteConfigService } from 'app/shared/service/remote-config.service';
import { SessionTimeoutService } from 'app/shared/service/session-timeout.service';
import { SubDomainService } from 'app/shared/service/sub-domain.service';
import { TitleService } from 'app/shared/service/title.service';
import { TransService } from 'app/shared/translate/translate.service';

@Component({
    templateUrl: './login-url.component.html',
    selector: 'app-login-url',
    styleUrls: ['./login-url.component.scss'],
})
export class LoginUrlComponent implements OnInit {
    org: any;
    user: any;
    supportedLanguages: any;
    apiLoading: boolean = true;
    errMessage: any;
    constructor(
        public api: ApiService,
        public auth: AuthGuard,
        public notifService: NotificationService,
        public route: ActivatedRoute,
        public router: Router,
        public translate: TransService,
        public subDomain: SubDomainService,
        private readonly titleService: TitleService,
        private readonly sessionTimeoutService: SessionTimeoutService,
        private readonly messagingService: MessagingService,
        private preLoader: PreLoaderService,
        public remoteConfigService: RemoteConfigService,
        public subDomainService: SubDomainService,
    ) {}
    ngOnInit() {
        this.getOrgSetting();
    }
    getOrgSetting() {
        const domain = this.subDomain.getSubDomain();
        if (domain) {
            const body = { domain: domain };
            const options = { show_success: false, show_loader: false };
            this.api.post('login/lookup/org', body, options).subscribe({
                next: (data) => {
                    this.org = data;
                    this.api.setScheduleOrgRefreshAt();
                    localStorage.setItem('orgSetting', JSON.stringify(data));
                    const orgTheme = Util.filterArray(
                        this.org.settings,
                        'property',
                        'org_theme',
                    ).value;
                    if (orgTheme !== null) {
                        Util.applytheme(orgTheme);
                    }
                    this.titleService.setDocTitle(this.getOrg().name + ' | RapL');
                    this.supportedLanguages = Util.filterArray(
                        this.org.settings,
                        'property',
                        'supported_languages',
                    ).value;
                    const supportedAuthTypes = Util.filterArray(
                        this.org.settings,
                        'property',
                        'supported_auth_types',
                    ).value;
                    if (supportedAuthTypes.indexOf('TOKEN') !== -1) {
                        this.route.queryParamMap.subscribe((queryParams) => {
                            if (queryParams.get('token') !== null) {
                                this.challengeToken(queryParams.get('token'));
                            } else {
                                this.showErrorMessage('Token is either missing or invalid');
                            }
                        });
                    } else {
                        this.showErrorMessage('Authentication token is not enabled');
                    }
                },
                error: (err) => {
                    this.router.navigateByUrl('/404', { skipLocationChange: true });
                    console.log(err);
                },
            });
        } else {
            this.router.navigateByUrl('/404', { skipLocationChange: true });
        }
    }
    getOrg() {
        return JSON.parse(localStorage.getItem('orgSetting'));
    }
    challengeToken(token) {
        const body = {
            token: token,
        };
        this.api
            .post('login/challenge/token', body, {
                show_success: false,
                show_loader: false,
                show_toast: false,
            })
            .subscribe({
                next: (data: any) => {
                    this.apiLoading = false;
                    this.user = data;
                    this.user.type = 'EMAIL';
                    this.user.principal = this.user.email;
                    this.user.org = this.org;
                    // put in subscriptions
                    const subscription = this.notifService
                        .retrieveNotification(20)
                        .subscribe((innerdata) => {
                            this.notifService.notifWindow = innerdata;
                        });
                    // IMPORTANT: push new subscription in the array
                    this.notifService.subscriptions.push(subscription);
                    localStorage.setItem('auth_token', JSON.stringify(data.token));
                    localStorage.setItem('current_user', JSON.stringify(this.user));
                    this.translate.changeLang(this.user.language);
                    this.auth.isLogin = true;
                    const supportedLanguageArr = this.supportedLanguages.split(',');
                    const currentUser = JSON.parse(localStorage.getItem('current_user'));
                    this.preLoader.show();
                    const userWebSessionTimeout: any = Util.filterArray(
                        currentUser.org.settings,
                        'property',
                        'user_web_session_timeout',
                    );
                    if (userWebSessionTimeout) {
                        this.sessionTimeoutService.detectUserInactivity(
                            userWebSessionTimeout.value,
                        );
                    }
                    this.messagingService.requestPermission();
                    this.messagingService.receiveMessage();
                    this.getOrgEmojis();
                    if (this.user.force_set_language) {
                        if (supportedLanguageArr.length === 1) {
                            Util.privilegeBasedModule(this.router);
                        } else {
                            localStorage.setItem('user_selected_language_menu', 'true');
                            this.router.navigate(['/app/profile/language']);
                        }
                    } else {
                        Util.privilegeBasedModule(this.router);
                    }
                },
                error: (err: any) => {
                    this.showErrorMessage(err.error.message);
                    console.log('Challenge Token error message');
                    console.log(err);
                },
            });
    }
    backToLogin() {
        this.router.navigate(['/security/login']);
    }
    showErrorMessage(errMessage) {
        setTimeout(() => {
            this.apiLoading = false;
            this.errMessage = errMessage;
        }, 1000);
    }

    getOrgEmojis() {
        this.api.get('admin/orgs/emojis').subscribe({
            next: (data: any) => {
                localStorage.setItem('orgEmojis', JSON.stringify(data));
            },
            error: (err: any) => {
                console.log('Get Org Emojis Error', err);
            },
        });
    }
}
