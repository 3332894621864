import { Component, OnInit } from '@angular/core';
import { FirebaseSavePointService } from 'app/shared/service/firebase-save-point.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './four04.component.html',
    styleUrls: ['./four04.component.scss'],
})
export class Four04Component implements OnInit {
    private savePointSubscription: Subscription;
    constructor(public firebaseSavePointService: FirebaseSavePointService) {}
    ngOnInit() {
        localStorage.removeItem('adminHeadertab');
        this.savePointSubscription = this.firebaseSavePointService
            .getOrgSavePoint()
            .subscribe((data) => {
                if (!localStorage.getItem('orgSavePoint')) {
                    localStorage.setItem('orgSavePoint', JSON.stringify(data));
                }
            });
    }
}
