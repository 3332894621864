import { Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { TransService } from 'app/shared/translate/translate.service';

export enum Type {
    WARNING = 'assets/img/icon-warning.svg',
    NOTIFY = 'assets/img/icon-notify-action.svg',
}
@Injectable()
export class ConfirmBoxService {
    // display confim
    public status: Subject<boolean> = new Subject();
    private _active = false;
    // button Observable
    public clickStream = new Subject<any>();
    @Output() observable = this.clickStream.asObservable();
    public dialogImage: string;
    public dialogHeader: string;
    public dialogMessage: string;
    public dialogType: string;
    public dialogNote: string;
    public checkBoxLabel: string;
    public isConfirmBoxOpen = false;
    public showCheckBox = false;
    public checkBoxValue = false;
    constructor(public translate: TransService) {}

    public get active(): boolean {
        return this._active;
    }

    public set active(v: boolean) {
        this._active = v;
        this.status.next(v);
    }

    public show(
        image,
        titlebar,
        message,
        type?: any,
        note?: any,
        checkBoxLabel?: any,
        showCheckBox?: any,
    ): void {
        const translateLanguage = localStorage.getItem('translate_language');
        if (translateLanguage != null) {
            this.translate.changeLang(translateLanguage);
        } else if (this.translate.translateLanguage) {
            this.translate.changeLang(this.translate.translateLanguage);
        }
        this.dialogImage = Type[image];
        this.dialogHeader = titlebar;
        this.dialogMessage = message;
        this.dialogType = type;
        this.dialogNote = note;
        this.checkBoxLabel = checkBoxLabel;
        this.showCheckBox = showCheckBox;
        this.checkBoxValue = true;
        this.active = true;
    }

    public hide(): void {
        this.active = false;
    }

    confirmed(accept: boolean, checkboxValue?) {
        this.active = false;
        if (checkboxValue) {
            const body = {
                result: accept,
                checkboxValue: checkboxValue,
            };
            this.clickStream.next(body);
        } else {
            this.clickStream.next(accept);
        }
    }
}
