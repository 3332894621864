@if (getChunkStatus()) {
  <div class="initialize-layout">
    <div [ngClass]="{ 'initialize-layout-outer': active }">
      <img class="d-none d-lg-block m-auto" width="auto" height="350" alt="RapL" src="assets/img/chunk-default.svg" />
      <img class="d-block d-lg-none m-auto" width="auto" height="200" alt="RapL" src="assets/img/chunk-mobile.svg" />
      <h5 class="mb-3 mt-3 text-center">Please wait while we initialize resources</h5>
      <p>This will be finished in few moment</p>
      <img width="auto" height="50" alt="RapL" src="assets/img/loading.svg" />
    </div>
  </div>
}
