import { Component } from '@angular/core';
import { ReloadBoxService } from 'app/reloadbox/reloadbox.service';

@Component({
    selector: 'app-reload-box-component',
    templateUrl: './reloadbox.component.html',
})
export class ReloadBoxComponent {
    public show: boolean;
    public constructor(public reloadbox: ReloadBoxService) {
        reloadbox.status.subscribe((status: boolean) => {
            this.show = status;
        });
    }
    confirmButton(accept: boolean) {
        this.reloadbox.confirmed(accept);
    }
}
