@if (show) {
  <div class="preloader" [class]="getSplashBackdropClass()">
    <div class="container text-center">
      <div class="col">
        <div class="preloader-img animate__animated animate__fadeIn" [ngClass]="{ 'festive-img': isFestiveMode() }">
          @if (isFestiveMode()) {
            <img alt="FestiveModeSplash" src="assets/img/festive-mode-splash.png?v2" class="festive-splash" />
          }
          <img alt="logo" [src]="getSpalshURL()" [ngClass]="{ 'default-splash': isFestiveMode() }" style="width: 100%; height: 100%" />
        </div>
      </div>
    </div>
    @if (isFestiveMode()) {
      <div class="pyro">
        <div class="before"></div>
        <div class="after"></div>
      </div>
    }
  </div>
}
