// https://github.com/halt-hammerzeit/libphonenumber-js
import { Injectable } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';

@Injectable()
export class MobileNumberService {
    public phoneUtil = PhoneNumberUtil.getInstance();

    public validateMobileNumber(mobileNumber: any) {
        if (mobileNumber) {
            try {
                const number = this.phoneUtil.parseAndKeepRawInput(mobileNumber, null);
                if (this.phoneUtil.isValidNumber(number)) {
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        } else {
            return false;
        }
    }
}
