<div class="menu-close-outer d-flex justify-content-center" [ngClass]="{ 'remove-close-btn': removeCloseBtn }">
  <span class="align-items-center d-flex justify-content-center menu-close-layout" (click)="closeBottomSheet()">
    <span class="mdi mdi-close fs-1 text-white"></span>
  </span>
</div>
<div class="mobile-my-account-outer pt-4 px-4">
  <div class="other-item-list">
    @if (listType === 'normalList') {
      @for (list of activeMyAccountList; track list; let index = $index) {
        <div class="list-item">
          <div class="layout-body" [routerLink]="[list.routerLink]" (click)="logEvent(list)">
            <div class="container">
              <div class="align-items-center row">
                <div class="col-2 col-sm-1">
                  <div class="list-icons">
                    <img height="28" [src]="sanitizer.bypassSecurityTrustResourceUrl(list.imageSrc)" alt="list.label" draggable="false" />
                  </div>
                </div>
                <div class="col-9 action-btn pe-0 ps-0">
                  <a class="navigation-card">
                    {{ list.label }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    } @else if (listType === 'externalList') {
      @for (otherList of otherMyAccountList; track otherList) {
        <div class="list-item">
          <div class="layout-body" [routerLink]="[otherList.routerLink]" (click)="logEvent(otherList)">
            <div class="container">
              <div class="align-items-center row">
                <div class="col-2 col-sm-1">
                  <div class="list-icons">
                    <img height="28" [src]="sanitizer.bypassSecurityTrustResourceUrl(otherList.imageSrc)" alt="otherList.label" draggable="false" />
                  </div>
                </div>
                <div class="col-9 action-btn pe-0 ps-0">
                  <a class="navigation-card rapl-semi-bold">
                    {{ otherList.label }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      @if (currentUser.auth_type === 'DEFAULT' || currentUser.auth_type === 'OIDC' || currentUser.auth_type === 'OTP') {
        <div class="list-item">
          <div class="layout-body" (click)="mobileLogoutModal()">
            <div class="container">
              <div class="align-items-center row">
                <div class="col-2 col-sm-1">
                  <div class="list-icons">
                    <img height="24" src="assets/img/signoutIcon.png" alt="" draggable="false" />
                  </div>
                </div>
                <div class="col-9 action-btn pe-0 ps-0">
                  <a class="navigation-card rapl-semi-bold sentence-case d-inline-block" app-translate="LEARNER.LOGOUT" [translate]="'LEARNER.LOGOUT'"> Sign Out </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    }
  </div>
</div>
