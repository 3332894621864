import { Component } from '@angular/core';
import { Util } from 'app/shared/utils';
import { PreLoaderService } from '../../shared/service/preloader.service';
import { SubDomainService } from 'app/shared/service/sub-domain.service';

@Component({
    selector: 'app-pre-loader-component',
    templateUrl: './preloader.component.html',
    styleUrls: ['./preloader.component.scss'],
})
export class PreLoaderComponent {
    public show: boolean;
    public isOrginvertedLogo: boolean;
    public isNoOrgLogo: boolean;

    public constructor(
        public subDomainService: SubDomainService,
        public preloader: PreLoaderService,
    ) {
        preloader.status.subscribe((status: boolean) => {
            this.show = status;
        });
    }

    getOrg() {
        return JSON.parse(localStorage.getItem('orgSetting'));
    }

    isFestiveMode() {
        let enableFestiveMode = false;
        const festiveMode = Util.filterArray(
            this.getOrg().settings,
            'property',
            'enable_festive_mode',
        );
        if (festiveMode) {
            enableFestiveMode = festiveMode.value;
        }
        return enableFestiveMode;
    }

    getSplashBackdropClass() {
        const orgTheme = Util.filterArray(this.getOrg().settings, 'property', 'org_theme').value;
        if (orgTheme !== null && !this.isFestiveMode()) {
            Util.applytheme(orgTheme);
            return 'cobrand-splash';
        } else if (this.isFestiveMode()) {
            return 'festive-mode-bg';
        } else {
            return '';
        }
    }

    getSpalshURL() {
        let orgTheme = Util.filterArray(this.getOrg().settings, 'property', 'org_theme').value;
        if (orgTheme !== null) {
            orgTheme = JSON.parse(orgTheme);
            if (orgTheme?.logo?.inverted && !this.isFestiveMode()) {
                this.isOrginvertedLogo = true;
                return orgTheme?.logo?.inverted;
            } else if (this.getOrg()?.logo) {
                return this.getOrg()?.logo;
            } else {
                return this.defaultSpalshURL();
            }
        } else if (this.getOrg()?.logo) {
            return this.getOrg()?.logo;
        } else {
            this.isNoOrgLogo = true;
            return this.defaultSpalshURL();
        }
    }

    defaultSpalshURL() {
        const width = window.innerWidth;
        if (this.isFestiveMode()) {
            return 'assets/img/rapl-brand-logo.svg?v2';
        } else {
            return width <= 768
                ? 'assets/img/rapl-preloader-white.png'
                : 'assets/img/rapl-preloader-blue.png';
        }
    }

    checkCoBrand() {
        if (this.getOrg()) {
            const orgTheme = Util.filterArray(
                this.getOrg().settings,
                'property',
                'org_theme',
            ).value;
            if (orgTheme !== null) {
                Util.applytheme(orgTheme);
                return true;
            } else {
                return false;
            }
        }
    }

    snowCount(i: number) {
        return new Array(i);
    }
}
