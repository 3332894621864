import { EventEmitter, Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Router } from '@angular/router';
import { Favicons } from 'app/favicons';
import { SpinnerService } from 'app/spinner/spinner.service';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class NotificationService {
    private _notifications: any = [];
    public notifWindow: any = [];
    user: any;
    public lastKey: string;
    public queryable = true;
    public newNotif = false;
    reloadHomeContent: EventEmitter<string> = new EventEmitter();
    unlockedTest: EventEmitter<object> = new EventEmitter();
    public updateNotify = false;
    subscriptions: Subscription[] = [];

    constructor(
        public spinner: SpinnerService,
        public notif: AngularFireDatabase,
        public favicons: Favicons,
        public router: Router,
    ) {}

    public retrieveNotification(limit: any): Observable<any> {
        this.user = JSON.parse(localStorage.getItem('current_user'));
        if (this.user?.user_id) {
            return this.notif
                .list(`/notifications/${this.user.user_id}`, (ref) =>
                    ref.limitToFirst(limit).orderByChild('sort_id'),
                )
                .snapshotChanges()
                .pipe(
                    map(
                        (actions) => {
                            const data = [];
                            actions.forEach((action) => {
                                const obj = action.payload.val();
                                obj['$key'] = action.key;
                                data.push(obj);
                            });
                            this._notifications = [];
                            if (data.length > 0) {
                                // If the last key in the list equals the last key in the database
                                this.lastKeyInList(data);
                            }
                            // if new notifs are viewed
                            this.notificationViewed(limit, data);
                            this._notifications = data;
                            this._notifications.forEach((notif) => {
                                this.notificationLanguage(notif);
                            });
                            return this._notifications;
                        },
                        (error) => {
                            console.log(error);
                        },
                    ),
                );
        } else {
            return observableOf();
        }
    }
    lastKeyInList(data) {
        if (data[data.length - 1].$key === this.lastKey) {
            this.queryable = false;
        } else {
            this.queryable = true;
        }
    }
    actionOnLastNotify(data) {
        const newNotfiyDiffSec =
            (new Date().getTime() - new Date(data.created_at).getTime()) / 1000;
        if (this.isHomeActive(true) && this.updateNotify !== true && newNotfiyDiffSec <= 25) {
            if (data.actionable) {
                this.reloadHomeContent.emit('true');
            }
        }
    }
    notificationViewed(limit, data) {
        if (limit === 20) {
            const obj = data.filter((notif: any) => notif.status.is_viewed === false);
            if (obj && obj.length > 0) {
                if (obj[0].scenario === 'TEST_UNLOCKED') {
                    this.unlockedTest.emit(obj[0].payload);
                }
                if (!this.updateNotify) {
                    this.newNotif = true;
                    this.favicons.activate('notify');
                    this.actionOnLastNotify(obj[0]);
                }
            } else {
                this.newNotif = false;
                this.favicons.activate('default');
                this.updateNotify = false;
            }
        }
    }
    isHomeActive(exact) {
        return this.router.isActive('/app/home', exact);
    }
    notificationLanguage(notif) {
        const userLang = localStorage.getItem('translate_language');
        for (const lang in notif.translations) {
            if (lang === userLang) {
                notif.metaData = notif.translations[lang];
                break;
            } else if (notif.translations[lang].default) {
                notif.metaData = notif.translations[lang];
            }
        }
    }

    public getLastKey(): Observable<string> {
        this.user = JSON.parse(localStorage.getItem('current_user'));
        if (this.user?.user_id) {
            const noti = this.notif.list(`/notifications/${this.user.user_id}`, (ref) =>
                ref.limitToLast(1).orderByChild('sort_id'),
            );
            return noti.snapshotChanges().pipe(
                map((data: any) => {
                    if (data.length > 0) {
                        return data[0].key;
                    } else {
                        return '';
                    }
                }),
            );
        } else {
            return observableOf();
        }
    }

    public updateNotification(key: string): boolean {
        this.user = JSON.parse(localStorage.getItem('current_user'));
        if (this.user?.user_id && key) {
            this.notif
                .object(`/notifications/${this.user.user_id}/${key}`)
                .update({ status: { is_read: true, is_viewed: true } });
        }
        return true;
    }
    public viewNotification(key: string, isRead: boolean): boolean {
        this.updateNotify = true;
        this.user = JSON.parse(localStorage.getItem('current_user'));
        if (this.user?.user_id && key) {
            this.notif
                .object(`/notifications/${this.user.user_id}/${key}`)
                .update({ status: { is_read: isRead, is_viewed: true } });
        }
        return true;
    }
}
