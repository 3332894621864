import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { environment } from '../../../environments/environment';

@Injectable()
export class LogEventService {
    public constructor(private analytics: AngularFireAnalytics) {}
    public logEvent(eventName: string, eventParams: any = {}): void {
        const currentUser = JSON.parse(localStorage.getItem('current_user'));
        try {
            if (currentUser != null) {
                const userProperties = {
                    domain: currentUser.org.domain,
                    org_id: currentUser.org.org_id,
                };
                this.analytics
                    .setUserProperties(userProperties, currentUser.user_id)
                    .then(() => console.log('User properties set successfully'))
                    .catch((error) => console.error('Error setting user properties:', error));
                this.analytics.setUserId(currentUser.user_id);
            }
            this.analytics.setAnalyticsCollectionEnabled(environment.firebaseAnalytics);
            this.analytics.logEvent(eventName, eventParams);
        } catch (error) {
            console.error(error, 'error');
        }
    }
}
