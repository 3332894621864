import { Injectable } from '@angular/core';
import { Util } from '../utils';

@Injectable()
export class AuthGuardService {
    public isGenieRestricted(value: any, role: string) {
        const orgSetting = JSON.parse(localStorage.getItem('orgSetting'));
        const setting = Util.filterArray(orgSetting.settings, 'property', value);
        const excludedRolesString = setting?.value;
        const currentUserRole = role;
        const excludedRolesArray = excludedRolesString.split(',');
        if (currentUserRole === 'RAPL_ADMIN') {
            return false;
        } else {
            return excludedRolesArray.includes(currentUserRole);
        }
    }

    isSettingEnabled(value: any) {
        const orgSetting = JSON.parse(localStorage.getItem('orgSetting'));
        const setting = Util.filterArray(orgSetting.settings, 'property', value);
        return setting?.value;
    }
}
