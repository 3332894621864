export const adminConstants: any = {
    ACTIVATED_AT: 'Activated at',
    ACCESS_TYPE: 'Access type',
    ACCESS_DENIED: 'Access denied',
    ACKNOWLEDGE_FEEDBACK:
        'Setting this make sure that learners will have to acknowledge the feedback',
    ADD: 'Add',
    ADD_ASSIGNM: 'Add Assignment',
    ADD_ATTACHMENT: 'Add Attachment',
    ADD_CHIP_SHORTCUT: 'Use comma (,) or press enter to add new options.',
    ADD_FILE: 'Add file',
    ADD_GRP: 'Add Group',
    ADD_SUB_GRP: 'Add sub group',
    ADD_GRP_TO_FEEDBACK_ERR: 'Error add groups to feedback',
    ADD_GRP_TO_LEADERBOARD_ERR: 'Error add groups to Leaderboard',
    ADD_QUESTION: 'Add question',
    ADD_QUESTION_IMG: 'Add question image',
    ADD_CHOICE_IMG: 'Add choice image',
    ADD_RAT_IMG: 'Add rational image',
    ADD_NW_QUES: 'Add New Question',
    ADD_OPT: 'Add Options',
    ADD_OTHERS_OPT: 'Add "others" option',
    ADD_POLL: 'Add Poll',
    ADD_QUES: 'Add Question',
    ADD_QUES_QUIZ: 'Add Question From Quiz',
    ADD_ROLE: 'Add Role',
    ADD_ROLE_TO_FEEDBACK_ERR: 'Error add role to feedback',
    ADD_ROLE_TO_LEADERBOARD_ERR: 'Error add role to Leaderboard',
    ADD_SURVEY: 'Add Survey',
    ADD_QUIZ: 'Add Quiz',
    ADD_TEST: 'Add Test',
    ADD_VIDEO_CONF: 'Choose the platform for hosting the event',
    ADD_USR: 'Add User',
    ADDED_AT: 'Added At',
    ADDED_BY: 'Added By',
    ADDED_ON: 'Added On',
    AUDIT_LOG: 'Audit Log',
    APPLY: 'Apply',
    APPLY_FORM_THEME_STYLE: 'Apply Theme',
    APPLY_FORM_THEME_STYLE_MSG: 'Are you sure to apply theme?',
    APPLY_FORM_CUSTOM_THEME_STYLE: 'Apply Custom Style',
    APPLY_FORM_CUSTOM_THEME_STYLE_MSG: 'Are you sure to apply custom style?',
    APPLY_CHNG_TO_LB: 'Apply this changes to live leaderboard',
    ALL: 'All',
    ALL_DAYS: 'All Days',
    ALL_GRP: 'All Groups',
    ALLOWED_CALLING_CODES: 'Allowed Calling Codes',
    ALLOWED_EMAIL_DOMAINS: 'Allowed Email Domains',
    ALLOW_SEEKING: 'Allow Seeking',
    ALLOW_SEEK_DESC: 'Allow Seek for this file.',
    ALPHABETIC_ORDER: 'Alphabetical order',
    ALPHABETIC_ORDER_INFO: 'Enable this option to sort dropdown options in alphabetical order.',
    ALLOW_USER_RE_REGISTER: 'Allow previously unsubscribed users to re-subscribe',
    ATTEMPTED: 'Attempted',
    ATTEMPTED_ON: 'Attempted on',
    ASGMENTS: 'Assignments',
    ASSIGNMENT_DUE_DATE: 'Assignment Due Date',
    ASG: 'Assign',
    ASG_ASGMENT: 'Assign Assignment',
    ASG_AT: 'Assigned at',
    ASSIGN_CERTIFICATE: 'Assign Certificate',
    ASSIGN_FILE: 'Assign File',
    ASSIGN_LPATH: 'Assign Learning Path',
    ASSIGN_PATH: 'Assign Path',
    ASSIGN_TO_HIERARCHY: 'Assign to hierarchy',
    ASG_INVI: 'Assign & Invite',
    ASG_INVI_SEL_USRS: 'Assign & Invite Selected Users',
    ASG_LP_SCC: 'Learning path assigned successfully',
    ASG_PATH_SUCC: 'Path assigned successfully',
    ASG_SEL_USRS: 'Assign Selected Users',
    ASG_TO_GRP: 'Assign to Group',
    ASG_TO_ROLE: 'Assign to Role',
    ASG_QUIZ: 'Assign Quiz',
    ASG_QUIZS: 'Assign Quizzes',
    ASG_TEST: 'Assign Test',
    ASG_USRS: 'Assign Users',
    ASG_USR_GRP: 'Assign the user to a group',
    ASSIGNED_CONTENT: 'Assigned contents',
    ASSIGNED_ROL: ' Assigned Roles',
    ASSIGNED_FILES: 'Assigned Files',
    ASSIGNED_FILES_LIST: 'Assigned Files List',
    ASSIGNED_GRP: 'Assigned Groups',
    ASSIGNED_ROLES: 'Assigned Roles',
    ASSIGNED_GROUP_FILE: 'View details of the Group to which the File is assigned.',
    ASSIGNED_ROLE_FILE: 'View details of the Role to which the File is assigned.',
    ASSIGNED_GROUP_EVENT: 'View details of the Group to which the Event is assigned.',
    ASSIGNED_USER_EVENT: 'View details of the User to which the Event is assigned.',
    ASSIGNED_GROUP_POLL: 'View details of the Group to which the Poll is assigned.',
    ASSIGNED_ROLE_POLL: 'View details of the Role to which the Poll is assigned.',
    ASSIGNED_GROUP_QUIZ: 'View details of the Group to which the Quiz is assigned.',
    ASSIGNED_GROUP_PATH: 'View details of the Group to which the Path is assigned.',
    ASSIGNED_ROLE_EVENT: 'View details of the Role to which the Event is assigned.',
    ASSIGNED_ROLE_QUIZ: 'View details of the Role to which the Quiz is assigned.',
    ASSIGNED_ROLE_PATH: 'View details of the Role to which the Path is assigned.',
    ASSIGNED_PATH_FILE: 'View details of the Path to which the File is assigned.',
    ASSIGN_SEL_USR: 'Assign Selected Users',
    ASSIGNED_LB: 'Assigned Leaderboards',
    ASSIGNED_LB_LIST: 'Assigned Leaderboards List',
    ASSIGNED_QUIZS: 'Assigned Quizzes',
    ASSIGNED_QUIZ_LIST: 'Assigned Quizzes List',
    ASSIGNED_TESTS: 'Assigned Tests',
    ASSIGNED_TESTS_LIST: 'Assigned Tests List',
    ASSIGNED_POLLS: 'Assigned Polls',
    ASSIGNED_POLLS_LIST: 'Assigned Polls List',
    ASSIGNED_SEQ: 'Assigned Learning Path',
    ASSIGNED_PATH: 'Assigned Path',
    ASSIGNED_SEQ_LIST: 'Assigned Learning Path List',
    ASSIGNED_PATH_LIST: 'Assigned Path List',
    ASSIGNED_SUR: 'Assigned Surveys',
    ASSIGNED_SUR_LIST: 'Assigned Survey Lists',
    ASSIGNMENT_GRADED: 'Assignment Graded Successfully',
    ASSETS: 'Assets',
    AVG_POINTS: 'Average Points',
    AWARD_PONTS: 'Award points',
    AWARD_POINTS_TEST: 'Award points for this test',
    AWARD_POINTS_POLL: 'Award points for this poll',
    BCK: 'Back',
    BCKLOG: 'Backlog',
    BCKLOG_QUES: 'Backlog Questions',
    BLOCK: 'Block',
    BOOKMARKED_COUNT: 'Bookmarked Count',
    CANCEL: 'Cancel',
    CANCELED_AT: 'Canceled at',
    CHO_HINT_SPECIAL_CHAR_ERR: 'Choice hint cannot start with special characters like @, + or =',
    CHOICE_SPECIAL_CHAR_ERR: 'Choice cannot start with special characters like @, + or =',
    CHOOSE_CORRECT_ANSWER: 'Choose the correct answer',
    COPY_LINK: 'Copy Link',
    COPY_TO_CLIPBOARD: 'Copy to clipboard',
    COPIED_TO_CLIPBOARD: 'Copied to clipboard',
    CLICK_ASG_GRP_COUNT:
        'Click on the assigned groups count to view list of groups assigned to this test',
    CLICK_ASG_ROLE_COUNT:
        'Click on the assigned roles count to view list of roles assigned to this test',
    CLICK_ASG_GRP_COUNT_ASSGN:
        'Click on the assigned groups count to view list of groups assigned to this assignment',
    CLICK_ASG_ROLES_COUNT_ASSGN:
        'Click on the assigned roles count to view list of roles assigned to this assignment',
    CLICK_ASG_GRP_COUNT_LP:
        'Click on the assigned groups count to view list of groups assigned to this learning path',
    CLICK_ASG_ROLES_COUNT_LP:
        'Click on the assigned roles count to view list of roles assigned to this learning path',
    CLICK_ASG_GRP_COUNT_PATH:
        'Click on the assigned groups count to view list of groups assigned to this path',
    CLICK_ASG_ROLES_COUNT_PATH:
        'Click on the assigned roles count to view list of roles assigned to this path',
    CLICK_ASG_GRP_COUNT_POLL:
        'Click on the assigned groups count to view list of groups assigned to this poll',
    CLICK_ASG_ROLES_COUNT_POLL:
        'Click on the assigned roles count to view list of roles assigned to this poll',
    CLICK_ASG_GRP_COUNT_QUIZ:
        'Click on the assigned groups count to view list of groups assigned to this quiz',
    CLICK_ASG_ROLES_COUNT_QUIZ:
        'Click on the assigned roles count to view list of roles assigned to this quiz',
    CLICK_ASG_GRP_COUNT_SURVEY:
        'Click on the assigned groups count to view list of groups assigned to this survey',
    CLICK_ASG_ROLES_COUNT_SURVEY:
        'Click on the assigned roles count to view list of roles assigned to this survey',
    CLICK_ASG_GRP_COUNT_USER:
        'Click on the assigned groups count to view list of groups assigned to this user',
    CLICK_ASG_ROLES_COUNT_USER:
        'Click on the assigned roles count to view list of roles assigned to this user',
    CLONE: 'Clone',
    CLONE_ALL: 'Clone All',
    CLONE_ASSIGNMENT: 'Clone Assignment',
    CLONE_AS_QUIZ: 'Clone As Quiz',
    CLONE_AS_TEST: 'Clone As Test',
    CLONE_FEEDBACK_TEMPLATE: 'Duplicate Feedback Template',
    CLONE_FORM: 'Clone Form',
    CLONE_OPT: 'Clone Option',
    CLONE_QUESTIONS: 'Clone Questions',
    CLONE_QUIZ: 'Clone Quiz',
    CLEAR_CATEGORY: 'Clear Category',
    CLONE_NOTE:
        'Note : Clone request submitted will be processed in background. Please check 5 minutes after submitting the request.',
    CLONE_SELECTED: 'Clone Selected',
    CLONE_SURVEY: 'Clone Survey',
    CLONE_PATH: 'Clone Path',
    CLONE_PATH_INFO: 'Do you want to clone the entire path with all items?',
    CLONE_TEST: 'Clone Test',
    CLOSE: 'Close',
    COLLAPSE: 'Collapse',
    CLOSE_NEW_USER_REGIST: 'Close registration for new users',
    CLONE_REQUEST_SUCC: 'Clone request submitted successfully',
    CATEGORY: 'Category',
    CERTIFICATE_TEMP: 'Certificate Template',
    CERTIFICATE_ASG_SUCS: 'Certificate template assigned successfully',
    CERTIFICATE_UNASG_SUCS: 'Certificate template un-assigned successfully',
    COMPLETED_AT: 'Completed at',
    COMPUTE_LB_GRP: 'Compute ranks for users in selected groups',
    COMPUTE_LB_ROL: 'Compute ranks for users in selected roles',
    COMPUTE_LB_RANK_BY_GRP:
        'Compute ranks for users in above selected roles in following groups only',
    COMPUTE_LB_RANK_BY_ROL:
        'Compute ranks for users in above selected groups with following roles only',
    CNF_DISABLE_LB:
        'NOTE: Once the leaderboard is disabled, learners will not be able to view the leaderboard, and admin computing will be stopped until it is re-enabled.',
    CNF_DEL_GRP: 'Are you sure to delete group ?',
    CNF_DEL_LB: 'Are you sure to delete leaderboard ?',
    CNF_DEL_EVENT: 'Are you sure to delete Event ?',
    CNF_DEL_MAPPING: 'Are you sure to delete Mapping ?',
    CNF_DEL_DEPENDENT_OPT: 'Are you sure to delete dependent option ?',
    CNF_DEL_HOST: 'Are you sure to delete Host ?',
    CNF_DEL_POLL: 'Are you sure to delete Poll ?',
    CNF_DEL_QUES: 'Are you sure to delete question ?',
    CNF_DEL_SEL_GRP: 'Are you sure to delete selected groups ?',
    CNF_DEL_SEL_QUES: 'Are you sure to delete selected questions ?',
    CNF_DEL_SEL_USR:
        'Are you sure to delete selected users, you will have the option to recover it within 30 days',
    CNF_DEL_SURVEY: 'Are you sure to delete Survey ?',
    CNF_DEL_QUIZ: 'Are you sure to delete Quiz ?',
    CNF_DEL_SUBSCRI: 'Are you sure to delete subscription ?',
    CNF_DEL_SCHEDULE: 'Are you sure to delete schedule ?',
    CNF_DEL_TEST: 'Are you sure to delete test ?',
    CNF_DEL_USR:
        'If you delete this account now, you will have the option to recover it within 30 days',
    CNF_DIS_CHOIC_IMG: 'Are you sure you want to discard choice image ?',
    CNF_DIS_CHOIC_TXT: 'Are you sure you want to discard choice text ?',
    CNF_DIS_CHG_TXT:
        "Your changes haven't been saved yet. Are you sure you want to discard your changes and proceed?",
    CNF_DISC_CHG: 'Are you sure you want to discard it ?',
    CNF_FORM_CLONE: 'Do you want to clone this form ?',
    CNF_INACT_LP: 'Are you sure to inactivate learning path ?',
    CNF_INACT_HOST: 'Are you sure to inactivate host ?',
    CNF_INACT_PATH: 'Are you sure to inactivate path ?',
    CNF_INACT_POLL: 'Are you sure to inactivate poll ?',
    CNF_INACT_SURVEY: 'Are you sure to inactivate survey ?',
    CNF_INACT_TEST: 'Are you sure to inactivate test ?',
    CNF_INACT_TOPC: 'Are you sure to inactivate Quiz ?',
    CNF_LEAVE_MSG: "Your edits won't be saved",
    CNF_NEW_LEAVE_MSG: "Your inputs won't be saved",
    CNF_LEAVE_COMMENT_MSG: "Your comment won't be saved",
    CNF_MOV_SEL_GRP: 'Are you sure, you want to move the selected group ?',
    CNF_MOV_SEL_FILE: 'Are you sure, you want to move the selected file ?',
    CNF_MOV_SEL_FOLDER: 'Are you sure, you want to move the selected folder ?',
    CNF_REM_ASSIGNM: 'Are you sure to remove assignment ?',
    CNF_REM_FORM: 'Are you sure to remove form ?',
    CNF_REM_FILE: 'Are you sure to remove file ?',
    CNF_REM_GROUP: 'Are you sure to remove group ?',
    CNF_REM_ROLE: 'Are you sure to remove role ?',
    CNF_REM_USER: 'Are you sure to remove user ?',
    CNF_REM_LANG: 'Are you sure to remove language ?',
    CNF_REM_SEL_ASSIGNM: 'Are you sure to remove selected assignment?',
    CNF_REM_SEL_FORM: 'Are you sure to remove selected form?',
    CNF_REM_SEL_QUIZ: 'Are you sure to remove selected quiz?',
    CNF_REM_SEL_USR: 'Are you sure to remove selected user ?',
    CNF_REM_SEL_TEST: 'Are you sure to remove selected test?',
    CNF_REM_TEST: 'Are you sure to remove test ?',
    CNF_REM_TOPC: 'Are you sure to remove quiz ?',
    CNF_REM_USR: 'Are you sure to remove user ?',
    CNF_USR_RESTORE: 'Are you sure to restore user ?',
    CNF_RESTORE_SEL_USR: 'Are you sure, you want to restore selected users ?',
    CNF_UNASG_CERTIFICATE: 'Are you sure, you want to unassign certificate template?',
    COMMENT: 'Comment',
    COMMENTS: 'Comments',
    CONTENT_TYPES: 'Content Types',
    CONFIRM: 'Confirm',
    CONTENT: 'Content',
    CONFIRM_DELETE_ROLE: 'Are you sure to delete role ?',
    CONFIRM_SUSPEND_REPORT: 'Are you sure you want to Suspend report?',
    CONFIRM_UNASSIGN_GROUP: 'Are you sure to remove selected group ?',
    CONFIRM_UNASSIGN_ROLE: 'Are you sure to remove selected role ?',
    CRE_AT: 'Created At',
    CRE_BY: 'Created By',
    CRE: 'CREATE',
    CRE_ASSIGNM: 'Create Assignment',
    CREATE_DRAFT: 'Create as Draft',
    CREATE_LIVE: 'Create & Live',
    CREATE_LB: 'Create Leaderboard',
    CREATE_INVITE: 'Create & INVITE',
    CREATE_NEW_ASSIGNM: 'Create New Assignment',
    CREATE_NEW_EVENT: 'Create New Event',
    CREATE_NEW_FILE: 'Create New File',
    CREATE_NEW_POLL: 'Create New Poll',
    CREATE_MAPPING: 'Create Mapping',
    CREATE_FIELD: 'Create Field',
    CREATE_CUSTOM_FIELD: 'Create custom field',
    CREATE_NEW_PATH: 'Create New path',
    CREATE_NEW_SURVEY: 'Create New Survey',
    CREATE_NEW_TEST: 'Create New Test',
    CRE_NW_QUIZ: 'Create New Quiz',
    CRE_NW_USR: 'Create New User',
    CREATE_REPORT: 'Create Report',
    CRE_TEMP: 'Create Template',
    CRE_SUBSCR: 'Create Subscription',
    CSV_FILE_ERR: 'Please select a csv filetype to upload.',
    CSV_FILE_UPLOAD_ERR: 'File uploaded. You will be notified when data is processed successfully.',
    CUSTOM_FIELD: 'Custom fields',
    CUSTON_FIELD_TEXT_VALIDATION_ERR:
        'The field label may only contain letters, numbers, dashes and underscores.',
    CUSTOM_FIELDS_MAPPING: 'Custom fields mapping',
    CUSTOM_FIELD_CREATED_SUCC: 'Custom field created successfully.',
    CUSTOM_FIELD_DEL_SUCC: 'Custom field deleted successfully.',
    CUSTOM_FIELD_UPDATED_SUCC: 'Custom field updated successfully.',
    CUSTOM_FIELD_DROPDOWN_HINT: 'Can add up to 250 options, each with maximum of 100 characters.',
    CUSTOM_THEME_STYLE_DIS_CHG_TXT:
        "Your changes haven't been saved yet. Are you sure you want to discard your changes and proceed?",
    CUTOFF_DATE: 'Due At',
    CUTOFF_DATE_LABEL: 'Due Date Time',
    CUTOFF_DATE_INFO: 'Due date to the assignment to attain maximum marks',
    CUTOFF_DATE_ERR: 'The due date field is mandatory',
    DAYS_QUES_REL: 'Days when questions are to be released *',
    DEC_VAL_ERR: 'Decimal values are not allowed for max upload size',
    DEF_LANG: 'Default Lang',
    DEL: 'Delete',
    DEL_AT: 'Deleted At',
    DEL_ASSIGNM: 'Delete Assignment',
    DEL_PERMANENTLY: 'Delete Permanently',
    DEL_PERMANENTLY_INFO:
        'Performing this action will permanently delete the user account and cannot be restored.',
    DEL_SUB: 'Delete Submission',
    DEL_ASSIGNM_CONFIRM: 'Are you sure you want to delete assignment ?',
    DEL_EVENT: 'Delete Event',
    DEL_DEPENDENT_OPT: 'Delete Dependent',
    DEL_MAPPING: 'Delete Mapping',
    DEL_HOST: 'Delete Host',
    DEL_GRP: 'Delete group',
    DEL_GRPS: 'Delete Groups',
    DEL_LB: 'Delete Leaderboard',
    DEL_POLL: 'Delete Poll',
    DEL_QUES: 'Delete Question',
    DELETE_ROLE: 'Delete role',
    DELETE_ROLES: 'Delete Roles',
    DEL_SEL_GRP: 'Delete selected groups',
    DEL_SEL_QUE: 'Delete selected question',
    DEL_SEL_QUES: 'Delete selected questions',
    DEL_SEL_QUIZ: 'Delete selected Quizzes',
    DEL_SEL_USR: 'Delete selected users',
    DEL_SEL_TEST: 'Delete selected test',
    DEL_SURVEY: 'Delete Survey',
    DEL_QUIZ: 'Delete Quiz',
    DEL_SUBSCRI: 'Delete subscription',
    DEL_SCHEDULE: 'Delete schedule',
    DEL_TEST: 'Delete Test',
    DEL_TRANS: 'Delete Translation',
    DEL_USR: 'Delete User',
    DESC: 'Description',
    DEPENDENT_OPTIONS: 'Dependent Options',
    DEPENDENT_DEL_HINT: 'Click on delete button to apply deletion.',
    DOWNLOAD_OTP: 'Download OTP',
    DOWNLOAD_PENDING_USERS_OTP: 'Download pending users OTP',
    DOWNLOAD_RESPONSE_FILE: 'Download response file',
    DOWNLOAD_UPLOAD_FILE: 'Download upload file',
    DOWNLOAD_QR_CODE: 'Download QR Code',
    DISABLE: 'Disable',
    DISABLE_LB: 'Disable Leaderboard',
    CHOICE_HINT: 'Choice Hint',
    CONSUMING: 'Consuming',
    DILY_REL_CNT: 'Daily release Count',
    DIS_CHOIC_IMG: 'Discard Choice Image',
    DIS_CHOIC_TXT: 'Discard Choice Text',
    DISC_CHGES: 'Discard Changes',
    DELETE: 'Delete',
    DELETE_FIELD: 'Delete field',
    DELETE_FORM: 'Delete Form',
    DOWNLOAD_REPORT: 'Download Report',
    DOWNLOAD: 'Download',
    DELETE_SCHEDULE: 'Delete Schedule',
    DOWNLOAD_REPORT_TRIGG_SUCC: 'Download report triggered successfully',
    DOWNLOAD_TEMPLATE_CREATED_SUCCESSFULLY: 'Download template created successfully',
    DRAFT: 'Draft',
    DUE_DATE_INFO:
        '% of grade that needs to be reduced from max grade for late submissions (ie submission after the due date)',
    DUE_BY_DAYS:
        'Number of Days within which learner to review and acknowledge from the time of receiving the feedback ',
    DUP_QUES: 'Duplicate Question',
    DUPLICATE_ASSIGNMNET: 'Duplicate Assignment',
    DUPLICATE_AS_PATH: 'Duplicate As Path',
    DUPLICATE_AS_PATH_INFO:
        'Note : Duplicate request submitted will be processed in background. Please check 5 minutes after submitting the request.',
    DUPLICATE_AS_QUIZ: 'Duplicate As Quiz',
    DUPLICATE_LEARNING_PATH: 'Duplicate Learning path',
    DUPLICATE_LEARNING_PATH_INFO: 'Do you want to duplicate entire learning path with all quizzes?',
    DUPLICATE_PATH: 'Duplicate Path',
    DUPLICATE_PATH_INFO: 'Do you want to duplicate the entire path with all items?',
    DUPLICATE_QUIZ: 'Duplicate Quiz',
    DUPLICATE_SURVEY: 'Duplicate Survey',
    DUPLICATE_AS_TEST: 'Duplicate As Test',
    DUPLICATE_TEST: 'Duplicate Test',
    DURATION: 'Duration',
    EDIT: 'Edit',
    EDIT_ASSIGNM: 'Edit Assignment',
    EDIT_CUSTOM_FIELD: 'Edit custom field',
    EDIT_FIELD: 'Edit field',
    EDIT_EVENT: 'Edit Event',
    EDIT_FORM: 'Edit Form',
    EDIT_LB: 'Edit Leaderboard',
    EDIT_POLL: 'Edit Poll',
    EDT_QUES: 'Edit Question',
    EDT_QUIZ: 'Edit Quiz',
    EDIT_SCHEDULE: 'Edit Schedule',
    EDIT_SUBSCR: 'Edit Subscription',
    EDIT_SURVEY: 'Edit Survey',
    EDIT_TEST: 'Edit Test',
    EDIT_TEMPLATE: 'Edit Template',
    EDT_USR: 'Edit User',
    EDT_REPORT: 'Edit Report',
    EML: 'Email',
    EML_ID: 'Email ID',
    EML_INV: 'Email is invalid',
    EML_VERI: 'Email verified',
    EMPTY_BMK_TEMPLATE: '"Bookmark frequently used Templates to appear here for quick access."',
    END_TIME: 'End time',
    ENABLE: 'Enable',
    ENABLE_COMMENTS: 'Enable comments',
    ENABLE_COMMENTS_DESC:
        'Enable this flag to allow learners to add comments in library files assigned to them.',
    ENABLE_DOWNLOAD: 'Enable Download',
    ENABLE_DOWNLOAD_DESC: 'Allow the learner to download the file.',
    ENABLE_FORMS: 'Enable forms',
    CRAFT: 'Craft',
    CRAFT_AI: 'Craft AI',
    CRAFT_FILE_CREATE: 'Create craft file',
    CRAFT_FILE_TITLE_HINT: 'Enter a title to generate a file.',
    CRAFT_REFERENCE_FILE_TITLE: 'Reference file for creating file.',
    ENABLE_CRAFT: 'Enable craft',
    ENABLE_EVENT: 'Enable event',
    ENABLE_CRAFT_DESC:
        'Enable this flag to allow admin/authors to create new scenarios using craft.',
    ENABLE_EVENT_DESC:
        'Toggle to enable or disable events. When enabled, you can schedule and manage events like video conferences.',
    ENABLE_FESTIVE_MODE: 'Enable festival mode',
    ENABLE_FESTIVE_MODE_DESC:
        'Enable this flag to allow festival mode only in the web application(Splash screen and header logo).',
    ENABLE_FORMS_DESC:
        'Turn on this flag to activate the forms module, allowing administrators to create new forms and enabling learners to access them.',
    ENABLE_EMAIL_TEMPLATE: 'Allow customization',
    ENABLE_EMAIL_DESC: 'Enable this flag to allow admin/ authors to customize email templates.',
    ENABLE_MODERATION: 'Enable Moderation',
    ENABLE_USAGE_ALERT_EMAIL: 'Enable usage alert by email',
    ENABLE_USAGE_ALERT_DESC:
        'Enable this flag to allow privileged users to receive daily email alerts when weekly usage is below the expected threshold.',
    ENT_VAL_EMAIL_PHONE: 'Enter valid email or mobile number',
    ERR: 'error',
    ERR_DEL_GRP: 'Error deleting groups',
    EVENT_START_DATE: 'Select the date and time when the event will begin',
    EXACT_SCHEDULE_INFO: 'Follow exact schedule during unlock',
    EXACT_SCHEDULE_TOOLTIP:
        'If enabled, when unlock criteria is satisfied, the next quiz will be released as per the schedule and not instantly.',
    ERR_SAVING_FEEDBACK: 'Error Saving feedback',
    ASSIGNM_ASG_SUCS: 'Assignment assigned successfully',
    ASSIGNM_FORM_SUCS: 'Form assigned successfully',
    ASSIGNM_CREATED_SUCC: 'Assignment created successfully',
    ASSIGNM_CREATED_LIVE: 'Assignment live successfully',
    ASSIGNM_DEL_SUCC: 'Assignment deleted successfully',
    ASSIGNM_INACTIVE_SUCC: 'Assignment inactivated successfully',
    ASSIGNM_REM_SUCS: 'Assignment removed successfully',
    FORM_ASG_SUCS: 'Form assigned successfully',
    FORM_ASG_TO_GRP_SUCS: 'Form assigned to group successfully',
    FORM_ASG_TO_ROLE_SUCS: 'Form assigned to role successfully',
    SURVEY_ASG_TO_GRP_SUCS: 'Survey assigned to group successfully',
    SURVEY_ASG_TO_ROLE_SUCS: 'Survey assigned to role successfully',
    FORM_REM_SUCS: 'Form removed successfully',
    ASSIGNM_LANG: 'Assignment Language',
    ASSIGNM_TYPE: 'Assignment type',
    ASSIGNM_TYPE_REQ: 'Assignment should be either of type inline or file.',
    ASSIGNM_UPD: 'Assignment updated successfully',
    ASSIGNM_UP_LIVE: 'Assignment updated and live successfully',
    EVENT: 'Event',
    EXPORT: 'Export',
    EXPAND: 'Expand',
    EXPLORE_REPORTS: 'Explore Reports',
    EXPORT_ALL_TOPC: 'Export all Topics',
    EXPORT_ALL_USR: 'Export all Users',
    EXPORT_OTP: 'Export OTP',
    EXPORT_OTP_INFO: 'Are you sure you want to export OTP for all pending users?',
    EXT_FULL_SCRN: 'Exit full screen',
    FEEDBACK: 'Feedback',
    FEEDBACK_ASGN_TO_GRP: 'Feedback assigned to group successfully',
    FEEDBACK_ASGN_TO_ROL: 'Feedback assigned to role successfully',
    FEEDBACK_CRET_SUCC: 'Feedback created successfully',
    FEEDBACK_CANNOT_SUBMIT: 'Beyond this date, feedback cannot be submitted',
    FEEDBACK_CLOSE_FOR_SUB: 'Feedback closed for submission',
    FEEDBACK_DEL: 'Feedback deleted',
    FEEDBACK_REMOVED_FROM_GRP: 'Feedback removed from group',
    FEEDBACK_REMOVED_FROM_ROL: 'Feedback removed from role',
    FEEDBACK_RES_DELETE: 'Feedback response deleted',
    FEEDBACK_UPDATED_SUCC: 'Feedback updated successfully',
    FEEDBACK_USR: 'Feedback User',
    FILE: 'FILE',
    FILE_CHAGES_UPDATED: 'File changes updated successfully.',
    FILE_INFO_NEW: 'Add a Title, Description, Category and Tags.',
    FILE_UPLOAD_FAILED: 'File upload failed. please try again',
    FILE_UPLOADED_SUCC: 'File uploaded successfully.',
    FILE_PROPERTY_UPDATED: 'File property updated successfully.',
    FILES: 'Files',
    FILE_ASG_SUCS: 'File assigned successfully',
    FILE_REM_SUCS: 'File removed successfully',
    FILE_READ_TYPE_INFO: `"Reference" File will be shown in learner library.
    "Must read" File will be appear in learner's todo list`,
    FILE_READ_TYPE_WARNING: 'At least one read type must be selected',
    FILE_NAME: 'File Name',
    FILE_COUNT: 'Files Count',
    FILE_SIZE: 'File Size',
    FILE_UPDATE_WARNING: 'Published content update warning',
    FILE_UPDATE_MSG:
        'Please note that you are updating an already published content. Users who have already viewed the content will not be able to track the updates made by you. Are you sure you want to update?',
    FILTER_BY_CONTENT: 'Filter By Content',
    FILTER_BY_GRPS: 'Filter By Groups',
    FILTER_BY_FREQ: 'Filter By Frequency',
    FILTER_BY_ROL: 'Filter by Roles',
    FILTER_BY_ROLE: 'Filter By Org Role',
    FILTER_BY_LANG: 'Filter By Language',
    FILTER_POINTS_BY_MOD: 'Filter points by content type',
    FILTER_BY_STATUS: 'Filter By Status',
    FILTER_BY_TAGS: 'Filter By Tag',
    FILTER_BY_TIME_PERIOD: 'Filter By Time Period',
    FILTER_BY_TYPE: 'Filter By Type',
    FINISH_SESSION: 'Finish Session',
    FIRST_NAME: 'First Name',
    FOLDER_NAME: 'Folder Name',
    FOLDER_NAME_UPDATED: 'Folder name updated successfully.',
    FOLDER_COUNT: 'Folders Count ',
    FORMS: 'Forms',
    FORM_INACTIVE: 'Form Inactive',
    FILE_DEL: 'File deleted successfully',
    FOLDER_DEL: 'Folder deleted successfully',
    FREQUENCY: 'Frequency',
    FULL_NAME: 'Full Name',
    FULL_SCRN: 'Full screen',
    GRADED: 'Graded',
    GRADE_CUTOFF_REDUCTION: 'Reduction Percentage',
    GRADE_CUTOFF_REDUCTION_ERR: 'Grade reduction percentage shoud be in the range 1 to 100',
    GRP: 'Group',
    GRPINFO: 'Group Info',
    GRP_ROL_SPECIAL_CHAR_ERR:
        'Group or role information cannot start with special characters like @, + or =',
    GRP_ROL: 'Group Role',
    GRP_CRET_SUCS: 'Group created successfully',
    GRP_DEL_SUCS: 'Group deleted successfully',
    GRP_DESC: 'Group Description',
    GRP_AND_ROL_ASG_NO_ASSIGNM_HEADING: 'No assignment assigned',
    GRP_AND_ROL_ASG_NO_ASSIGNM_INFO:
        'To assign assignment, click on <strong>"Assign assignment"</strong> button',
    GRP_AND_ROL_ASG_NO_FORM_HEADING: 'No form assigned',
    GRP_AND_ROL_ASG_NO_FORM_INFO: 'To assign form, click on <strong>"Assign form"</strong> button',
    GRP_AND_ROL_NO_UNASG_FORM_NOTE:
        'For assigning form, Before assigning make sure you have created them. If you have not created forms, go to <strong>"Forms"</strong> under the left sidebar and click on <strong>"Build form"</strong> button',
    GRP_AND_ROL_ASG_NO_TEST_HEADING: 'No test assigned',
    GRP_AND_ROL_ASG_NO_TEST_INFO: 'To assign test, click on <strong>"Assign Test"</strong> button',
    GRP_AND_ROL_ASG_NO_TOP_HEADING: 'No quizzes assigned',
    GRP_AND_ROL_ASG_NO_TOP_INFO:
        'To assign quizzes, click on <strong>"Assign quizzes"</strong> button',
    GRP_AND_ROL_ASG_NO_USR_HEADING: 'No users assigned',
    GRP_AND_ROL_ASG_NO_USR_INFO: 'To assign users, click on <strong>"Assign users"</strong> button',
    GRP_AND_ROL_ASG_NO_LPATH_HEADING: 'No learning path assigned',
    GRP_AND_ROL_ASG_NO_PATH_HEADING: 'No path assigned',
    GRP_AND_ROL_ASG_NO_PATH_INFO: 'To assign path, click on <strong>"Assign Paths"</strong> button',
    GRP_AND_ROL_ASG_NO_LPATH_INFO:
        'To assign learning path, click on <strong>"Assign learning path"</strong> button',
    GRP_AND_ROL_ASG_NO_POLLS_AND_SUR_HEADING: 'No polls & surveys assigned',
    GRP_AND_ROL_ASG_NO_POLLS_AND_SUR_INFO:
        'To assign polls & surveys, click on <strong>"Assign polls & surveys"</strong> button',
    GRP_AND_ROL_ASG_NO_FILE_HEADING: 'No file assigned',
    GRP_AND_ROL_ASG_NO_FILE_INFO: 'To assign file, click on <strong>"Assign file"</strong> button',
    GRP_AND_ROL_NO_USR: 'No users available',
    GRP_AND_ROL_ASG_USR: 'Please assign users',
    GRP_AND_ROL_UASG_NO_ASSIGNM_HEADING: 'No assignment available for assignment',
    GRP_AND_ROL_ASG_NO_ASSIGNM_NOTE: `For assigning assignment, Before assigning make sure you have created them.
    If you have not created assignment,go to <strong>"Assignment"</strong> under <strong>"Content"</strong> and click <strong>"Add assignment"</strong> button`,
    GRP_AND_ROL_UNASG_NO_FORM_HEADING: 'No form available for assignment',
    GRP_AND_ROL_ASG_FORM_NOTE: `To assign form, go to <strong>"Assign To Group / Role"</strong> under <strong>"Content"</strong> tab, select forms and click on <strong>"Assign Form"</strong> button`,
    GRP_AND_ROL_UASG_NO_TEST_HEADING: 'No test available for assignment',
    GRP_AND_ROL_ASG_NO_TEST_NOTE: `For assigning test, Before assigning make sure you have created them.
    If you have not created test,go to <strong>"Test"</strong> under <strong>"Content"</strong> and click <strong>"Add Test"</strong> button`,
    GRP_AND_ROL_UASG_NO_TOP_HEADING: 'No quiz available for assignment',
    GRP_AND_ROL_UASG_NO_TOP_INFO: 'Please add quizzes in the quiz management',
    GRP_AND_ROL_ASG_NO_TOP_NOTE: `For assigning quizzes click assign quiz,Before assigning make sure you have created them.
    If you have not created quizzes,go to <strong>"Quiz"</strong> under <strong>"Content"</strong> and click <strong>"Add quiz"</strong> button`,
    GRP_AND_ROL_UASG_NO_USR_HEADING: 'No users available for assignment',
    GRP_AND_ROL_UASG_NO_USR_INFO: 'Please add users in the user management',
    GRP_AND_ROL_ASG_NO_USR_NOTE: `For assiging users click assign users,Before assigning make sure you have added them to this org.
    If you have not added the users, go to <strong>"Manage"</strong> under <strong>"Users"</strong> and click on <strong>"Add Users"</strong> button`,
    GRP_AND_ROL_UASG_NO_LPATH_HEADING: 'No learning path available for assignment',
    GRP_AND_ROL_UASG_NO_LPATH_INFO: 'Please add new learning path in the learning path management',
    GRP_AND_ROL_ASG_NO_LPATH_NOTE: `For assigning learning paths click assign learning paths,Before assigning make sure you have created them.
    If you have not created paths,go to <strong>"Learning path"</strong> under <strong>"Content"</strong> and click <strong>"Add learning path"</strong> button`,
    GRP_AND_ROL_UASG_NO_PATH_HEADING: 'No path available for assignment',
    GRP_AND_ROL_UASG_NO_PATH_INFO: 'Please add new path in the path management',
    GRP_AND_ROL_ASG_NO_PATH_NOTE: `For assigning paths click assign paths,Before assigning make sure you have created them.
    If you have not created paths,go to <strong>"Path"</strong> under <strong>"Content"</strong> and click <strong>"Add path"</strong> button`,
    GRP_AND_ROL_UASG_NO_POLLS_AND_SUR_HEADING: 'No polls & surveys available for assignment',
    GRP_AND_ROL_UASG_NO_POLLS_AND_SUR_INFO: 'Please add new polls and surveys in the management',
    GRP_AND_ROL_ASG_NO_POLLS_AND_SUR_NOTE: `For assigning polls & surveys click assign polls / surveys,Before assigning make sure you have created them.
    If you have not created polls & surveys,go to <strong>"Poll / survey"</strong> under <strong>"Content"</strong> and click <strong>"Add poll / survey"</strong> button`,
    GRP_AND_ROL_UASG_NO_FILE_HEADING: 'No files available for assignment',
    GRP_AND_ROL_UASG_NO_FILE_INFO: 'Please add file in the library assets',
    GRP_AND_ROL_ASG_NO_FILE_NOTE: `For assigning files click assign files,Before assigning make sure you have created them.
    If you have not created files,go to <strong>"Files"</strong> under <strong>"Content"</strong> and click <strong>"Create new folder"</strong> button`,
    GRP_AND_ROL_ASG_MS_NO_ASSIGNM_INFO:
        'To assign assignment, go to "Assign To Group / Role" under "Content" tab  select assignment and click on <strong>"Add assignment"</strong> button',
    GRP_AND_ROL_ASG_MS_NO_USR_INFO: `To assign users, go to "Assign To Group / Role" under "User" tab select users click on <strong>"Assign users"</strong> button`,
    GRP_AND_ROL_ASG_MS_NO_TEST_INFO:
        'To assign test, go to "Assign To Group / Role" under "Content" tab  select test and click on <strong>"Add test"</strong> button',
    GRP_AND_ROL_ASG_MS_NO_TOP_INFO:
        'To assign quizzes, go to "Assign To Group / Role"  under "Content" tab select quizzes and click on <strong>"Assign quizzes"</strong> button',
    GRP_AND_ROL_ASG_AC_NO_USR_INFO:
        'To assign users, go to "Assign To Group / Role" under "User" tab and click on <strong>"Assign users"</strong> button',
    GRP_AND_ROL_ASG_MS_NO_LPATH_INFO:
        'To assign learning path, go to "Assign To Group / Role" under "Content" tab  select Learning path and click on <strong>"Assign learning paths"</strong> button',
    GRP_AND_ROL_ASG_MS_NO_PATH_INFO:
        'To assign path, go to "Assign To Group / Role" under "Content" tab  select path and click on <strong>"Assign paths"</strong> button',
    GRP_AND_ROL_ASG_IU_NO_USR_HEADING: 'No pending users available',
    GRP_AND_ROL_ASG_MS_NO_FILE_INFO:
        'To assign files, go to "Assign To Group / Role" under "Content" tab  select files and click on <strong>"Assign files"</strong> button',
    GRP_AND_ROL_ASG_MS_NO_POLLS_AND_SUR_INFO: `To assign polls & surveys,
    go to "Assign To Group / Role" under "Content" tab  select polls & surveys and click on <strong>"Assign polls & surveys"</strong> button`,
    GRP_INFO: 'Group Info',
    GRP_MAX_CHAR: 'Group Name cannot be more than 100 characters long.',
    GRP_NAME: 'Group Name',
    GRP_PARENT_UPD_SUCS: 'Group parent updated successfully',
    GRP_UPD_SUCS: 'Group updated successfully',
    GRPS: 'Groups',
    HEADER_FIELD: 'Header Field',
    HIERARCHY_ASSIGN_NOTE:
        'Note : Assign to hierarchy request submitted will be processed in background. Please check back in 5 minutes after submitting the request.',
    HIERARCHY_UNASSIGN_NOTE:
        'Note : Remove from hierarchy request submitted will be processed in background. Please check back in 5 minutes after submitting the request.',
    HIGH_PRIORITY: 'High priority ',
    HINT_SPECIAL_CHAR_ERR: 'Question hint cannot start with special characters like @, + or =',
    HISTORY_DETAILS: 'History Details',
    HOST_INACT: 'Host Inactive',
    ITEMS_COUNT: 'Items count',
    IMG_ONLY: 'Image Only',
    IMPORT_ALL: 'Import All',
    IMPORT: 'Import',
    IMPORT_QUESTION: 'Import Questions',
    IMPORT_QUESTION_REQ_SUCC: 'Import questions, request submitted successfully',
    IMPORT_NOTE:
        'Note : Import request submitted will be processed in background. Please check 5 minutes after submitting the request.',
    IMPORT_SELECTED_QUESTION: 'Import Selected Question',
    IN_DAYS: 'In days',
    IN_HOURS: 'In hours',
    INACTIVATE_ASSIGNM: 'Inactive assignment',
    INACTIVATE_ASSIGNM_CONFIRM: 'Are you sure to inactivate assignment ?',
    INACTIVATE_POLL: 'Inactivate Poll',
    INACTIVATE_SURVEY: 'Inactivate Survey',
    INCLUDE_CERTIFICATE_TEMP: 'Include Certificate Template',
    INCLUDE_FILES: 'Include Files',
    INCLUDE_GRP: 'Include Groups',
    INCLUDE_INACTIVE: 'Include Inactive',
    INCLUDE_ROL: 'Include Role',
    INCLUDE_ROLS: 'Include Roles',
    INCLUDE_USERS: 'Include Users',
    INSERT: 'Insert',
    INITIAL_SHUFFLE: 'Shuffle questions initially',
    INLINE: 'INLINE',
    INVALID_CATEGORY: 'Invalid category. Please select category from dropdown list.',
    INVI: 'Invite',
    INVI_ALL: 'Invite all',
    INVI_ALL_BY_USERS:
        'Are you sure you want to send invite EMAIL/ SMS to all users who are yet to activate their account?',
    INVI_SEL_USR: 'Invite Selected Users',
    INVITE_ALL_USERS_NOTE:
        'NOTE: All users who were added to the system an year ago and still have not activated their account will be excluded from this bulk invite action.',
    IS_DOWNLOADABLE: 'Is Downloadable',
    ISSUED_CERTIFICATES: 'Issued Certificates',
    IS_RATEABLE: 'Is Rateable',
    ITERATION: 'iteration',
    LABEL_SPECIAL_CHAR_ERR: 'Label cannot start with special characters like @, + or =',
    LANG: 'Language',
    LANGS: 'Languages',
    LAST_ATTEMPTED_ON: 'Last attempted on',
    LAST_INVIT: 'Last Invited',
    LAST_INVIT_AT: 'Last Invited At',
    LAST_NAME: 'Last Name',
    LAST_QUESTION: 'Last question',
    LB: 'Leaderboard',
    LB_NAME_SPECIAL_CHAR_ERR:
        'Leaderboard name cannot start with special characters like @, + or =',
    LB_ROL_UP: 'Include all child groups in hierarchy under selected groups',
    LB_TIME_PER: 'Once set, Time period cannot be reset',
    LB_TEAM_PER: 'Once set, Team by cannot be reset',
    LB_TYPE: 'Leaderboard type',
    LEADERBOARD_CREATED: 'Leaderboard created.',
    LEADERBOARD_ENABLE: 'Leaderboard enabled.',
    LEADERBOARD_DISABLE: 'Leaderboard disabled.',
    LEADERBOARD_DELETE_SUCCESSFULLY: 'Leaderboard deleted successfully',
    LEADERBOARD_RELEASE_DELETE_SUCCESSFULLY: 'Leaderboard release deleted successfully',
    LEADERBOARD_GRP_ADD_SUCC: 'Leaderboard group added successfully',
    LEADERBOARD_GRPS_ADD_SUCC: 'Leaderboard groups added successfully',
    LEADERBOARD_GRP_REM_SUCC: 'Leaderboard group removed successfully',
    LEADERBOARD_GRPS_REM_SUCC: 'Leaderboard groups removed successfully',
    LEADERBOARD_ROL_ADD_SUCC: 'Leaderboard role added successfully',
    LEADERBOARD_ROLS_ADD_SUCC: 'Leaderboard roles added successfully',
    LEADERBOARD_ROL_REM_SUCC: 'Leaderboard role removed successfully',
    LEADERBOARD_ROLS_REM_SUCC: 'Leaderboard roles removed successfully',
    LEADERBOARD_SAVE_ERR: 'Error Saving Leaderboard',
    LEADERBOARD_SCHEDULED_SUCCESSFULLY: 'Leaderboard scheduled successfully',
    LEADERBOARD_UPDATED: 'Leaderboard updated.',
    LEARNINGPATHS: 'Learning Paths',
    LEARNING_PATH_CLONE_NOTE:
        'Note : Only quizzes in LIVE status will be considered for duplication. Duplicate request submitted will be processed in the background. Please check 5 minutes after submitting the request.',
    LEAVE_ASIGN: 'Leave Assignment',
    LEAVE_COMMNET: 'Leave Comment',
    LEAVE_CUSTOM_STYLE: 'Leave Custom Style',
    LEAVE_EVENT: 'Leave Event',
    LEAVE_EVENT_HOST: 'Leave Host',
    LEAVE_FEEDBACK: 'Leave Feedback',
    LEAVE_FILE: 'Leave File',
    LEAVE_FORM: 'Leave Form',
    LEAVE_FOLDER_CREATION: 'Leave Folder Creation',
    LEAVE_LP: 'Leave Learning Path',
    LEAVE_PATH: 'Leave Path',
    LEAVE_NODE: 'Leave Group',
    LEAVE_MANAGE: 'Leave Manage',
    LEAVE_POLL: 'Leave Poll',
    LEAVE_QUIZ: 'Leave Quiz',
    LEAVE_ROL: 'Leave Role',
    LEAVE_SUB: 'Leave Subscription',
    LEAVE_SURVEY: 'Leave Survey',
    LEAVE_TEST: 'Leave Test',
    LEAVE_QUESTION: 'Leave Question',
    LEAVE_SCHEDULE_LB: 'Leave Schedule Leaderboard',
    LOGIN_VIA_SSO: 'Login via SSO',
    LIVE: 'LIVE',
    LP_ASSIGN_SUCC: 'Learning path assigned successfully',
    LP_CREATED_SUCC: 'Learning path created successfully.',
    LP_DEP_WAR:
        'The learning path feature is soon to be deprecated. We recommend using path feature instead. Would you still like to create a learning path?',
    LP_REM_SUCC: 'Learning path removed successfully',
    LP_CRITERIA_UPDATED: 'Unlock criteria updated successfully',
    LP_QUIZ_ADDED: 'Sequence quiz added successfully',
    LP_QUIZ_POS_UPDTAED: 'Sequence quiz position updated successfully',
    LP_QUIZ_REM: 'Sequence topic removed successfully',
    LP_UPDATED_SUCC: 'Learning path updated successfully.',
    LP_UPDATED_AND_LIVE: 'Learning path updated & made live successfully.',
    LP_INACTIVE: 'Learning path is inactivated.',
    LP_DELETED: 'Learning path deleted successfully',
    LP_INACT: 'Learning path Inactive',
    MAINTAIN_CHOICE_ORDER: 'Maintain choice order',
    MAKE: 'Make',
    MAKE_INACTIVE: 'Make Inactive',
    MAKE_LIVE: 'Make Live',
    MAKE_LIVE_CONDITON: 'Assign test will be enable, Once Test made live',
    MAKE_FILE_MANDATORY: 'Make file view mandatory',
    MAKE_FILE_MANDATORY_INFO:
        'If enabled, the user will have to view related files first to unlock the quiz',
    MAKE_FILE_MANDATORY_INFO_TEST:
        'If enabled, the user will have to view related files first to unlock the test.',
    MAKE_FILE_MANDATORY_CONFIRM:
        'View related files is optional, setting this flag will lock the quiz untill all the files are viewed, do you wish to continue? ',
    MAKE_FILE_MANDATORY_NOT_CONFIRM:
        'View related files is optional, not setting this flag will unlock the quiz.' +
        'without any restriction on asset viewing, do you wish to continue? ',
    MAKE_FILE_MANDATORY_CONFIRM_FOR_TEST:
        'View related files is optional, setting this flag will lock the test until all the files are viewed, do you wish to continue? ',
    MAKE_FILE_MANDATORY_NOT_CONFIRM_FOR_TEST:
        'View related files is optional, not setting this flag will unlock the test ' +
        'without any restriction on asset viewing, do you wish to continue? ',
    MANAGE_CUSTOM_FIELDS: 'Manage custom fields',
    MANAGE_FIELDS_MAPPING: 'Manage custom fields mapping',
    MANAGE_CERTIFICATE: 'Manage Certificate',
    MANAGE_EVENT: 'Manage Event',
    MANAGE_FILES: 'Manage Files',
    MANAGE_FORM: 'Manage Form',
    MANAGE_HOST: 'Manage Host',
    MANDATORY_QUESTION: 'Mandatory Question',
    MNG_TEAM: 'Manage Team',
    MANAGE: 'Manage',
    MASTERED: 'Mastered',
    MASTERED_AT: 'Mastered At',
    MASTERED_QUIZ: 'Mastered quizzes',
    MANAGAE_SCHEDULE_REPORT: 'Manage Schedule Report',
    MAX_ATTEMPT_ERR: 'Quiz max attempt should be in the range 2 to 10',
    MAX_ATTEMPT_HINT:
        'Quiz question wont be released if user completes the max attempts specified here.',
    MAX_FILE_UPLOAD: 'Max File Uploads',
    MAX_FILE_UPLOAD_ERR: 'Maximum file size allowed is',
    MAX_FILE_UPLOAD_INFO: 'Max files that the user can upload for submission',
    MIN_FILE_UPLOAD: 'Min File Uploads',
    MIN_FILE_UPLOAD_INFO: 'Min files that the user should upload for submission',
    MIN_ONE_ITEM_REQUIRED: 'Min 1 item required. Please add path item.',
    MIN_ONE_QUESTION_REQ: 'Min 1 questions required. Please add question.',
    MIN_ONE_QUIZ_REQUIRED: 'Min 1 topic required. Please add more topics.',
    MIN_QUIZ_QUES_ERR:
        'The minimum quiz questions cannot be greater than the maximum quiz questions',
    MIN_TEST_QUES_ERR:
        'The minimum test questions cannot be greater than the maximum test questions',
    MIN_TWO_OPTION_REQ: 'Minimum 2 options required for dropdown list.',
    MAX_GRADE: 'Max Grade',
    MAX_GRADE_VAL_ERR: 'Max grade should be in the range of 1 to 100',
    MAX_GRADE_PASS_GRADE_ERR: 'Max grade and pass grade required when needs grading is checked',
    MAX_GRADE_INFO: 'Max assignment grade that can be awarded',
    MAX_SUB: 'Max Submissions',
    MAX_SUB_INFO: 'Max number of re-submissions allowed',
    MAX_UPLOAD_SIZE: 'Maximum upload size',
    MAX_UPLOAD_SIZE_FOR_IMG: 'Maximum size allowed for image is 5 MB.',
    MAX_UPLOAD_SIZE_INFO: 'Max file upload size in MB for a submission',
    LOCAL_TIME_ZONE_TEXT: '(Times are shown in the local timezone)',
    MAX_ATTEMPTS: 'Max Attempts',
    MAX_BCKLOG_QUES: 'Max Backlog Questions',
    MAX_SUB_ERR: 'The max submissions may not be greater than 10',
    MAX_SUB_REQ_ERR:
        'Max submissions count is required when assignment resubmit criterion is either MANUAL or AUTO UNTIL PASS',
    MBL_INV: 'Mobile number is invalid',
    MBL_NO: 'Mobile No',
    MBL_VERI: 'Mobile verified',
    MBL_VIEW: 'Mobile view',
    TAB_VIEW: 'Tab view',
    MONITOR_VIEW: 'Monitor view',
    AUTHOR_VIEW: 'Author view',
    MCQ: 'MCQ',
    MIN: 'Minutes',
    MIN_MST_QUES: 'Quiz mastery criteria',
    MIN_MST_QUES_HINT:
        ' This is a number value denoting the number of questions to master as the pass criteria for the quiz',
    MISS_RATIONAL_FOR_COR_CHOICE: 'Missing rationale for correct choice',
    MISS_RATIONAL_FOR_DEFAULT_LANG: `Missing rationale for default language's correct choice`,
    MNG_ITEMS: 'Manage items',
    MNG_QUES: 'Manage Questions',
    MNG_QUIZZES: 'Manage quizzes',
    MOD_TO_INCLUDE: 'Contents to include',
    MORE_OPTIONS: 'More options',
    MORE_QUESTION_LEFT: 'more question left',
    MORE_QUESTIONS_LEFT: 'more questions left',
    MOVE: 'Move',
    MOVE_FILE: 'Move File',
    MOVE_FOLDER: 'Move Folder',
    MOVE_GRP: 'Move Group',
    MOVE_TO: 'Move to',
    MOVE_TO_SAME_FOLDER_ERR: 'Cannot move to same folder',
    MULTI_CHOICE: 'Multiple choice',
    MULTI_CHOICE_IMAGE: 'Multiple choice & image',
    MY_BOOKMARKS: 'My Bookmarks',
    MY_DOWNLOADS: 'My Downloads',
    NAME: 'Name',
    NAME_FIELD_REQ: 'The name field is required',
    NEEDS_GRADING: 'Needs Grading',
    NEEDS_GRADING_HELP: 'If set true assignment will be graded',
    NEW_FOLDER_CREATE_SUCC: 'New folder created successfully.',
    NO_CHANGE: 'No changes to update',
    NO_GRADING: 'No Grading',
    NO_TOP_HEADING: 'No quizzes available',
    NO_TOP_INFO: 'Please add a new quiz',
    NO_GROUPS_AVAILABLE: 'There are no groups available.',
    NO_GROUPS_SELECTED: 'There are no groups selected.',
    NO_ROL_AVAILABLE: 'There are no roles available.',
    NO_ROL_SELECTED: 'There are no roles selected.',
    NO_ASSETS_AVAILABLE: 'There are no assets available.',
    NO_DOWNLOAD_REPORT_HEADING: 'No Download Report ',
    NO_FILE_ASG: 'No files assigned',
    NO_FOLDER_TO_MOVE: 'No folder in this level to move',
    NO_FOLDER_TO_MOVE_INFO: 'You can move to this folder by clicking on move button',
    NO_LEADERBOARD_ASG: 'No leaderboard assigned',
    NO_QUIZ_ASG: 'No quiz assigned',
    NO_TEST_ASG: 'No test assigned',
    NO_PATH_ASG: 'No path assigned',
    NO_POLL_ASG: 'No poll assigned',
    NO_SEARCH_RESULTS: 'No search results found',
    NOT_ADDED_ANY_PATH: 'Not added to any path',
    NO_SUR_ASG: 'No survey assigned',
    NO_FILE_ASG_HEADING: 'No file available',
    NO_FILE_ASG_INFO: 'Please assign file',
    NO_OPTS_AVAILABLE: 'No options available',
    NO_DEPENDENT_OPTS_SEL: 'No dependent options are selected',
    No_FIELDS_AVAILABLE: ' No fields available, Create a new field. ',
    NO_FILE_UASG_HEADING: 'No files available for assignment',
    NO_FILE_UASG_INFO: 'Please add file in the library assets',
    NO_LB_HEADING: 'No leaderboards available',
    NO_LB_INFO:
        'To create a leaderboard click <strong>"Create Leaderboard"</strong> button on top right.',
    NO_LPATH_AVAIL: 'No learning paths available',
    NO_PATH_AVAIL: 'No paths available',
    NO_ATTEMPTS_LEFT: 'no_attempts_left',
    NO_QUES_AVAIL: 'No questions available',
    NO_QUES_GVN_DAY:
        'This defines the number of questions received on a given day, usually set at < 1/4th the total number of questions in the quiz.',
    NO_RESULT_FOUND: 'No results found for',
    NO_RELEASE_REPORT_HEADING: 'Reports are yet to be released',
    NOT_ACTIVATED: 'Not Activated',
    NO_RELEASES: 'No Releases',
    NOT_RELEASED: 'Not Released',
    NOT_VALID_FILE: 'Not valid File.',
    NO_GRP_SELECTED: 'No group selected.',
    NO_SEARCH_RESULT_FOR: 'No search result for',
    NO_SCHEDULE_REPORT_HEADING: 'No schedule report available',
    NO_SCHEDULE_REPORT_INFO: 'Please add a new schedule report',
    NO_TAG_FOUND: 'No tags found',
    NO_TEST_HEADING: 'No test available',
    NO_TEST_INFO: 'Please add a new test',
    NO_QUES_ASG_PLS_SEARCH_SEL_QUIZ:
        'No quizzes assigned. Please search and select the quiz to add in list.',
    NXT_QUES: 'NEXT QUESTION',
    NXT_SCHEDULE: 'Next Schedule',
    NXT_SCHEDULE_AT: 'Next Scheduled At',
    ONLY_ASSIGN_MODULES_INFO:
        'Include only the content assigned to teams added to this leaderboard',
    OPEN: 'Open',
    OPEN_FILE: 'Open file',
    OPEN_FOLDER: 'Open folder',
    OPEN_IMAGE: 'Open Image',
    OPEN_PDF: 'Open pdf',
    OPEN_SCORM: 'Open scorm',
    OPEN_URL: 'Open url',
    OPEN_VIDEO: 'Open video',
    OUTPUT: 'Output',
    ORG_ROL: 'Org Role',
    PASS_CRITERIA: 'Pass Criteria',
    PASS_GRADE: 'Pass Grade',
    PASS_GRADE_GRET_MAX_GRADE_ERR:
        'Pass grade should not be greater than max grade and should be in the range of 1 to 100',
    PASS_GRADE_INFO: 'Assignment pass grade',
    PASSED: 'Passed',
    PAST: 'Past',
    PATHS: 'Paths',
    PATH_GRPS: 'Path Groups',
    PATH_ROL: 'Path Roles',
    PATH_ITEM_DELETED: 'Path item deleted successfully',
    PATH_ASSIGN_SUCC: 'Path assigned successfully',
    PATH_CREATED_SUCC: 'Path created successfully.',
    PATH_REM_SUCC: 'Path removed successfully',
    PATH_UPDATED_SUCC: 'Path updated successfully.',
    PATH_UPDATED_AND_LIVE: 'Path updated & made live successfully.',
    PATH_INACTIVE: 'Path is inactivated.',
    PATH_INACT_SUCS: 'Path Inactivated successfully',
    PATH_DELETED: 'Path deleted successfully',
    PATH_EXACT_SCHEDULE_TOOLTIP:
        'If enabled, when unlock criteria is satisfied, the next content will be released as per the schedule and not instantly.',
    PATH_INACT: 'Path Inactive',
    PATH_STATUS: 'Path Status',
    PAUSE_LEARNING_DISABLE_NOTE:
        'Disabling this flag could potentially have adverse effects on users who have already paused their learning.',
    PENDING_USERS: 'Pending Users',
    POINTS: 'Points',
    POINTS_SETTING: 'Points Setting',
    PRIORITY: 'priority',
    POLL: 'POLL',
    POLLS: 'Polls',
    POLL_ASG_SUCS: 'Poll assigned successfully',
    POLL_GRPS: 'Poll Groups',
    POLL_ROLS: 'Poll Rols',
    POLL_CREATED_LIVE_SUCC: 'Poll created & Live successfully',
    POLL_CREATED_SUCCESSFULLY: 'Poll created successfully',
    POLL_DEL_SUCS: 'Poll deleted successfully',
    POLL_INACT: 'Poll Inactive',
    POLL_INACT_SUCS: 'Poll Inactivated successfully',
    POLL_question: 'Poll Question',
    POLL_SURVEY_ASG_SCC: 'Poll/Survey assigned successfully',
    POLL_SURVEY_REM_SCC: 'Poll/Survey removed successfully',
    POLL_LANGUAGE: 'Poll Langauge',
    POLL_THEME: 'Poll Theme',
    POLL_UPD_LIVE_SUCS: 'Poll updated & Live successfully.',
    POLL_UPD_SUCS: 'Poll updated successfully.',
    PROPERTIES: 'Properties',
    PROCESSING_SCORM: "We're processing this SCORM. Please check back later",
    PLS_CHOSE_DEFUT_LANG: 'Please choose default language.',
    PLS_SET_DEFUT_LANG: 'Please set default language.',
    PLS_CHOSE_REL_FRQ_DYS: 'Please choose release frequency days.',
    PLS_ENABLE_LB: 'Please enable leaderboard type.',
    PLS_ENTER_VALID_URL: 'please enter valid URL',
    PLS_ENTER_VALID_YT_URL: 'The URL entered must be a link to YouTube.',
    PLS_FILL_ALL_REQ: 'Please fill all required field',
    PLS_FILL_CHOIC_FOR_PRE: 'Please fill all choice text for preview.',
    PLS_FILL_CHOIC_IMG_FOR_PRE: 'Please fill all choice image for preview.',
    PLS_FILL_CHOIC_TEXT_IMG_FOR_PRE: 'Please fill all choice text & image for preview.',
    PLS_FILL_QUES_CHOIC_FOR_PRE: 'Please fill question & all choices for preview.',
    PLS_PROVDIDE_A_REMARK: 'Please provide a remark',
    PLS_PROVIDE_A_VALID_GRADE: 'Please provide a valid grade',
    PLS_SEL_GRP: 'Please select a group to move',
    PLS_SEL_FILE_TO_UPLOAD: 'Please select a file to upload.',
    PLS_SEL_FOLDER: 'Please select a folder to move',
    PLS_SEL_QUEST: 'Please select atleast one question',
    PLS_SEL_LB_TIME_PERIOD: 'Please select a leaderboard time period.',
    PLS_SET_CRCT_ANS: 'Please set correct answer',
    PLS_SET_CRCT_PASS_CRITE: 'Please set correct pass criteria',
    PLS_SET_REVEAL_ANSWER_CRITERIA: 'Please set reveal answer criteria',
    PLS_WAIT_FILE_UPLOAD_PROGRESS: 'Please wait file upload in progress',
    VALID_TIME_NOT_LESS_THEN_30_MINUTES: 'Test validity period should be at least 30 minutes',
    PREVW: 'Preview',
    PREVW_EVENT: 'View Event',
    PREVW_FORM: 'Preview Form',
    PREVIEW_QUESTIONS: 'Preview Questions',
    RELEASES: 'Releases',
    QR_CODE: 'qr code',
    QUSETION_SPECIAL_CHAR_ERR: 'Question cannot start with special characters like @, + or =',
    QUES_CRE_SUCS: 'Question created successfully',
    QUES_DEL_SUCS: 'Question deleted successfully',
    QUES_HINT: 'Question Hint',
    QUES_MST_CRITE: 'Question mastery criteria',
    QUES_MST_CRITE_MAX_ATTEMPT_ERR:
        'Quiz max attempts cannot be less than question mastery criteria',
    QUES_NO_CHG: 'No changes in question to update',
    QUES_NO_CHG_QUIZ_UPDATE: 'No changes in quiz to update',
    QUESTION_OPTIONS_LESS_THAN_TWO: 'Question options should not less then 2',
    QUESTION_OPTION_MORE_THAN_TEN_ERR: 'Question options cannot have more than 10',
    QUESTION_TEXT_AREA_LESS_THAN_TWO: 'Question textboxes should not less then 2',
    QUESTION_TEXT_AREA_MORE_THAN_TEN_ERR: 'Question textboxes cannot have more than 10',
    QUES_REL_CNT:
        'This is the maximum number of questions that a learner is allowed to accumulate at any point, ideally 3X the daily release count',
    QUES_REL_TIME: 'This is the time of day when questions will be sent to the user',
    QUES_UPD_SUCS: 'Question updated successfully',
    QUIZS: 'Quizzes',
    QUIZ_COUNT: 'Quiz Count',
    QUIZ_CRE_AT: 'Quiz created at',
    QUIZ_GRP: 'Quizze Groups',
    QUIZ_LANG: 'Quiz Language',
    QUIZ_PRE: 'Quiz Preview',
    QUIZ_MAX_ATTEMPTS: 'Max Attempts Per Question',
    QUIZ_ROL: 'Quizze Roles',
    QUIZ_TAG_WARNING: 'Quiz should have at least one tag',
    RATINLE: 'Rationale',
    RATIONAL_SPECIAL_CHAR_ERR: 'Rationale cannot start with special characters like @, + or =',
    RANK: 'Rank',
    READ_TYPE: 'Read type',
    RECORDINGS: 'Recordings',
    RECIPIENT_FIELD_REQ: 'Recipient field is required',
    REFREENCE_FILES: 'Reference files',
    REL_FRQ: 'Release Frequency',
    REL_TIME: 'Release Time',
    RELEASE: 'Release',
    RELEASE_LIST: 'Release List',
    RELEASE_DETAILS: 'Release Details',
    REM: 'Remove',
    REF: 'Refresh',
    REFESH_CERTIFICATE: 'Refresh Certificate',
    REFESH_CERTIFICATE_INFO:
        'Once the refresh process is completed, the previously generated certificate will no longer be available. Additionally, please note that a certificate can be refreshed only once, so verify and confirm any changes before requesting to refresh the certificate. Keep in mind that the refresh process can take up to 5 minutes.',
    REM_ASSIGNM: 'Remove assignment',
    REM_FORM: 'Remove form',
    REM_FILE: 'Remove File',
    REM_PATH: 'Path removed successfully',
    REM_LP: 'Learning path removed successfully',
    RESET_FILTER: 'Reset filter',
    RESPONSE_SPLIT: 'Response split',
    RESULT: 'Result',
    RESUME: 'Resume',
    RELEASED_ITEMS_COUNT: 'Released items count',
    REM_SEL_ASSIGNM: 'Remove selected assignment',
    REM_SEL_FORM: 'Remove selected form',
    REM_SEL_TOPC: 'Remove selected quiz',
    REM_SEL_TEST: 'Remove selected test',
    REM_SEL_USR: 'Remove selected User',
    REM_LANG: 'Remove Language',
    REM_TEST: 'Remove Test',
    REM_THUMBNAIL: 'Remove Thumbnail',
    REM_TOPC: 'Remove Quiz',
    REM_TRANS: 'Remove Translation',
    REM_USR: 'Remove User',
    REPORT: 'Report',
    REPORT_DEL_SUCS: 'Report deleted successfully',
    REPORT_SPECIAL_CHAR_ERR: 'Report name cannot start with special characters like @, + or =',
    REPORT_SUS_SUCS: 'Report Suspended successfully',
    REPORT_SHED_SUCS: 'Report Scheduled successfully',
    REQ: 'required*',
    REQ_FIELD: 'Required field',
    REQ_FIELD_INFO: 'Enable this option to make the field requied while event creation.',
    RECIPIENTS: 'Recipients',
    REMARKS: 'Remarks',
    REMOVE_GRP: 'Remove group',
    REMOVE_ROL: 'Remove role',
    REMOVE_USER: 'Remove user',
    RESTORE_USR: 'Restore User',
    RESTRICT_STATUS: 'Options will be enable, Once file made active',
    REVEAL_ANSWER: 'Reveal Answer',
    REVEAL_ANSWER_INFO:
        'This will allow users to see the answer as soon as one of the reveal answer criteria is satisfied',
    REVEAL_ANSWER_CRITERIA: 'Reveal Answer Criteria',
    REVEAL_ANSWER_CRITERIA_INFO: `"Passed" means the user will only see the answer key after having passed the test.
    "No attempts left" allows the user to see answers once they have finished all attempts.
    "Once Attempted" allows the user to see answers once they have attempted the test.`,
    REVEAL_ANSWER_CRITERIA_VALIDITY_EXPIRED_INFO: `"Passed" means the user will only see the answer key after having passed the test.
    "No attempts left" allows the user to see answers once they have finished all attempts.
    "Once Attempted" allows the user to see answers once they have attempted the test.
    "Validity expired" shows the user the answer key if the end date has expired and the user has attempted the test atleast once.`,
    REVIEW_SUB: 'Review Submission',
    ROL: 'Roles',
    ROLE: 'Role',
    ROLE_INFO: 'Role Info',
    ROLE_DESC: 'Role Description',
    ROLE_CREATED_SUCCESSFULLY: 'Role created successfully',
    ROLE_DELETED_SUCCESSFULLY: 'Role deleted successfully',
    ROLE_UPDATED_SUCCESSFULLY: 'Role updated successfully',
    ROL_UPD_SUCS: 'Role updated successfully',
    ROLE_MAX_CHAR: 'Role Name cannot be more than 30 characters long.',
    ROLE_NAME: 'Role Name',
    ROLLUP_INFO: `When enabled, the points considered for taking average in
    team leaderboard will be IN and UNDER the selected groups`,
    REPORT_TYPE: 'Report Type',
    RUN_HISTORY: 'Run History',
    SAVE_DRAFT: 'SAVE AS DRAFT',
    SAVE_LIVE: 'SAVE & LIVE',
    SAV: 'SAVE',
    SAV_CHG: 'SAVE CHANGE',
    SAVE_INVI: 'SAVE & INVITE',
    SAVE_POINT_FILE_META:
        'Looks like this file has been updated meanwhile. Would you like to refresh the file or force update your changes?',
    SAVE_POINT_FOLDER_META:
        'Looks like this folder has been updated meanwhile. Would you like to refresh the folder or force update your changes?',
    SAVE_POINT_HEADER: 'Data out of sync',
    SAVE_POINT_IN_QUESTIONS:
        'Looks like this question has been updated meanwhile. Would you like to refresh the question or force update your changes?',
    SAVE_POINT_LP_META:
        'Looks like this learning path has been updated meanwhile. Would you like to refresh the learning path or force update your changes?',
    SAVE_POINT_PATH_META:
        'Looks like this path has been updated meanwhile. Would you like to refresh the path or force update your changes?',
    SAVE_POINT_POLL_META:
        'Looks like this poll has been updated meanwhile. Would you like to refresh the poll or force update your changes?',
    SAVE_POINT_QUIZ_META:
        'Looks like this quiz has been updated meanwhile. Would you like to refresh the quiz or force update your changes?',
    SAVE_POINT_SURVEY_META:
        'Looks like this survey has been updated meanwhile. Would you like to refresh the survey or force update your changes?',
    SAVE_POINT_TEST_META:
        'Looks like this test has been updated meanwhile. Would you like to refresh the test or force update your changes?',
    SCHD_NOW: 'Schedule Now',
    SCHD: 'Schedule',
    SCHDS: 'Schedules',
    SCHEDULE_FORM: 'Schedule Form',
    SCHEDULE_TEMPLATE: 'Schedule Template',
    SCHEDULE_REPORT: 'Schedule Report',
    SCHEDULE_REPORT_CREATED_SUCCESSFULLY: 'Schedule report created successfully',
    SCHEDULE_REPORT_UPDATED_SUCCESSFULLY: 'Schedule report updated successfully',
    SEARCH_QUES: 'Search question',
    SELECT_TEMP_DOWNLOAD: 'Select a template to download',
    SELECT_TEMP_SCHED: 'Select a template to schedule',
    SELECTED_DEPENDENT_FIELD: 'Selected dependent field',
    SELECT_DEPENDENT_FIELD: 'Select dependent field',
    SELECT_MAPPING_FIELDS: 'Select mapping fields',
    SELECT_PARENT_FIELD: 'Select parent field',
    SELECTED_PARENT_FIELD: 'Selected parent field',
    SELECTED_QUESTION: 'Selected Question',
    SELECT_ALL: 'Select All',
    SLC_OPTIONS: 'Select options',
    SEL_ROL: 'Select Roles',
    SEL_ROLS: 'Selected Roles',
    SEL_THEME: 'Select a theme',
    SEL_GRP: 'Selected Groups',
    SEL_GRPS: 'Select Groups',
    SEL_USERS: 'Select Users',
    SEL_USR_INVIT_CONFIRMATION:
        'Few users are not assigned to any group/role. We recommend inviting the users after assigning to group/role',
    SEND_QUES:
        'We recommend you send questions every work day OR alternate work days OR one work day per week',
    SERACH: 'Search',
    SETTINGS: 'Settings',
    SHORT_CODE: 'Short Code',
    SHOW_ASSIGNED_GROUPS: 'Show assigned groups',
    SHOW_ASSIGNED_ITEMS: 'Show assigned items',
    SHOW_ASSIGNED_ROLES: 'Show assigned roles',
    SHOW_ASSIGNED_USERS: 'Show assigned users',
    SHOW_ASSIGNED_QUIZZES: 'Show assigned quizzes',
    SHOW_GRP: 'Show Group',
    SHOW_LB_TO_USR: 'Show this leaderboard to users',
    SHOW_PREW: 'Show question preview',
    SHOW_SUBMTTD_USRS: 'Show submitted users',
    SHUFFLE_CHOICES: 'Shuffle Choices',
    SHUFFLE_QUESTIONS: 'Shuffle Questions',
    SINGLE_USR_INVIT_CONFIRMATION:
        'This user is not assigned to any group/role. We recommend inviting the user after assigning to group/role',
    SSO: 'SSO',
    START_TIME: 'Start time',
    STATUS: 'Status',
    STRUCTURE: 'Structure',
    SUCS: 'success',
    SEQ_STATUS: 'Learning Path Status',
    SUBMIT: 'Submit',
    SUBMITTED: 'Submitted',
    SUB_BY: 'Submitted by',
    SUB_FEEDBACK: 'Submit Feedback',
    SUB_TYPE: 'Submit type',
    SUBSCRIBE: 'Subscribe',
    SUBSCR_AT: 'subscribed At',
    SUBSCR_STATUS: 'subscription status',
    SUBSCR_USR: 'Subscription User',
    SUBSCR: 'Subscriptions',
    SUBSCR_CRE_SUCS: 'Subscription created successfully',
    SUBSCR_DEL_SUCS: 'Subscription deleted successfully',
    SUBSCR_ALLOWED_EMAIL_DOMAIN: `Use comma (,) or press enter to create new tags. Each tag can contain maximum of 50
    characters. For example (gmail.com, linkstreet.in).`,
    SUBSCR_ALLOWED_MOBIL_DOMAIN: `Use comma (,) or press enter to create new tags. Each tag can contain maximum of 5
    characters. For example (+91, +44, +1).`,
    SUBSCR_STATUS_UPDATE: 'Subscription status updated',
    SUBSCR_UPDATED_SUCS: 'subscription updated successfully',
    SUBSCRIBED_USERS: 'Subscribed Users',
    SUPPORTED_LANG: 'Supported languages',
    SURE_TO_INVIT: 'Are you sure you want to Invite?',
    SURVEY: 'Survey',
    SURVEY_INACT: 'Survey Inactive',
    SURVEY_INACT_SUCS: 'Survey Inactivated successfully',
    SURVEY_PRE: 'Survey Preview',
    SURVEY_GRPS: 'Survey Groups',
    SURVEY_ROLS: 'Survey Roles',
    SURVEYS: 'Surveys',
    SURVEY_CRE_SUCS: 'Survey created successfully',
    SURVEY_DEL_SUCS: 'Survey deleted successfully',
    SURVEY_UPDATED_LIVE_SUCCESS: 'Survey updated & Live successfully.',
    SURVEY_UPDATED_SUCCESS: 'Survey updated successfully',
    SURVEY_LANGUAGE: 'Survey Language',
    SURVEY_THEME: 'Survey Theme',
    SUSPEND_REPORT: 'Suspend Report',
    SUSPEND: 'Suspend',
    SYS: 'System',
    TAGS: 'Tag',
    TEAM: 'Team',
    TEAM_BY: 'Team by',
    TEAM_BY_GRP: 'Team by Groups',
    TEAM_BY_ROL: 'Team by Roles',
    TEAM_RANK: 'Team Rank',
    TEMPLATE: 'Template',
    TEMPLATES: 'Templates',
    TEMPLATE_ARCHIVE_SUCC: 'Template archive successfully',
    TEMP_BKM_SUCS: 'Template bookmarked successfully',
    TEMP_CRE_SUCS: 'Template created successfully',
    TEMP_UPD_SUCS: 'Template updated successfully',
    TEMPLATE_DEF_SUCC: 'Default template updated successfully',
    TEMPLATE_DEL_SUCC: 'Template deleted successfully',
    TEMPLATE_FINALIZE_SUCC: 'Template finalized successfully',
    TEMPLATE_RESET_SUCC: 'Default template reset successfully',
    TEST_PRE: 'Test Preview',
    TEST_HIDE_RESULTS_CERT_WARNING:
        'Test results are hidden; however, certificates will be issued upon passing the test.',
    TESTS: 'Tests',
    TEST_ASG_SUCS: 'Test assigned successfully',
    TEST_INACT: 'Test Inactive',
    TEST_INACTIVATED_SUCCESSFULLY: 'Test Inactivated successfully',
    TEST_LANGUAGE: 'Test Language',
    TEST_CRE_SUCS: 'Test created successfully',
    TEST_DEL_SUCS: 'Test deleted successfully',
    TEST_GRPS: 'Test Groups',
    TEST_MAX_ATTEMPTS: 'Test Max Attempts',
    TEST_PASS_CRITE: 'Test Pass Criteria',
    TEST_PASS_CRITERIA_INFO: 'Number of correct answer required to pass the test.',
    TEST_TAG_WARNING: 'Test should have at least one tag',
    TEST_TIME_LIMIT: 'Test time limit',
    TEST_TIME_LIMIT_RANGE_IN_MIN: 'Test duration should be in range of 1 to 180 minutes.',
    TEST_TIME_LIMIT_INFO: 'Duration of test in minutes.',
    TEST_UPD_SUCS: 'Test updated successfully.',
    TEST_UPD_LIVE_SUCS: 'Test updated & Live successfully.',
    TEST_REM_SUCS: 'Test removed successfully',
    THEME: 'Theme',
    TIME_PERIOD: 'Time Period',
    TITLE: 'Title',
    TITLE_DIS_SPECIAL_CHAR_ERR:
        'Title or description cannot start with special characters like @, + or =',
    TIME_LIMIT: 'Time Limit',
    TO_CRE_NEW_LEARN_PATH:
        'To create a new learning path complete the fields on the right panel and click "Save"',
    TO_CRE_NEW_PATH: 'To create a new path complete the fields on the right panel and click "Save"',
    TO_CRE_NEW_QUES:
        'To create a question complete the fields on the right panel and click "Add Question"',
    TOP: 'top',
    TOPIC_STATUS: 'Quiz Status',
    TOPC_ASG_SUCS: 'Quiz assigned successfully',
    TOPC_CRE_SUCS: 'Quiz created successfully',
    TOPC_DEL_SUCS: 'Quiz deleted successfully',
    TOPC_DEL_TEST: 'Test deleted successfully',
    TOPC_GRPS: 'Topic Groups',
    TOPC_INACT: 'Quiz Inactive',
    TOPC_INACT_SUCS: 'Quiz Inactivated successfully',
    TOPC_REM_SUCS: 'Quiz removed successfully',
    TOPC_UPD_LIVE_SUCS: 'Quiz updated & Live successfully.',
    TOPC_UPD_SUCS: 'Quiz updated successfully.',
    TOPCS: 'Topics',
    TO_SEL_GRP: 'To select Groups',
    TO_SEL_ROL: 'To select Roles',
    TOT_NO_GRPS_TEST_ACCESS: 'Total number of users who have accessed the test.',
    TOTAL_QUESTIONS: 'Total questions',
    TOTL_USRS_CNT_INF:
        'Total number of users who have activated their account in the group, \n maybe less than total users invited to the group.',
    TYPE: 'Type',
    TXT_IMG: 'Text & Image',
    TXT_ONLY: 'Text Only',
    USAGE_ALERT_HEADING: 'Usage alert email',
    USR_ASG_QUIZ_STATUS: 'User Assigned Quiz Status',
    USR_ASG_TEST_STATUS: 'User Assigned test Status',
    UNASG: 'Unassign',
    UNASSIGN_GROUP: 'Unassign Group',
    UNASSIGN_ROLE: 'Unassign Role',
    UNASSIGN_FROM_HIERARCHY: 'Unassign from hierarchy',
    UNASSIGN_GRP_SUCS: 'Group unassinged successfully',
    UNASSIGN_ROLE_SUCS: 'Role unassinged successfully',
    UNASSIGN_USER_SUCS: 'User unassinged successfully',
    UNASG_CERTIFICATE: 'Un-assign Certificate Template',
    UNSUBMITTED: 'Unsubmitted',
    UNSUBSCRIBE: 'UnSubscribe',
    UNLOCK_USR: 'Unlock User',
    UNSUPPORTED_IMAGE_FORMAT:
        'Unsupported file format. Please note that only image type gif, jpeg, jpg, and png file formats are supported.',
    UPD: 'UPDATE',
    UPD_LIVE: 'UPDATE & LIVE',
    UPLOAD: 'Upload',
    URL: 'Url',
    USR_ASG_POLL_STATUS: 'User assigned poll status',
    USR_ASG_SEQ_STATUS: 'User assigned learning Path Status',
    USR_ASG_PATH_STATUS: 'User assigned Path Status',
    USR_ASG_SUCS: 'User assigned successfully',
    USR_ASG_SURVEY_STATUS: 'User assigned survey Status',
    USR_ASSIGN_DEL_SUCC: 'User assignments deleted successfully',
    USR_CRE_SUCS: 'User created successfully',
    USR_CRE_INVI_SUCS: 'User created and invited successfully',
    USR_DEL_SUCS: 'User deleted successfully',
    USR_ASSIGNM_LISTING: 'User Assigned Assignment Status.',
    USR_GET_FILE: 'This is the date from which learners will get access to File.',
    USR_GET_POLL: 'This is the date from which learners will get access to poll.',
    USR_GET_QUES: 'This is the date from which learners will get access to questions.',
    PARENT_OPTIONS: 'Parent Options',
    PATH_VALID_FROM_DATE: 'This is the date from which learners will get access to path contents.',
    PATH_VALID_UNTIL_DATE:
        'This is the date until which learners will get access to path contents.',
    PATH_VALID_FROM_TIME: 'This is the time from which learners will get access to path contents.',
    PATH_VALID_UNTIL_TIME:
        'This is the time until which learners will get access to path contents.',
    USR_GET_ASSIGNM: 'This is the date from which learners will get access to assignment.',
    USR_GRPS: 'User Groups',
    USR_RESTORE: 'User restored successfully',
    USR_ROL: 'User Roles',
    USR_INVI_SUCS: 'User invited successfully',
    USR_REM_SUCS: 'User removed successfully',
    USR_UPD_SUCS: 'User updated successfully',
    USR_UPD_INVI_SUCS: 'User updated and invited successfully',
    USR: 'User',
    USR_ASSIGN_STATUS: 'User Assignment Status',
    USR_NOT_GET_CONTENT: 'This is the date from which learners will not get any content',
    USR_WILL_GET_CONTENT: 'This is the date after when learners will get assigned content',
    USR_LP_STATUS: 'User Learning Path Status',
    USR_PATH_STATUS: 'User Path Status',
    USR_POLL_STATUS: 'User Poll Status',
    USR_SUR_STATUS: 'User Survey Status',
    USR_SUBMISSION: 'User Submissions',
    USR_UNLOCK_SUCC: 'User unlocked successfully',
    USRS: 'Users',
    VACATION_CANCEL: 'Vacation cancelled successfully',
    VAL_FRM: 'Valid From',
    VAL_FRM_TIME: 'This is the time from which learners will get access to questions',
    VAL_FRM_TIME_LABEL: 'Valid From Time',
    VAL_UTLL: 'Valid Until',
    VAL_UTLL_TIME: 'This is the time until which learners will get access to questions',
    VAL_UTLL_TIME_LABEL: 'Valid End Time',
    VAL_UTLL_SUB: 'This is the time until which learners will get access to subscription',
    SURVEY_VALID_PERIOD:
        'Ensure the validity period gives learners enough time to answer all questions.',
    VALI_PEROD_ATEMPT_ALL_POLL:
        'Make sure the validity period allows learners to attempt poll by given the parameter',
    VALI_PEROD_ATEMPT_ALL_QUES:
        'Make sure the validity period allows learners to attempt all questions at least twice given the above parameters',
    VALI_PEROD_ATEMPT_TEST_ALL_QUES:
        'This is the date until which learners will get access to questions.',
    VALI_PEROD_SUB_ASSIGNM:
        'This is the date until which learners will get access to submit assignment',
    VALI_PEROD_ATEMPT_SUBSCRIBE:
        'Make sure the validity period allows learners to subscribe at least twice given the above parameters',
    VALI_PEROD_VIEW_FILE: 'This is the date until which learners will get access to file',
    VALIDITY_EXPIRED: 'validity_expired',
    VIEW: 'View',
    VIEW_CERTIFICATE: 'View certificate',
    VIEW_QUES: 'View Question',
    VIEW_REPORT: 'View Report',
    VIEW_REPORT_POLL: 'View report for this poll',
    VIEW_FILE: 'View file',
    VIEW_FILES: 'View files',
    VIEW_SUB_GRP: 'View sub groups',
    VIEW_SETTING: 'View setting',
    VIEW_TEMPLATE: 'View Template',
    WANT_TO_INVITE_ALL_USERS:
        'Are you sure you want to send invite EMAIL/ SMS to all users who are yet to activate their account?',
    WARN: 'WARNING',
    WARN_CONFIRM_CANCEL: 'warning-confirm-cancel',
    YOU: 'You',
    ZER_QUES_LEFT: '0 Question left',
    TEST_INFO_NEW: 'Add a Title, Description and Tags.',
    TEST_INFO_DRAFT: 'Update the Test Title, Description and Tags.',
    TEST_INFO_LIVE: 'Update the Test Title, Description and Tags.',
    TEST_INFO_INACTIVE: 'The test is Inactive. The below fields cannot be updated.',
    CONFIG_DRAFT: 'Configure Maximum Attempts, Pass Criteria and Duration for the Test.',
    CONFIG_LIVE: 'The test is Live. This field cannot be updated.',
    RESULT_SET_DRAFT: 'Select how to show results once the test is attempted.',
    RESULT_SET_LIVE: 'Select how to show results to the user once the test is attempted.',
    POINT_SET_DRAFT: 'Select if points are to be awarded upon passing the Test.',
    POINT_SET_LIVE: 'The test is Live. This field cannot be updated.',
    SHUFFLE_SET_DRAFT: 'Select shuffling between Questions, Choices or both.',
    SHUFFLE_SET_LIVE: 'The test is Live. This field cannot be updated.',
    VALIDITY_DRAFT: 'Add Test start and End Date and Time.',
    VALIDITY_LIVE: 'The test is Live. The "From Validity" field cannot be updated.',
    MANAGE_QUESTIONS:
        'Add or Remove Test Questions here. Once the test is live, Addition and deletion of questions is restricted.',

    MANAGE_QUESTIONS_QUIZ:
        'Add or Remove Quiz Questions here. Once the Quiz is live, Addition and deletion of questions is restricted.',

    SURVEY_MANAGE_QUESTIONS:
        'Add or Remove Survey Questions here. Once the survey is live, Addition and deletion of questions is restricted.',
    IMPORT_TEST_LIST:
        'Select from the list of tests that are either live or in draft mode from which you would like to import questions.',
    IMPORT_QUIZ_LIST:
        'Select from the list of quizzes that are either live or in draft mode from which you would like to import questions.',
    IMPORT_TEST_QUEST_LIST: 'Select the questions from this test to be imported to the new test.',
    IMPORT_QUIZ_QUEST_LIST: 'Select the questions from this quiz to be imported to the new quiz.',
    ASSIGNED_FILE:
        'Add a reference file to the Test. This can be viewed before attempting the Test.',
    ASSIGNED_FILE_QUIZ:
        'Add a reference file to the Quiz. This can be viewed before attempting the Quiz.',
    FILES_LIST: 'Select the appropriate file from the list of files to assign to the Test.',
    FILES_LIST_QUIZ: 'Select the appropriate file from the list of files to assign to the Quiz.',
    ASSIGNED_CERTIFICATE_TEMP: 'Select the template to assign a Certificate  to the Test.',
    ASSIGNED_CERTIFICATE_TEMP_PATH: 'Select the template to assign a Certificate  to the Path.',
    ASSIGNED_CERTIFICATE_TEMP_QUIZ: 'Select the template to assign a Certificate  to the Quiz.',
    CERTIFICATE_LIST: 'Select the Certificate from the list of Live Certificates.',
    ISSUED_CERTIFICATE: 'View user details of the Certificates released on  completion.',
    MAKE_LIVE_NOTE: 'NOTE: Once the test is live, fields will no longer be editable.',
    ASSIGNED_GROUP: 'View details of the Group to which the Test is assigned.',
    FORM_ASSIGNED_GROUP: 'View details of the Group to which the Form is assigned.',
    SURVEY_ASSIGNED_GROUP: 'View details of the Group to which the survey is assigned.',
    ASSIGNM_ASSIGNED_GROUP: 'View details of the Group to which the Assignment is assigned.',
    LB_ASSIGNED_GROUP: 'View details of the Group to which the leaderboard is assigned.',
    LB_ASSIGNED_ROLE: 'View details of the Role to which the leaderboard is assigned.',
    SELECT_GROUP: 'Click Assign Group to provide the Test to specific Group.',
    ASSIGNED_ROLE: 'View details of the Role to which the Test is assigned.',
    ASSIGNM_ASSIGNED_ROLE: 'View details of the Role to which the Assignment is assigned.',
    FORM_ASSIGNED_ROLE: 'View details of the Role to which the Form is assigned.',
    SURVEY_ASSIGNED_ROLE: 'View details of the Role to which the survey is assigned.',
    SELECT_ROLE: 'Click Assign Role to provide the Test to specific Role.',
};
