<div class="modal-header">
  <div class="col-10">
    <h5 class="modal-title w-100 rapl-semi-bold">{{ data.pdfTitle }}</h5>
  </div>
  <div class="col-2">
    <button type="button" class="btn-close close float-end position-relative modal-close-btn" mat-dialog-close>
      <span aria-hidden="true"><img alt="" src="assets/img/close.svg" width="16" height="16" /></span>
    </button>
  </div>
</div>
<mat-dialog-content class="mat-dialog-content-outer">
  <div class="pdf-viewer">
    <img alt="loader" class="loader-image text-center" draggable="false" />
    @if (pdfError) {
      <div class="image-error-screen-content text-center">
        <div class="image-error-screen-title">Could not view pdf.</div>
        <div class="image-error-screen-message">There was a problem trying to load the pdf.</div>
        <div class="image-error-screen-reload">
          <svg fill="#096eef" (click)="retryPdf()" class="cursor-pointer mt-3" viewBox="0 0 24 24" style="width: 32px" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>
    }
    @if (pdfLoaded) {
      <ng2-pdfjs-viewer
        [zoom]="'page-fit'"
        [pdfSrc]="selectedPdfUrl"
        [download]="data.isDownloadable"
        [print]="false"
        [downloadFileName]="data.pdfTitle"
        [openFile]="false"
        [page]="1"
        [showSpinner]="true"
        [viewBookmark]="false">
      </ng2-pdfjs-viewer>
    }
  </div>
</mat-dialog-content>
