@if (active) {
  <div [ngClass]="{ 'upload-spinner': active }">
    <div class="uploading">
      <img alt="" src="assets/img/uploading.svg" />
      <h4 class="uploading-text">
        @if (uploadSpinner.text === 'UPLOADING') {
          <div class="sentence-case" [innerHtml]="!isManageActive() ? ('LEARNER.UPLOADING' | translate) : 'UPLOADING'"></div>
        }
        @if (uploadSpinner.text === 'SUBMITTING') {
          <div class="sentence-case" [innerHtml]="!isManageActive() ? ('LEARNER.SUBMITTING' | translate) : 'SUBMITTING'"></div>
        }
        <div class="processing"><span>.</span><span>.</span><span>.</span></div>
      </h4>
    </div>
  </div>
}
