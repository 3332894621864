import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackQuestionModalComponent } from 'app/feedback-question-modal/feedback-question-modal.component';
import { ApiService, LogEventService, Util } from 'app/shared';
import { LogEventName } from 'app/shared/enum/log-event-name';
import { PdfViewerComponent } from 'app/shared/pdf-viewer/pdf-viewer.component';
import { DataService } from 'app/shared/service/data.service';
import { SnackbarService } from 'app/shared/service/snack-bar.service';
import { AppHeaderService } from './app-header.service';
import { LearnerUtil } from 'app/user_app/learner-util';

@Injectable()
export class NotificationScenarioService {
    appLeaderBoardApiUrl = '/app/myScore';
    logEventName: any = LogEventName;
    constructor(
        public router: Router,
        public api: ApiService,
        public appHeaderService: AppHeaderService,
        public dataService: DataService,
        public datePipe: DatePipe,
        public snackbarService: SnackbarService,
        public logEventService: LogEventService,
        public translateService: TranslateService,
        public modalService: NgbModal,
        public dialog: MatDialog,
    ) {}

    navigateFromNotification(notif) {
        switch (notif.scenario) {
            case 'NEW_BADGE': {
                this.newBadge(notif);
                break;
            }
            case 'TOP_LEARNER': {
                this.topLearner(notif);
                break;
            }
            case 'TOP_LEARNER_GROUP':
            case 'TOP_LEARNER_ROLE': {
                this.topLearnerLb(notif);
                break;
            }
            case 'ACTIVATION_ALERT':
            case 'USAGE_ALERT': {
                if (Util.isOnline()) {
                    this.router.navigate(['/monitor/dashboard']);
                } else {
                    this.snackbarService.offline();
                }
                break;
            }
            case 'OPINION_REMINDER': {
                this.pollRemainder(notif);
                break;
            }
            case 'NEW_OPINION': {
                this.newPoll(notif);
                break;
            }
            case 'UNSEEN_FILES': {
                if (Util.isOnline()) {
                    const params = {};
                    params['filter[bucket_user_correlation.not_viewed]'] = 'true';
                    this.setLibraryParams(params);
                    this.router.navigate(['/app/library']);
                } else {
                    this.snackbarService.offline();
                }
                break;
            }
            case 'LEARNER_RANK': {
                this.redirectUserLeaderboard(notif);
                break;
            }
            case 'NEW_TEST':
            case 'RETAKE_TEST':
            case 'TEST_UNLOCKED': {
                this.newTest(notif);
                break;
            }
            case 'TEST_REMINDER': {
                this.testReminder();
                break;
            }
            case 'NEW_CERTIFICATE': {
                this.newCertificate(notif.payload.certificate_id);
                break;
            }
            case 'TEST_ANSWERS_REVEALED': {
                this.testAnswer(notif);
                break;
            }
            case 'ASSIGNMENT_RESUBMISSION_REQUEST':
            case 'NEW_ASSIGNMENT': {
                this.newAssignment(notif);
                break;
            }
            case 'ASSIGNMENT_GRADED': {
                this.goToHistoryAssignment(notif);
                break;
            }
            case 'FEEDBACK_DISAGREED': {
                this.goToAdminFeedbackResponse(notif);
                break;
            }
            case 'FEEDBACK_SUBMITTED': {
                this.goToFeedbackResponse(notif);
                break;
            }
            case 'TOPIC_PENDING_PRELIM': {
                this.goToLibrary(notif);
                break;
            }
            case 'TEST_PENDING_PRELIM': {
                this.goToLibrary(notif);
                break;
            }
            case 'REVIEW_WORKFLOW_ASSIGNED':
            case 'REVIEW_WORKFLOW_DATE_EXTENDED':
            case 'REVIEW_WORKFLOW_GOAL_APPROVED':
            case 'REVIEW_WORKFLOW_GOAL_REVISE':
            case 'REVIEW_WORKFLOW_REMARK_REVISE':
            case 'REVIEW_WORKFLOW_REMARK_APPROVED': {
                this.goToUserReviewWorkflow(notif);
                break;
            }
            case 'REVIEW_WORKFLOW_GOAL_SUBMITTED':
            case 'REVIEW_WORKFLOW_GOAL_REVISION_SUBMITTED':
            case 'REVIEW_WORKFLOW_REMARK_SUBMITTED':
            case 'REVIEW_WORKFLOW_REMARK_REVISION_SUBMITTED': {
                this.goToTeamReviewWorkflow(notif);
                break;
            }
            case 'NEW_FILE': {
                this.newFile(notif);
                break;
            }
            case 'FORM_RELEASED': {
                this.newForm(notif);
                break;
            }
            case 'FORM_REMINDER': {
                this.newForm(notif);
                break;
            }
            case 'EVENT_REMINDER': {
                this.goToEventDescription(notif);
                break;
            }
            default: {
                this.masteredQuiz(notif);
                break;
            }
        }
    }

    setLibraryParams(params) {
        sessionStorage.setItem('filterComponent', 'library');
        sessionStorage.setItem('filterParams', JSON.stringify(params));
    }

    goToLibrary(notif) {
        const contentParams = {};
        if (notif.payload.topic_id) {
            contentParams['filter[topics.topic_ids]'] = notif.payload.topic_id;
        } else {
            contentParams['filter[assessments.assessment_ids]'] = notif.payload.assessment_id;
        }
        const options = { show_success: false, show_loader: false, search: contentParams };
        this.api.get('buckets', options).subscribe({
            next: (data: any) => {
                const params = {};
                if (notif.payload.topic_id) {
                    params['filter[topics.topic_ids]'] = notif.payload.topic_id;
                } else {
                    params['filter[assessments.assessment_ids]'] = notif.payload.assessment_id;
                }
                params['competence'] = false;
                sessionStorage.setItem('openFile', JSON.stringify(params));
                if (data.list.length > 0) {
                    this.router.navigate([`/app/openfile/${data.list[0].f_id}`]);
                } else {
                    this.snackbarService.error(
                        this.translateService.instant('LEARNER.FILE_NOT_FOUND'),
                    );
                }
            },
            error: (err: any) => {
                if (err.status === 0) {
                    console.log(err);
                }
            },
        });
    }

    newBadge(notif) {
        if (notif.payload.badge_id) {
            this.goTobadge(notif);
        }
    }
    topLearner(notif) {
        if (notif.payload.lb_id) {
            this.redirectUserLeaderboard(notif);
        }
    }
    topLearnerLb(notif) {
        if (notif.payload.master_id && notif.payload.lb_id) {
            this.topLearnerGrpAndRole(notif);
        }
    }
    newPoll(notif) {
        if (notif.payload?.insight_id) {
            this.goToNewPoll(notif);
        }
    }
    newTest(notif) {
        if (notif.payload.assessment_id) {
            this.redirectTakeOrReTakeTest(notif);
        }
    }
    testAnswer(notif) {
        if (notif.payload.assessment_id) {
            this.revealTestAnswer(notif);
        }
    }
    private handleAssignment(notif: any) {
        if (notif.payload.assignment_id) {
            this.goToAssignment(notif);
        }
    }
    newAssignment(notif) {
        this.handleAssignment(notif);
    }
    historyAssignment(notif) {
        this.handleAssignment(notif);
    }
    masteredQuiz(notif) {
        if (notif.payload?.topic_id) {
            this.masteredTopic(notif);
        }
    }
    handleApiErr(err) {
        if (err.status === 0) {
            this.snackbarService.offline();
        }
        console.log(err);
    }
    masteredTopic(notif) {
        const topicId = notif.payload.topic_id;
        if (topicId) {
            if (notif.scenario === 'MASTERED_TOPIC') {
                localStorage.setItem('notification_mastered_topic', JSON.stringify(notif));
                if (Util.isOnline()) {
                    this.router.navigate(['/app/history/quiz', topicId]);
                } else {
                    this.snackbarService.offline();
                }
                
            } else if (
                notif.scenario === 'NEW_QUESTION' ||
                notif.scenario === 'ORANGE_ALERT' ||
                notif.scenario === 'RED_ALERT' ||
                notif.scenario === 'WELL_DONE'
            ) {
                this.api.get(`topics/perceive/${topicId}`).subscribe({
                    next: (data: any) => {
                        if (notif.scenario === 'RED_ALERT') {
                            this.logEventService.logEvent(
                                this.logEventName.QUIZ_RED_ALERT_NOTIFICATION_CLICKED,
                                {},
                            );
                        } else {
                            this.logEventService.logEvent(
                                this.logEventName.QUIZ_NEW_SCENARIO_NOTIFICATION_CLICKED,
                                {},
                            );
                        }
                        const topic = data;
                        topic.metaData = Util.metaData(topic.translations);
                        localStorage.setItem('current_topic', JSON.stringify(topic));
                        if (Util.isOnline()) {
                            this.router.navigate(['/app/takequiz', topicId]);
                        } else {
                            this.snackbarService.offline();
                        }
                    },
                    error: (err: any) => {
                        this.handleApiErr(err);
                    },
                });
            }
        }
    }
    pollRemainder(notif: any) {
        if (Util.isOnline()) {
            if (notif?.payload?.survey_type === 'SURVEY') {
                this.router.navigate(['/app/takesurvey', notif.payload.insight_id]);
            } else {
                this.router.navigate(['/app/takepoll', notif.payload.insight_id]);
            }
        } else {
            this.snackbarService.offline();
        }
    }
    goToNewPoll(notif) {
        const insightId = notif.payload.insight_id;
        if (insightId) {
            this.api.get(`opinions/${insightId}`).subscribe({
                next: (data: any) => {
                    data.metaData = Util.metaData(data.translations);
                    if (Util.isOnline()) {
                        if (data.type === 'POLL') {
                            this.router.navigate(['/app/takepoll', data.insight_id]);
                        } else {
                            this.router.navigate(['/app/takesurvey', data.insight_id]);
                        }
                    } else {
                        this.snackbarService.offline();
                    }
                },
                error: (err: any) => {
                    this.handleApiErr(err);
                },
            });
        }
    }
    goToAssignment(assignment: any) {
        if (Util.isOnline()) {
            this.router.navigate(['/app/takeassignment', assignment.payload.assignment_id]);
        } else {
            this.snackbarService.offline();
        }
    }
    goToHistoryAssignment(assignment: any) {
        if (assignment.payload.assignment_id) {
            if (Util.isOnline()) {
                this.router.navigate(['/app/history/assignment', assignment.payload.assignment_id]);
            } else {
                this.snackbarService.offline();
            }
        }
    }
    goToFeedbackResponse(feedback: any) {
        if (Util.isOnline()) {
            if (!LearnerUtil.isMobileView()) {
                const updatedParams = { fid: `${feedback.payload.feedback_id}` };
                this.router.navigate(['app/myAccount/feedback'], {
                    queryParams: updatedParams,
                });
            } else {
                this.router.navigate(['app/feedback', feedback.payload.feedback_id]);
            }
        } else {
            this.snackbarService.offline();
        }
    }
    goToNewFile(notif) {
        const fileId = notif.payload.fid;
        if (fileId) {
            if (Util.isOnline()) {
                this.router.navigate(['/app/openfile', fileId]);
            } else {
                this.snackbarService.offline();
            }
        }
    }
    testReminder() {
        this.api.get('assessments').subscribe({
            next: (data: any) => {
                if (data.list.length > 0) {
                    for (const test of data.list) {
                        test.metaData = Util.metaData(test.translations);
                    }
                    if (Util.isOnline()) {
                        this.router.navigate([
                            '/app/taketest/instructions',
                            data.list[0].assessment_id,
                        ]);
                    } else {
                        this.snackbarService.offline();
                    }
                } else {
                    this.snackbarService.error('You’re up to date. You have completed all tests!');
                }
            },
            error: (err: any) => {
                this.handleApiErr(err);
            },
        });
    }
    newCertificate(certificateId) {
        if (Util.isOnline()) {
            this.api
                .get(`certificates/${certificateId}`, { show_success: false, show_loader: true })
                .subscribe({
                    next: (data: any) => {
                        this.dialog.open(PdfViewerComponent, {
                            panelClass: 'pdf-viewer-outer',
                            width: 'inherit',
                            disableClose: true,
                            data: {
                                pdfTitle: data.name,
                                pdfSource: data.download.pdf,
                                isDownloadable: true,
                            },
                        });
                    },
                    error: (err: any) => {
                        console.log(err);
                    },
                });
        } else {
            this.snackbarService.offline();
        }
    }
    goTobadge(notif) {
        this.logEventService.logEvent(this.logEventName.BADGE_NEW_RELEASE_NOTIFICATION_CLICKED, {});
        this.dataService.setSharedData('badge_id', notif.payload.badge_id);
        if (Util.isOnline()) {
            const width = window.innerWidth;
            const fragment = 'badgesInsights';
            this.router.navigate(['/app/learnerInsight'], {
                queryParams: { id: notif.payload.badge_id },
                fragment: fragment,
            });
        } else {
            this.snackbarService.offline();
        }
    }
    revealTestAnswer(notif) {
        this.api.get(`assessments/history/${notif.payload.assessment_id}`).subscribe({
            next: (data: any) => {
                data.metaData = Util.metaData(data.translations);
                localStorage.setItem('testResponse', JSON.stringify(data));
                if (Util.isOnline()) {
                    this.router.navigate(['/app/history/test/response']);
                } else {
                    this.snackbarService.offline();
                }
            },
            error: (err: any) => {
                this.handleApiErr(err);
            },
        });
    }
    topLearnerGrpAndRole(notif) {
        localStorage.setItem('myScoreTab', '1');
        const notifIndividualLeaderboard = {
            masterId: notif.payload.master_id,
            lbId: notif.payload.lb_id,
        };
        this.logEventService.logEvent(this.logEventName.TEAM_LB_WINNER_NOTIFICATION_CLICKED, {});
        localStorage.setItem('teamLeaderboard', JSON.stringify(notifIndividualLeaderboard));
        if (Util.isOnline()) {
            this.router.navigate([this.appLeaderBoardApiUrl], {
                queryParams: { path: 'notify' },
            });
        } else {
            this.snackbarService.offline();
        }
    }
    redirectUserLeaderboard(notif) {
        localStorage.setItem('myScoreTab', '0');
        this.logEventService.logEvent(this.logEventName.USER_LB_WINNER_NOTIFICATION_CLICKED, {});
        const notifIndividualLeaderboard = {
            masterId: notif.payload.master_id,
            lbId: notif.payload.lb_id,
        };
        localStorage.setItem('individualLeaderboard', JSON.stringify(notifIndividualLeaderboard));
        if (Util.isOnline()) {
            this.router.navigate([this.appLeaderBoardApiUrl], {
                queryParams: { path: 'notify' },
            });
        } else {
            this.snackbarService.offline();
        }
    }
    redirectTakeOrReTakeTest(notif) {
        this.router.navigate(['/app/taketest/instructions', notif.payload.assessment_id]);
    }

    goToAdminFeedbackResponse(notif: any) {
        if (Util.isOnline()) {
            const modalOptions: any = {
                windowClass: 'animate__animated animate__pulse feedback-question-modal',
                backdrop: 'static',
                keyboard: false,
            };

            if (notif.payload.feedback_id && notif.payload.user_id) {
                const modalRef = this.modalService.open(
                    FeedbackQuestionModalComponent,
                    modalOptions,
                );
                this.openFeedbackQuestionModal(modalRef);
                modalRef.componentInstance.modalType = 'notifyFeedbackResponse';
                modalRef.componentInstance.selectedFeedbackId = notif.payload.feedback_id;
                modalRef.componentInstance.selectedUserId = notif.payload.user_id;
            }
        } else {
            this.snackbarService.offline();
        }
    }

    openFeedbackQuestionModal(modalRef) {
        modalRef.result.then((result) => {
            if (result) {
                Util.privilegeBasedModule(this.router);
            }
        });
    }

    goToUserReviewWorkflow(notif) {
        localStorage.removeItem('selectedReviewYear');
        if (notif.payload.workflow_type === 'GOAL_SETTING') {
            this.router.navigate([
                '/review/takeUserReview/goalSetting',
                notif.payload.period_id,
                notif.payload.workflow_id,
            ]);
        } else if (notif.payload.workflow_type === 'PERIODIC') {
            this.router.navigate([
                '/review/takeUserReview/periodic',
                notif.payload.period_id,
                notif.payload.workflow_id,
            ]);
        } else if (notif.payload.workflow_type === 'FINAL') {
            this.router.navigate([
                '/review/takeUserReview/fullyear',
                notif.payload.period_id,
                notif.payload.workflow_id,
            ]);
        }
    }

    goToTeamReviewWorkflow(notif) {
        localStorage.removeItem('selectedReviewYear');
        localStorage.removeItem('selectedEmployee');
        this.api.get(`admin/reviews/employees/${notif.payload.user_id}`).subscribe({
            next: (data: any) => {
                localStorage.setItem('selectedEmployee', JSON.stringify(data));
                if (notif.payload.workflow_type === 'GOAL_SETTING') {
                    this.router.navigate([
                        '/review/takeTeamReview/goalSetting',
                        notif.payload.user_id,
                        notif.payload.period_id,
                        notif.payload.workflow_id,
                    ]);
                } else if (notif.payload.workflow_type === 'PERIODIC') {
                    this.router.navigate([
                        '/review/takeTeamReview/periodic',
                        notif.payload.user_id,
                        notif.payload.period_id,
                        notif.payload.workflow_id,
                    ]);
                } else if (notif.payload.workflow_type === 'FINAL') {
                    this.router.navigate([
                        '/review/takeTeamReview/fullyear',
                        notif.payload.user_id,
                        notif.payload.period_id,
                        notif.payload.workflow_id,
                    ]);
                }
            },
            error: (err: any) => {
                console.log(err);
            },
        });
    }

    newFile(notif) {
        if (notif.payload?.fid) {
            this.goToNewFile(notif);
        }
    }

    newForm(notif) {
        if (notif.payload.form_id) {
            const updatedParams = { type: 'myforms' };
            this.router.navigate(
                [`app/forms/${notif.payload.form_id}/entry/${notif.payload.form_entry_id}`],
                {
                    queryParams: updatedParams,
                },
            );
        }
    }

    goToEventDescription(notif) {
        this.router.navigate(['/app/events', notif.payload.occurrence_id]);
    }
}
