export class LeaderboardTypeBuilder {
    public type: string;
    public toppersLimit = 5;
    public winnersLimit = 3;
    public enabled = false;
    public rollup = false;
    public translations: any = [];
    constructor(type: string) {
        this.type = type;
    }
    get lbType() {
        return this.type;
    }
    setToppersLimit(value: number) {
        this.toppersLimit = value;
        return this;
    }
    get getToppersLimit() {
        return this.toppersLimit;
    }
    setWinnersLimit(value: number) {
        this.winnersLimit = value;
        return this;
    }
    get getWinnersLimit() {
        return this.winnersLimit;
    }
    setLbTypeEnabled(value: boolean) {
        this.enabled = value;
        return this;
    }
    get lbTypeEnabled() {
        return this.enabled;
    }
    setTranslations(value: any) {
        this.translations = value;
        return this;
    }
    get periodTranslations() {
        return this.translations;
    }
    build(): any {
        return {
            type: this.type,
            toppers_limit: this.toppersLimit,
            winners_limit: this.winnersLimit,
            enabled: this.setLbTypeEnabled,
            translations: this.periodTranslations,
        };
    }
}
