import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminUtil } from 'app/admin/admin-util';
import { ConfirmBoxService } from 'app/confirmbox/confirmbox.service';
import { adminConstants, ApiService, Util } from 'app/shared';
import { ConstantImage } from 'app/shared/enum/constant-image';
import { MaximumFileSize } from 'app/shared/enum/maximum-file-size';
import { SupportedFeedbackFileTypes } from 'app/shared/enum/supported-feedback-file-type';
import { SupportedImageFormat } from 'app/shared/enum/supported-image-format';
import { UploadStatus } from 'app/shared/enum/upload-status';
import { RemoteConfigService } from 'app/shared/service/remote-config.service';
import { S3UploadService } from 'app/shared/service/s3Upload.service';
import { SnackbarService } from 'app/shared/service/snack-bar.service';
import { LearnerUtil } from 'app/user_app/learner-util';

@Component({
    selector: 'app-feedback-question-modal',
    styleUrls: ['./feedback-question-modal.component.scss'],
    templateUrl: './feedback-question-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [S3UploadService],
})
export class FeedbackQuestionModalComponent implements OnInit {
    @ViewChild('feedbackTemplateForm', { static: false }) feedbackTemplateForm: NgForm;
    @Input() selectedFeedbackTemplate: any;
    @Input() modalType: any;
    @Input() selectedNodeId: any;
    @Input() selectedBundleId: any;
    @Input() selectedFeedback: any;
    @Input() selectedUser: any;
    @Input() refresh: any;
    @Input() selectedFeedbackId: any;
    @Input() selectedUserId: any;
    adminConstant: any;
    feedbackTemplateFormSubmit = false;
    orgSetting = JSON.parse(localStorage.getItem('orgSetting'));
    originalSelected: string;
    subscription: any;
    feedbackResponse: any;
    feedbackQuestion: any;
    submitFeedbackBody = {};
    mandatoryQuestionId = [];
    currentUser: any;
    s3UploadSubscription: any;
    supportedFeedbackFileTypeArray = Util.getEnumArray(SupportedFeedbackFileTypes, true);
    constantImage = ConstantImage;
    supportedImageFormats = Util.getEnumArray(SupportedImageFormat, false);
    supportedFeedbackFileTypes = SupportedFeedbackFileTypes;
    uploadStatusCheckTimer = UploadStatus.uploadStatusMilliSec;
    constructor(
        public api: ApiService,
        public activeModal: NgbActiveModal,
        public snackbarService: SnackbarService,
        public confirm: ConfirmBoxService,
        public remoteConfigService: RemoteConfigService,
        private readonly s3uploadService: S3UploadService,
    ) {}

    ngOnInit() {
        this.adminConstant = adminConstants;
        this.currentUser = JSON.parse(localStorage.getItem('current_user'));
        if (
            !this.selectedFeedbackTemplate?.template_id &&
            (this.modalType === 'nodeSubmitFeedback' || this.modalType === 'bundleSubmitFeedback')
        ) {
            this.feedbackTemplateFormSubmit = false;
        }
        this.changeFeedbackModal();
    }

    feedbackTemplateLoad(subscription) {
        if (!subscription.template_id) {
            this.feedbackTemplateFormSubmit = false;
            this.feedbackTemplateForm.resetForm();
        } else {
            this.selectedFeedbackTemplate = subscription;
        }
        this.originalSelected = JSON.stringify(this.selectedFeedbackTemplate);
    }

    changeFeedbackModal() {
        switch (this.modalType) {
            case 'nodeSubmitFeedback': {
                this.getNodeFeedbackQuestions();
                break;
            }
            case 'nodeFeedbackResponse': {
                this.getNodeFeedbackResponse();
                break;
            }
            case 'bundleSubmitFeedback': {
                this.getBundleFeedbackQuestions();
                break;
            }
            case 'bundleFeedbackResponse': {
                this.getBundleFeedbackResponse();
                break;
            }
            case 'notifyFeedbackResponse': {
                this.getNotifyFeedbackResponse();
                break;
            }
            default:
                break;
        }
    }

    apiErr(err) {
        if (err.error.code === 1401 || err.error.code === 4211) {
            this.refresh = true;
            this.activeModal.close(this.refresh);
        }
    }

    // Get node feedback questions
    getNodeFeedbackQuestions() {
        this.api
            .get(
                `admin/nodes/${this.selectedNodeId}/feedback/${this.selectedFeedback.feedback_id}/questions`,
            )
            .subscribe({
                next: (data: any) => {
                    this.feedbackQuestion = data.list;
                    this.setFeedbackQuestionType(this.feedbackQuestion);
                },
                error: (err: any) => {
                    this.apiErr(err);
                    console.log(err);
                },
            });
    }

    // Get node feedback Responses
    getNodeFeedbackResponse() {
        this.api
            .get(
                `admin/nodes/${this.selectedNodeId}/feedback/${this.selectedFeedback.feedback_id}/users/${this.selectedUser.user_id}/response`,
            )
            .subscribe({
                next: (data: any) => {
                    this.feedbackResponse = data;
                    this.feedbackQuestion = data.questions;
                    this.setFeedbackQuestionType(this.feedbackQuestion);
                },
                error: (err: any) => {
                    this.apiErr(err);
                    console.log(err);
                },
            });
    }

    // Get bundle feedback questions
    getBundleFeedbackQuestions() {
        this.api
            .get(
                `admin/bundles/${this.selectedBundleId}/feedback/${this.selectedFeedback.feedback_id}/questions`,
            )
            .subscribe({
                next: (data: any) => {
                    this.feedbackQuestion = data.list;
                    this.setFeedbackQuestionType(this.feedbackQuestion);
                },
                error: (err: any) => {
                    this.apiErr(err);
                    console.log(err);
                },
            });
    }

    // Get bundle feedback Responses
    getBundleFeedbackResponse() {
        this.api
            .get(
                `admin/bundles/${this.selectedBundleId}/feedback/${this.selectedFeedback.feedback_id}/users/${this.selectedUser.user_id}/response`,
            )
            .subscribe({
                next: (data: any) => {
                    this.feedbackResponse = data;
                    this.feedbackQuestion = data.questions;
                    this.setFeedbackQuestionType(this.feedbackQuestion);
                },
                error: (err: any) => {
                    this.apiErr(err);
                    console.log(err);
                },
            });
    }

    // Notification Feedback Response
    getNotifyFeedbackResponse() {
        this.api
            .get(`admin/feedback/${this.selectedFeedbackId}/users/${this.selectedUserId}/response`)
            .subscribe({
                next: (data: any) => {
                    this.selectedFeedback = data;
                    this.feedbackResponse = {
                        submission: data.submission,
                        response: data.response,
                    };
                    this.feedbackQuestion = data.questions;
                    this.selectedUser = data.submission.submitted_for;
                    this.setFeedbackQuestionType(this.feedbackQuestion);
                },
                error: (err: any) => {
                    if (err.error.code === 4208) {
                        this.activeModal.close(false);
                    }
                    console.log(err);
                },
            });
    }

    setFeedbackQuestionType(questions): any {
        questions.forEach((obj: any) => {
            obj.questionType = AdminUtil.getQuestionType(obj);
            if (obj.questionType === 'singleAnswer' || obj.questionType === 'textArea') {
                obj.answers = {
                    text: '',
                };
            } else if (obj.questionType === 'fileUpload') {
                obj.answers = {
                    name: '',
                    upload_id: '',
                };
            }
            if (obj.is_mandatory) {
                this.mandatoryQuestionId.push(obj.question_id);
            }
            if (
                this.modalType === 'nodeFeedbackResponse' ||
                this.modalType === 'bundleFeedbackResponse' ||
                this.modalType === 'notifyFeedbackResponse'
            ) {
                this.setQuestionAnswer(obj);
            }
        });
    }

    getFileSize(bytes: any): string {
        if (bytes < 1024) {
            return `${bytes} Bytes`;
        } else if (bytes < 1048576) {
            return `${(bytes / 1024).toFixed(2)} KB`;
        } else if (bytes < 1073741824) {
            return `${(bytes / 1048576).toFixed(2)} MB`;
        } else {
            return `${(bytes / 1073741824).toFixed()} GB`;
        }
    }

    downloadFile(data) {
        const fileFormat = data.source.slice(data.source.lastIndexOf('.') + 1).toLowerCase();
        if (!this.isSupportedFileType(data)) {
            data.file_type = fileFormat;
            LearnerUtil.downloadFile(data, this.remoteConfigService);
        } else {
            let errMsg = 'File type not supported';
            if (JSON.parse(localStorage.getItem('newVersionAvailable'))) {
                errMsg += '. Please update the app';
            }
            this.snackbarService.error(errMsg);
        }
    }

    // set Response with question to view
    setQuestionAnswer(question) {
        const response = this.feedbackResponse.response.find(
            ({ question_id }) => question_id === question.question_id,
        );
        if (response !== undefined) {
            if (
                question.questionType === 'fileUpload' ||
                question.questionType === 'singleAnswer' ||
                question.questionType === 'textArea'
            ) {
                response.answers.forEach((responseObj: any) => {
                    question.answers = responseObj;
                });
            } else if (
                question.questionType === 'singleChoice' ||
                question.questionType === 'multipleChoice'
            ) {
                response.answers.forEach((responseObj: any) => {
                    question.answers.choices.forEach((choiceObj: any) => {
                        if (choiceObj.choice_id === responseObj.choice_id) {
                            choiceObj.selected = true;
                        } else if (question.questionType === 'singleChoice') {
                            choiceObj.selected = false;
                        }
                    });
                    if (question.answers['other']) {
                        if (question.answers['other'].other_id === responseObj.other_id) {
                            question.answers['other'].selected = true;
                            question.answers['other'].text = responseObj.text;
                        } else {
                            question.answers['other'].selected = false;
                        }
                    }
                });
            } else if (question.questionType === 'multipleTextboxes') {
                response.answers.forEach((responseObj: any) => {
                    question.answers.rows.forEach((rowObj: any) => {
                        if (rowObj.row_id === responseObj.row_id) {
                            rowObj.text = responseObj.text;
                        }
                    });
                });
            }
        }
    }

    fileChangeListener($event: any, question) {
        const file: File = $event.target.files[0];
        if (file?.name) {
            const fileFormat = file.name.slice(file.name.lastIndexOf('.') + 1).toLowerCase();
            this.supportedFileType(fileFormat, file, question);
        } else {
            this.clearFileObject(question);
        }
    }

    clearFileObject(question) {
        question.answers.name = '';
        question.answers.upload_id = '';
        question.selectedFile = {};
    }

    supportedFileType(fileFormat, file, question) {
        if (this.supportedImageFormats.indexOf(fileFormat) !== -1) {
            this.setFileObject(file, question, this.supportedFeedbackFileTypes.IMAGE);
        } else if (fileFormat === 'pdf') {
            this.setFileObject(file, question, this.supportedFeedbackFileTypes.PDF);
        } else {
            this.snackbarService.errorPop('Please select file type: PDF or Image');
            this.clearFileObject(question);
        }
    }

    setFileObject(file: any, question, fileType) {
        question.selectedFile = file;
        if (this.validateFileSize(question, fileType)) {
            const s3UploadUrl = `admin/feedback/upload-url?action=${fileType}`;
            question.fileUploading = true;
            this.s3uploadService.gets3UploadUrl(s3UploadUrl, question.selectedFile);
            this.s3UploadSubscription = this.s3uploadService
                .getUploadDoneEmitter()
                .subscribe((event) => {
                    if (question.fileUploading) {
                        this.uploadStatusCheckTimer = UploadStatus.uploadStatusMilliSec;
                        this.getFileUploadS3status(event, question);
                    }
                });
            question.answers.name = file.name.split('.')[0].slice(0, 30);
        }
    }

    validateFileSize(question, fileType) {
        let fileUploadValid = false;
        if (fileType === this.supportedFeedbackFileTypes.IMAGE) {
            if (question.selectedFile.size / 1024 / 1024 < this.getMaximumFileSize('IMAGE')) {
                fileUploadValid = true;
            } else {
                this.snackbarService.error(
                    `Maximum file size allowed for image is ${this.getMaximumFileSize('IMAGE')} MB.`,
                );
                this.clearFileObject(question);
            }
        } else if (fileType === this.supportedFeedbackFileTypes.PDF) {
            if (question.selectedFile.size / 1024 / 1024 < this.getMaximumFileSize('PDF')) {
                fileUploadValid = true;
            } else {
                this.snackbarService.error(
                    `Maximum file size allowed for pdf is ${this.getMaximumFileSize('PDF')} MB.`,
                );
                this.clearFileObject(question);
            }
        } else {
            this.snackbarService.error(`Unsupported file type: ${fileType}`);
        }
        return fileUploadValid;
    }

    getMaximumFileSize(fileType) {
        let fileProperty = '';

        if (fileType === this.supportedFeedbackFileTypes.IMAGE) {
            fileProperty = 'attachment_image_max_file_size';
        } else {
            fileProperty = 'attachment_pdf_max_file_size';
        }
        const bucketProperty = Util.filterArray(
            this.currentUser.org.settings,
            'property',
            fileProperty,
        );
        if (bucketProperty !== undefined) {
            return bucketProperty.value / 1024 / 1024;
        } else {
            return parseInt(MaximumFileSize[fileType]);
        }
    }

    getFileUploadS3status(event: any, question) {
        if (event.id) {
            this.api
                .get(`admin/feedback/upload/${event.id}`, {
                    show_success: false,
                    show_loader: false,
                })
                .subscribe({
                    next: (data: any) => {
                        if (data.status === 'READY') {
                            question.fileUploading = false;
                            question.answers.upload_id = event.id;
                        } else if (data.status === 'FAILED') {
                            this.clearFileObject(question);
                            question.fileUploading = false;
                            this.snackbarService.errorPop('File upload failed. please try again');
                        } else if (data.status === 'PENDING' || data.status === 'PROCESSING') {
                            if (
                                this.uploadStatusCheckTimer <
                                UploadStatus.maxStatusCheckMinutes * 60 * 1000
                            ) {
                                this.uploadStatusCheckTimer *= UploadStatus.delayMultiple;
                                setTimeout(() => {
                                    this.getFileUploadS3status(event, question);
                                }, this.uploadStatusCheckTimer);
                            } else {
                                this.clearFileObject(question);
                                question.fileUploading = false;
                                this.snackbarService.errorPop(
                                    'File upload failed. please try again',
                                );
                            }
                        }
                    },
                    error: (err: any) => {
                        console.log(err);
                    },
                });
        } else {
            this.clearFileObject(question);
            question.fileUploading = false;
        }
    }

    singleChoiceChange(question, choiceType, selectedChoiceId) {
        if (choiceType === 'choice') {
            if (question.answers.other) {
                question.answers.other.text = '';
                question.answers.other.selected = false;
            }
            question.answers.choices.forEach((obj: any) => {
                if (obj.choice_id === selectedChoiceId) {
                    obj.selected = true;
                } else {
                    obj.selected = false;
                }
            });
        } else {
            question.answers.other.selected = true;
            question.answers.choices.forEach((obj: any) => {
                obj.selected = false;
            });
        }
    }

    indexTracker(index: number, value: any) {
        return index;
    }

    multipleChoiceOtherChange(question, event) {
        if (question.answers.other) {
            if (!event) {
                question.answers.other.text = '';
            }
        }
    }

    transformFilledAnswers() {
        const submissonAnswerObj = {
            questions: [],
        };

        this.feedbackQuestion.forEach((obj: any) => {
            let questionAnswerObj = {};
            if (obj.questionType === 'fileUpload') {
                if (obj.answers.name !== '' && obj.answers.upload_id !== '') {
                    questionAnswerObj = {
                        question_id: obj.question_id,
                    };
                    questionAnswerObj['answers'] = [
                        {
                            name: obj.answers.name,
                            upload_id: obj.answers.upload_id,
                        },
                    ];
                }
            } else if (obj.questionType === 'singleAnswer' || obj.questionType === 'textArea') {
                if (obj.answers.text !== '') {
                    questionAnswerObj = {
                        question_id: obj.question_id,
                    };
                    questionAnswerObj['answers'] = [{ text: obj.answers.text }];
                }
            } else if (obj.questionType === 'singleChoice') {
                obj.answers.choices.forEach((choice: any) => {
                    if (choice.selected) {
                        questionAnswerObj = {
                            question_id: obj.question_id,
                        };
                        questionAnswerObj['answers'] = [{ choice_id: choice.choice_id }];
                    }
                });
                if (obj.answers['other']) {
                    if (obj.answers['other'].selected === true) {
                        questionAnswerObj = {
                            question_id: obj.question_id,
                        };
                        questionAnswerObj['answers'] = [
                            {
                                other_id: obj.answers.other.other_id,
                                text: obj.answers.other.text,
                            },
                        ];
                    }
                }
            } else if (obj.questionType === 'multipleChoice') {
                obj.answers.choices.forEach((choice: any) => {
                    if (choice.selected) {
                        if (!questionAnswerObj['question_id']) {
                            questionAnswerObj = {
                                question_id: obj.question_id,
                            };
                            questionAnswerObj['answers'] = [{ choice_id: choice.choice_id }];
                        } else {
                            questionAnswerObj['answers'].push({
                                choice_id: choice.choice_id,
                            });
                        }
                    }
                });
                if (obj.answers['other']) {
                    if (obj.answers['other'].selected === true) {
                        if (!questionAnswerObj['question_id']) {
                            questionAnswerObj = {
                                question_id: obj.question_id,
                            };
                            questionAnswerObj['answers'] = [
                                {
                                    other_id: obj.answers.other.other_id,
                                    text: obj.answers.other.text,
                                },
                            ];
                        } else {
                            questionAnswerObj['answers'].push({
                                other_id: obj.answers.other.other_id,
                                text: obj.answers.other.text,
                            });
                        }
                    }
                }
            } else if (obj.questionType === 'multipleTextboxes') {
                obj.answers.rows.forEach((row: any) => {
                    if (row.text !== undefined) {
                        if (!questionAnswerObj['question_id']) {
                            questionAnswerObj = {
                                question_id: obj.question_id,
                            };
                            questionAnswerObj['answers'] = [
                                {
                                    row_id: row.row_id,
                                    text: row.text,
                                },
                            ];
                        } else {
                            questionAnswerObj['answers'].push({
                                row_id: row.row_id,
                                text: row.text,
                            });
                        }
                    }
                });
            }
            if (questionAnswerObj['question_id']) {
                submissonAnswerObj.questions.push(questionAnswerObj);
            }
        });

        return submissonAnswerObj;
    }

    checkAnyFileUploading() {
        const fileUploading = [];
        this.feedbackQuestion.forEach((questionObj: any) => {
            if (questionObj.fileUploading) {
                fileUploading.push(questionObj.question_id);
            }
        });
        return fileUploading;
    }

    checkAllMandatoryQuestion(submissonAnswerObj) {
        const invalid = [];
        for (const mandatoryQuestionId of this.mandatoryQuestionId) {
            const mandatoryQuestionfilled = submissonAnswerObj.questions.find(
                ({ question_id }) => question_id === mandatoryQuestionId,
            );
            if (mandatoryQuestionfilled === undefined) {
                invalid.push(mandatoryQuestionId);
            }
        }
        return invalid;
    }

    feedbackSubmission(feedbackTemplateForm: NgForm) {
        this.feedbackTemplateFormSubmit = true;
        if (!feedbackTemplateForm.valid) {
            return this.snackbarService.errorPop('Please fill all the required field');
        } else if (this.checkAnyFileUploading().length === 0) {
            const body = this.transformFilledAnswers();
            if (this.checkAllMandatoryQuestion(body).length === 0) {
                const options = { show_success: true, show_loader: true };
                this.submitFeedback(body, options);
                console.log(this.feedbackQuestion, body);
            } else {
                return this.snackbarService.errorPop('Please fill mandatory question');
            }
        } else {
            return this.snackbarService.errorPop('Uploading file, please wait...');
        }
    }

    submitFeedback(body: any, options: any) {
        let apiRouteUrl = `admin/nodes/${this.selectedNodeId}/feedback/${this.selectedFeedback.feedback_id}/users/${this.selectedUser.user_id}/response`;
        if (this.modalType === 'bundleSubmitFeedback') {
            apiRouteUrl = `admin/bundles/${this.selectedBundleId}/feedback/${this.selectedFeedback.feedback_id}/users/${this.selectedUser.user_id}/response`;
        }
        this.api.post(apiRouteUrl, body, options).subscribe({
            next: () => {
                this.refresh = true;
                this.activeModal.close(this.refresh);
                this.snackbarService.successPop('Feedback submitted');
            },
            error: (err: any) => {
                if (err.error.code === 3101) {
                    this.refresh = true;
                    this.activeModal.close(this.refresh);
                }
                console.log('Feedback submit error', err);
            },
        });
    }

    cancelChanges() {
        if (this.originalSelected !== JSON.stringify(this.selectedFeedbackTemplate)) {
            this.confirm.show(
                adminConstants.WARN,
                adminConstants.LEAVE_FEEDBACK,
                adminConstants.CNF_LEAVE_MSG,
            );
            this.subscription = this.confirm.observable.subscribe((result) => {
                if (result) {
                    this.activeModal.close();
                }
                this.subscription.unsubscribe();
            });
        } else {
            this.activeModal.close();
        }
    }

    isSupportedFileType(question) {
        return Util.defaultAssetImage(question, this.supportedFeedbackFileTypeArray);
    }

    getFileType(file) {
        const fileFormat = file.name.slice(file.name.lastIndexOf('.') + 1).toLowerCase();
        if (this.supportedImageFormats.indexOf(fileFormat) !== -1) {
            return this.supportedFeedbackFileTypes.IMAGE;
        } else if (fileFormat === 'pdf') {
            return this.supportedFeedbackFileTypes.PDF;
        }
    }
}
