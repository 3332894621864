@if (sessionTimeOutLeftText) {
  <div class="overlay-container">
    <div class="overlay-backdrop"></div>
    <div class="row me-0 ms-0 overlay-wrapper">
      <div class="col-sm p-0 overlay-modal">
        <div class="modal-title text-center">
          <h5>Session Timeout</h5>
        </div>
        <div class="modal-body text-center">
          <p class="mt-2 session-info text-center">
            <span class="mdi mdi-clock-outline me-2"></span>
            Your RapL session will expire in
          </p>
          <h5 class="mt-4 text-center time-out-timer m-4">
            {{ sessionTimeOutLeftText }}
          </h5>
          <p class="mt-2 update-info text-center">Please click "CONTINUE" to extend your session or click "Sign Out" to end your session now.</p>
        </div>
        <div class="modal-footer m-3">
          <button class="btn btn-link text-uppercase mt-2" (click)="closeSessionModal('logout')">Sign Out</button>
          <button class="btn btn-primary mt-2" (click)="closeSessionModal('Continue')">Continue</button>
        </div>
      </div>
    </div>
  </div>
}
