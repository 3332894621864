import { Injectable } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
@Injectable()
export class RemoteConfigService {
    constructor(private remoteConfig: AngularFireRemoteConfig) {}

    public async getStringValueByKey(key: string): Promise<any> {
        return this.remoteConfig
            .getString(key)
            .then((value) => {
                return value;
            })
            .catch((err) => {
                return err;
            });
    }

    public async getNumberValueByKey(key: string): Promise<any> {
        return this.remoteConfig
            .getNumber(key)
            .then((value) => {
                return value;
            })
            .catch((err) => {
                return err;
            });
    }

    public async getBooleanValueByKey(key: string): Promise<any> {
        return this.remoteConfig
            .getBoolean(key)
            .then((value) => {
                return value;
            })
            .catch((err) => {
                return err;
            });
    }
}
