import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnimateNumberPipe } from './animate-number.pipe';
import { DayLeftPipe } from './day-left.pipe';
import { TimeAgoPipe } from './days-ago.pipe';
import { ExtractUTCMonthPipe } from './extract-month.pipe';
import { MomentPipe } from './moment.pipe';

import { HighlightSearch } from './text-highlight';
import { InTimePipe } from './time-in.pipe';
import { TruncateCharactersPipe } from './truncate.pipe';
import { NumberAbbreviationsPipe } from './number-abbreviations .pipe';
import { TransformDatePipe } from './transform-date.pipe';
import { CustomDatePipe } from './custom-date.pipe';

@NgModule({
    declarations: [
        AnimateNumberPipe,
        CustomDatePipe,
        DayLeftPipe,
        ExtractUTCMonthPipe,
        HighlightSearch,
        InTimePipe,
        MomentPipe,
        TransformDatePipe,
        TimeAgoPipe,
        NumberAbbreviationsPipe,
        TruncateCharactersPipe,
    ],
    imports: [CommonModule],
    exports: [
        AnimateNumberPipe,
        CustomDatePipe,
        DayLeftPipe,
        ExtractUTCMonthPipe,
        HighlightSearch,
        InTimePipe,
        MomentPipe,
        TransformDatePipe,
        TimeAgoPipe,
        NumberAbbreviationsPipe,
        TruncateCharactersPipe,
    ],
    providers: [DatePipe],
})
export class MainPipe {}
