import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { ApiService, Util } from '..';

@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject(null);
    token: any;
    constructor(
        private readonly angularFireMessaging: AngularFireMessaging,
        private readonly api: ApiService,
    ) {}

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe((token) => {
            if (token) {
                this.token = token;
                const body: any = {};
                const options = { show_success: false, show_loader: false };
                body.token = token;
                body.source = 'WEB';
                body.app_version = Util.appVersion;
                /* eslint-disable */
                this.api.post('fcm/v1/register', body, options).subscribe({
                    next: () => {},
                    error: (err: any) => {
                        console.log('FCM Register Error', err);
                    },
                });
            }
        });
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe((msg) => {
            console.log('show message!', msg);
            this.currentMessage.next(msg);
        });
    }
}
