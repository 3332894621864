import { HttpBackend, HttpClient, HttpEventType } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { SnackbarService } from 'app/shared/service/snack-bar.service';
import { ApiService } from './api.service';

@Injectable()
export class S3UploadService {
    uploadDoneEvent: EventEmitter<number> = new EventEmitter();
    constructor(
        public api: ApiService,
        public http: HttpClient,
        handler: HttpBackend,
        public snackbarService: SnackbarService,
    ) {
        this.http = new HttpClient(handler);
    }

    gets3UploadUrl(uploadUrl, selectedFile) {
        this.api.get(uploadUrl, { show_success: false, show_loader: false }).subscribe({
            next: (data: any) => {
                this.uploads3(data, selectedFile);
            },
            error: (err: any) => {
                this.emitUploadDoneEvent(err);
                console.log('Get s3 upload url error', err);
            },
        });
    }
    uploads3(uploadData, selectedFile) {
        const inputs: any = {};
        uploadData.form.inputs.forEach((input) => {
            inputs[input.name] = input.value;
        });
        inputs['content-type'] = selectedFile.type;
        inputs['file'] = selectedFile;
        let formData = new FormData();
        for (const key in inputs) {
            if (Object.prototype.hasOwnProperty.call(inputs, key)) {
                const value = inputs[key];
                formData = this.transformObj2Formdata(formData, key, value);
            }
        }
        this.http
            .post(uploadData.form.action, formData, {
                observe: 'events',
            })
            .subscribe({
                next: (data) => {
                    if (data.type === HttpEventType.Response) {
                        this.emitUploadDoneEvent(uploadData);
                    }
                },
                error: (error) => {
                    this.emitUploadDoneEvent(error);
                    this.snackbarService.error('File upload failed. please try again');
                    console.error('There was an error!', error);
                },
            });
    }
    transformObj2Formdata(formData, key, value) {
        formData.append(key, value);
        return formData;
    }
    emitUploadDoneEvent(any) {
        this.uploadDoneEvent.emit(any);
    }
    getUploadDoneEmitter() {
        return this.uploadDoneEvent;
    }
}
