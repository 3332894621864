import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmBoxService } from 'app/confirmbox/confirmbox.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-confirm-component',
    templateUrl: './confirmbox.component.html',
    styleUrls: ['./confirmbox.component.scss'],
})
export class ConfirmBoxComponent {
    public show: boolean;
    showDismissPopUpAnimation = false;
    public constructor(
        public confirm: ConfirmBoxService,
        public translateService: TranslateService,
        public router: Router,
    ) {
        confirm.status.subscribe((status: boolean) => {
            this.show = status;
        });
    }
    confirmButton(accept: boolean) {
        if (!accept) {
            setTimeout(() => {
                this.confirm.confirmed(accept);
                this.showDismissPopUpAnimation = false;
            }, 200);
        } else {
            if (this.confirm.checkBoxValue) {
                this.confirm.confirmed(accept, this.confirm.checkBoxValue);
            } else {
                this.confirm.confirmed(accept);
            }
            this.showDismissPopUpAnimation = false;
        }
    }

    shouldTranslate() {
        return this.router.isActive('/app', false);
    }

    getActionText(dialogType) {
        let dialogAcionType = '';
        switch (dialogType) {
            case 'delete-cancel': {
                dialogAcionType = 'Delete';
                break;
            }
            case 'remove-cancel': {
                dialogAcionType = 'Remove';
                break;
            }
            case 'inactive-cancel': {
                dialogAcionType = 'Inactive';
                break;
            }
            case 'reset-cancel': {
                dialogAcionType = this.translateService.instant('LEARNER.RESET_ALL');
                break;
            }
            default:
                dialogAcionType = 'Archive';
                break;
        }
        return dialogAcionType;
    }
}
