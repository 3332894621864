import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/lang/', '.json');
}

@NgModule({
    exports: [CommonModule, TranslateModule],
    imports: [CommonModule, TranslateModule],
})
export class TransModule {
    static forRoot(): ModuleWithProviders<TranslateModule> {
        return {
            ngModule: TransModule,
        };
    }
}
