import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConstantImage } from 'app/shared/enum/constant-image';

@Component({
    selector: 'app-under-maintenance',
    templateUrl: './under-maintenance.component.html',
    styleUrls: ['./under-maintenance.component.scss'],
})
export class UnderMaintenanceComponent implements OnInit {
    public constantImage = ConstantImage;
    constructor(
        public sanitizer: DomSanitizer,
        public router: Router,
    ) {}
    ngOnInit() {
        localStorage.removeItem('adminHeadertab');
    }
    redirectToProfile() {
        const width = window.innerWidth;
        if (width <= 768) {
            this.router.navigate(['/app/myAccount']);
        } else {
            this.router.navigate(['/app/myAccount/profile']);
        }
    }
}
