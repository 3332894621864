import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ConfirmBoxService } from './confirmbox/confirmbox.service';
import { DataService, Util } from './shared';
import { AuthGuard } from './shared/auth.guard';
import { ApiService } from './shared/service/api.service';
import { PreLoaderService } from './shared/service/preloader.service';
import { SessionTimeoutService } from './shared/service/session-timeout.service';
import { SubDomainService } from './shared/service/sub-domain.service';
import { UpdateService } from './shared/service/update.service';
import { TransService } from './shared/translate/translate.service';
import { RemoteConfigService } from './shared/service/remote-config.service';
import { PreviousRouteService } from './shared/service/previous-route.service';
export let browserRefresh = false;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    browserSubscription: any;
    subscription: any;
    spinnerColor = '#0751C0';
    change$: Observable<any>;
    isStylesLoaded: boolean;
    orgSetting = JSON.parse(localStorage.getItem('orgSetting'));

    constructor(
        public auth: AuthGuard,
        public translate: TransService,
        public api: ApiService,
        public modalService: NgbModal,
        public updateService: UpdateService,
        public confirm: ConfirmBoxService,
        public router: Router,
        public data: DataService,
        public sessionTimeoutService: SessionTimeoutService,
        private readonly metaService: Meta,
        private updates: SwUpdate,
        public remoteConfig: AngularFireRemoteConfig,
        public subDomain: SubDomainService,
        public preLoader: PreLoaderService,
        public remoteConfigService: RemoteConfigService,
        public subDomainService: SubDomainService,
        public previousRouteService: PreviousRouteService,
    ) {
        this.remoteConfig.fetchAndActivate();
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                window.scroll(0, 0);
            }
        });

        this.browserSubscription = router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                browserRefresh = !router.navigated;
            }
        });
        this.updates.checkForUpdate();
    }

    @HostListener('window:beforeprint', ['$event'])
    beforePrint() {
        document.body.classList.add('blur-print');
    }

    @HostListener('window:afterprint', ['$event'])
    afterPrint() {
        document.body.classList.remove('blur-print');
    }

    ngOnInit() {
        const currDateTime = new Date();
        this.updateService.checkForUpdates();
        localStorage.setItem('appInitDateTime', currDateTime.toString());
        this.metaService.addTags([
            { name: 'keywords', content: 'RapL' },
            { name: 'description', content: 'RapL' },
        ]);
        setTimeout(() => {
            this.checkStatus();
        }, 200);
        if (this.auth.isLoggedIn()) {
            this.api.getAuthorizationHeader();
            const currentUser = JSON.parse(localStorage.getItem('current_user'));
            if (JSON.parse(localStorage.getItem('newVersionAvailable'))) {
                this.updateService.handleAppUpdate(this.orgSetting, currentUser);
            }
            this.preLoader.show();
            const userWebSessionTimeout: any = Util.filterArray(
                currentUser.org.settings,
                'property',
                'user_web_session_timeout',
            );
            if (userWebSessionTimeout) {
                localStorage.removeItem('showSessionTimeoutModal');
                this.sessionTimeoutService.detectUserInactivity(userWebSessionTimeout.value);
            }
            window.addEventListener(
                'storage',
                (e) => {
                    if (e.key === 'reloadWebApp') {
                        if (window.localStorage.getItem('reloadWebApp') !== null) {
                            this.updates.activateUpdate().then(() => {
                                localStorage.removeItem('reloadWebApp');
                                document.location.reload();
                            });
                        }
                    }
                },
                false,
            );
            setTimeout(() => {
                localStorage.removeItem('reloadWebApp');
            }, 5000);
        }
        if (this.translate.translateLanguage) {
            this.translate.changeLang(this.translate.translateLanguage);
        }
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.modalService.dismissAll();
                this.confirm.confirmed(false);
            }
        });
        this.appUpdate();
        /* eslint-disable no-unused-expressions */
        window.addEventListener(
            'dragover',
            (e) => {
                e?.preventDefault();
            },
            false,
        );
        window.addEventListener(
            'drop',
            (e) => {
                e?.preventDefault();
            },
            false,
        );
    }

    ngOnDestroy() {
        this.browserSubscription.unsubscribe();
    }

    ngAfterViewInit() {
        const loader = document.getElementById('loader');
        loader.style.display = 'none';
    }

    private checkStatus() {
        window.addEventListener('online', this.updateIndicator);
        window.addEventListener('offline', this.updateIndicator);
    }

    private updateIndicator() {
        const snackToast = document.getElementById('snack-toast');
        const snackImg = document.getElementById('snack-img');
        const snackImgIcon = document.getElementById('snack-img-icon');
        const offlineText = document.getElementById('offlineText');
        const onlineText = document.getElementById('onlineText');
        const mobileSnackBar = document.getElementById('mobile-snackbar');
        const mobileOfflineText = document.getElementById('offline-text');
        const mobileOnlineText = document.getElementById('online-text');
        if (!navigator.onLine) {
            onlineText.className = 'd-none';
            offlineText.className = 'd-block';
            snackToast.className = 'show animate__animated animate__fadeInRight';
            localStorage.setItem('offline', 'true');
            snackImg.className = 'offline-red-color';
            snackImgIcon.className = 'mdi mdi-alert-circle-outline';
            mobileOnlineText.className = 'd-none';
            mobileOfflineText.className = 'd-block';
            mobileSnackBar.className = 'show animate__animated animate__fadeInUp';
        } else if (Util.isOffline()) {
            if (mobileSnackBar !== null) {
                mobileSnackBar.className =
                    'show online-mobile-snackbar animate__animated animate__fadeOutDown';
                mobileOnlineText.className = 'd-block';
                mobileOfflineText.className = 'd-none';
                setTimeout(() => {
                    mobileSnackBar.className = 'd-none';
                }, 600);
            }
            snackImg.className = snackImg.className.replace(
                'offline-red-color',
                'online-green-color',
            );
            snackImgIcon.className = snackImgIcon.className.replace(
                'mdi-alert-circle-outline',
                'mdi-thumb-up-outline',
            );
            onlineText.className = 'd-block';
            offlineText.className = 'd-none';
            setTimeout(() => {
                snackToast.className = 'show animate__animated animate__fadeOutRight';
                localStorage.removeItem('offline');
            }, 500);
        }
    }

    public appUpdate() {
        if (JSON.parse(localStorage.getItem('newVersionAvailable'))) {
            localStorage.setItem('versionAvailableDropDown', 'true');
        }
    }

    showSessionReminderModal() {
        if (localStorage.getItem('showSessionTimeoutModal') === null) {
            return false;
        } else {
            return true;
        }
    }

    isTakeMonitorActive(exact) {
        return this.router.isActive('/monitor', exact);
    }

    isTakeManageActive(exact) {
        return this.router.isActive('/admin', exact);
    }

    isLearnerActive(exact) {
        return this.router.isActive('/app', exact);
    }

    isReviewActive(exact) {
        return this.router.isActive('/review', exact);
    }
}
