import axios from 'axios';
import { LeaderboardTypeBuilder } from 'app/shared//builder/leaderboard-type-builder';
import { Util } from 'app/shared/utils';
import { LanguageService } from 'app/shared/service/language.service';
import { DayService } from 'app/shared/service/day.service';

declare function require(moduleName: string): any;

export class AdminUtil {
    static readonly languageService = new LanguageService();
    static readonly dayService = new DayService();
    static headerTabArr = [];
    static getReleaseTime(rf: any) {
        return new Date(new Date().setUTCHours(rf.hour, rf.minute, 0, 0));
    }

    static setFillLbType(lb): any {
        let fillLb: any;
        lb.forEach(function (obj) {
            const lbType: LeaderboardTypeBuilder = new LeaderboardTypeBuilder(obj).build();
            lbType['enabled'] = false;
            lbType['rollup'] = false;
            fillLb = lbType;
        });
        return fillLb;
    }

    static columnInfo(info: string) {
        return `<span class="info"><span class="mdi mdi-help-circle"></span><span class="info-text">${info}</span></span>`;
    }

    static getUnlockCriteriaList() {
        return [
            {
                criterion: 'ON_TOPIC_MASTERY',
                function(value: any) {
                    if (value) {
                        return 'On quiz mastery';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_NTH_ITERATION',
                function(value: any = 'N') {
                    const iterationCount = value;
                    const iterationText = iterationCount === 1 ? 'iteration' : 'iterations';
                    return `On ${iterationCount} ${iterationText} release`;
                },
                value: 'N',
            },
            {
                criterion: 'ON_MASTERING_NTH_QUESTION',
                function(value: any = 'N') {
                    const questionsCount = value;
                    const questionText = questionsCount === 1 ? 'question' : 'questions';
                    return `On mastering ${questionsCount} ${questionText}`;
                },
                value: 'N',
            },
            {
                criterion: 'ON_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    const daysCount = value;
                    const dayText = daysCount === 1 ? 'day' : 'days';
                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${daysCount} ${dayText} from`;
                    } else {
                        return `On ${daysCount} ${dayText} after release`;
                    }
                },
                value: 'N',
            },
        ];
    }

    static getUnlockCriteriaTestList() {
        return [
            {
                criterion: 'ON_ASSESSMENT_PASS',
                function(value: any) {
                    if (value) {
                        return 'On test pass';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_ASSESSMENT_NTH_ITERATION',
                function(value: any = 'N') {
                    const iterationCount = value;
                    const iterationText = iterationCount === 1 ? 'iteration' : 'iterations';
                    return `On ${iterationCount} ${iterationText} release`;
                },
                value: 'N',
            },
            {
                criterion: 'ON_ASSESSMENT_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    const daysCount = value;
                    const dayText = daysCount === 1 ? 'day' : 'days';

                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${daysCount} ${dayText} from`;
                    } else {
                        return `On ${daysCount} ${dayText} after release`;
                    }
                },
                value: 'N',
            },
        ];
    }

    static getUnlockCriteriaFileList() {
        return [
            {
                criterion: 'ON_FILE_VIEW',
                function(value: any) {
                    if (value) {
                        return 'On file view';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_FILE_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    const daysCount = value;
                    const dayText = daysCount === 1 ? 'day' : 'days';

                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${daysCount} ${dayText} from`;
                    } else {
                        return `On ${daysCount} ${dayText} after release`;
                    }
                },
                value: 'N',
            },
        ];
    }

    static getUnlockCriteriaPollList() {
        return [
            {
                criterion: 'ON_POLL_ATTEMPT',
                function(value: any) {
                    if (value) {
                        return 'On poll response';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_POLL_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    const daysCount = value;
                    const dayText = daysCount === 1 ? 'day' : 'days';

                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${daysCount} ${dayText} from`;
                    } else {
                        return `On ${daysCount} ${dayText} after release`;
                    }
                },
                value: 'N',
            },
        ];
    }

    static getUnlockCriteriaSurveyList() {
        return [
            {
                criterion: 'ON_SURVEY_ATTEMPT',
                function(value: any) {
                    if (value) {
                        return 'On survey response';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_SURVEY_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    const daysCount = value;
                    const dayText = daysCount === 1 ? 'day' : 'days';

                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${daysCount} ${dayText} from`;
                    } else {
                        return `On ${daysCount} ${dayText} after release`;
                    }
                },
                value: 'N',
            },
        ];
    }

    static getUnlockCriteriaAssignmentList() {
        return [
            {
                criterion: 'ON_ASSIGNMENT_FIRST_SUBMISSION',
                function(value: any) {
                    if (value) {
                        return 'On assignment submission';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_ASSIGNMENT_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    const daysCount = value;
                    const dayText = daysCount === 1 ? 'day' : 'days';

                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${daysCount} ${dayText} from`;
                    } else {
                        return `On ${daysCount} ${dayText} after release`;
                    }
                },
                value: 'N',
            },
        ];
    }

    static getUnlockCriteriaULPText() {
        return [
            {
                criterion: 'ON_TOPIC_MASTERY',
                function(value: any) {
                    if (value) {
                        return 'On quiz mastery';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_NTH_ITERATION',
                function(value: any = 'N') {
                    const iterationsCount = value;
                    const iterationText = iterationsCount === 1 ? 'iteration' : 'iterations';
                    return `On ${iterationsCount} ${iterationText} release`;
                },
                value: 'N',
            },
            {
                criterion: 'ON_MASTERING_NTH_QUESTION',
                function(value: any = 'N') {
                    const questionsCount = value;
                    const questionText = questionsCount === 1 ? 'question' : 'questions';
                    return `On mastering ${questionsCount} ${questionText}`;
                },
                value: 'N',
            },
            {
                criterion: 'ON_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    const daysCount = value;
                    const dayText = daysCount === 1 ? 'day' : 'days';

                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${daysCount} ${dayText} from`;
                    } else {
                        return `On ${daysCount} ${dayText} after release`;
                    }
                },
                value: 'N',
            },

            {
                criterion: 'ON_ASSESSMENT_PASS',
                function(value: any) {
                    if (value) {
                        return 'On test pass';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_ASSESSMENT_NTH_ITERATION',
                function(value: any = 'N') {
                    const iterationCount = value;
                    const iterationText = iterationCount === 1 ? 'iteration' : 'iterations';
                    return `On ${iterationCount} ${iterationText} release`;
                },
                value: 'N',
            },
            {
                criterion: 'ON_ASSESSMENT_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    const daysCount = value;
                    const dayText = daysCount === 1 ? 'day' : 'days';

                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${daysCount} ${dayText} from`;
                    } else {
                        return `On ${daysCount} ${dayText} after release`;
                    }
                },
                value: 'N',
            },
            {
                criterion: 'ON_FILE_VIEW',
                function(value: any) {
                    if (value) {
                        return 'On file view';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_FILE_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    const daysCount = value;
                    const dayText = daysCount === 1 ? 'day' : 'days';

                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${daysCount} ${dayText} from`;
                    } else {
                        return `On ${daysCount} ${dayText} after release`;
                    }
                },
                value: 'N',
            },
            {
                criterion: 'ON_POLL_ATTEMPT',
                function(value: any) {
                    if (value) {
                        return 'On poll response';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_POLL_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    const daysCount = value;
                    const dayText = daysCount === 1 ? 'day' : 'days';

                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${daysCount} ${dayText} from`;
                    } else {
                        return `On ${daysCount} ${dayText} after release`;
                    }
                },
                value: 'N',
            },
            {
                criterion: 'ON_SURVEY_ATTEMPT',
                function(value: any) {
                    if (value) {
                        return 'On survey response';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_SURVEY_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    const daysCount = value;
                    const dayText = daysCount === 1 ? 'day' : 'days';

                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${daysCount} ${dayText} from`;
                    } else {
                        return `On ${daysCount} ${dayText} after release`;
                    }
                },
                value: 'N',
            },
            {
                criterion: 'ON_ASSIGNMENT_FIRST_SUBMISSION',
                function(value: any) {
                    if (value) {
                        return 'On assignment submission';
                    }
                },
                value: true,
            },
            {
                criterion: 'ON_ASSIGNMENT_NTH_DAY_AFTER_RELEASE',
                function(value: any = 'N', lpObj?) {
                    const daysCount = value;
                    const dayText = daysCount === 1 ? 'day' : 'days';
                    if (lpObj.valid_from && lpObj.status !== 'DRAFT') {
                        return `On ${daysCount} ${dayText} from`;
                    } else {
                        return `On ${daysCount} ${dayText} after release`;
                    }
                },
                value: 'N',
            },
        ];
    }

    static addNumberToDate(date, daysToBeAdded) {
        const getDate = new Date(date);
        return getDate.setDate(getDate.getDate() + parseInt(daysToBeAdded));
    }

    static convertToBlob(dataURI: any) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = dataURI.split(',')[1];
        }
        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }

    static sortByKey(array, key) {
        return array.sort(function (a, b) {
            const x = a[key];
            const y = b[key];
            return x < y ? -1 : x > y ? 1 : 0;
        });
    }

    static getCurrentStatus(validUntil: any): string {
        const current = new Date();
        const valid = new Date(validUntil);
        
        const currentDate = new Date(
            current.getFullYear(),
            current.getMonth(),
            current.getDate(),
            current.getHours(),
            current.getMinutes(),
            current.getSeconds(),
        );
        
        const validUntilDate = new Date(
            valid.getFullYear(),
            valid.getMonth(),
            valid.getDate(),
            valid.getHours(),
            valid.getMinutes(),
            valid.getSeconds(),
        );
        if (validUntilDate < currentDate) {
            return 'EXPIRED';
        } else {
            return '';
        }
    }

    static getAuthTypeFilters() {
        const currentUser = JSON.parse(localStorage.getItem('current_user'));
        const supportedAuthTypes = Util.filterArray(
            currentUser.org.settings,
            'property',
            'supported_auth_types',
        );
        if (supportedAuthTypes.value?.indexOf('TOKEN') >= 0) {
            const supportedAuths = supportedAuthTypes.value;
            const auths = supportedAuths.split(',');
            const authTypes = auths.filter((type) => type !== 'TOKEN');
            if (authTypes.length !== 0) {
                return authTypes;
            } else {
                return 'DEFAULT';
            }
        } else {
            return supportedAuthTypes.value;
        }
    }

    static isValidURL(value) {
        
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
            value,
        );
    }

    static isValidHour(hourValue: any) {
        return hourValue !== null && hourValue >= 1 && hourValue <= 12 && hourValue !== '';
    }

    static isValidMinute(minuteValue: any) {
        return minuteValue !== null && minuteValue >= 0 && minuteValue <= 59 && minuteValue !== '';
    }

    static surveyThemes() {
        const currentUser = JSON.parse(localStorage.getItem('current_user'));
        const settings = currentUser.org.settings;
        return JSON.parse(Util.filterArray(settings, 'property', 'survey_themes').value);
    }

    static getLanguageViewValue(value: any): string {
        return Util.filterArray(this.languageService.getSupportedLanguage(), 'value', value)
            .viewValue;
    }

    static getLbType(): any {
        const frequencyArr = ['USER', 'GROUP'];
        const lbTypeArr = [];
        frequencyArr.forEach(function (obj) {
            const lbType: LeaderboardTypeBuilder = new LeaderboardTypeBuilder(obj).build();
            if (obj === 'USER') {
                lbType['enabled'] = true;
                lbType['rollup'] = false;
            } else {
                lbType['enabled'] = false;
                lbType['rollup'] = false;
            }
            lbTypeArr.push(lbType);
        });
        return lbTypeArr;
    }

    static getLeaderboardJSON() {
        const currentUser = JSON.parse(localStorage.getItem('current_user'));
        return {
            master_id: 0,
            frequency: 'MONTHLY',
            language: Util.filterArray(currentUser.org.settings, 'property', 'default_language')
                .value,
            enabled: false,
            rollout: false,
            translations: [],
            contexts: this.getLbType(),
            primitive_data: 'GROUP',
            sync: false,
        };
    }

    static getReleaseFreq(releaseFrequency: any) {
        const days = this.dayService.getWeekDays(),
            daysIndex = releaseFrequency.days,
            selectedDays: any = [];
        for (const day of days) {
            const dayIndex = day.index;
            if (daysIndex.indexOf(dayIndex) !== -1) {
                selectedDays.push(day.day);
            }
        }
        return selectedDays;
    }

    static testHoursMinutesArrayfilter(value: any, dropDownValue) {
        const filterValue = value;
        return dropDownValue.filter((option) => option.indexOf(filterValue) === 0);
    }

    static getQuestionType(feedbackQuestion: any) {
        if (feedbackQuestion.family === 'SINGLE_CHOICE' && feedbackQuestion.sub_type === 'RADIO') {
            return 'singleChoice';
        }
        if (
            feedbackQuestion.family === 'MULTI_CHOICE' &&
            feedbackQuestion.sub_type === 'CHECKBOX'
        ) {
            return 'multipleChoice';
        }
        if (feedbackQuestion.family === 'OPEN_ENDED' && feedbackQuestion.sub_type === 'SINGLE') {
            return 'singleAnswer';
        }
        if (feedbackQuestion.family === 'OPEN_ENDED' && feedbackQuestion.sub_type === 'ESSAY') {
            return 'textArea';
        }
        if (feedbackQuestion.family === 'MATRIX' && feedbackQuestion.sub_type === 'SINGLE') {
            return 'multipleTextboxes';
        }
        if (feedbackQuestion.family === 'FILE_UPLOAD' && feedbackQuestion.sub_type === 'SINGLE') {
            return 'fileUpload';
        }
    }
    static SetAdminHeaderTabArr() {
        this.headerTabArr = [
            'ManageGroupRole',
            'ManageUsers',
            'AssignUsers',
            'ManageLeaderboard',
            'ManageContent',
            'AssignContent',
            'InviteUsers',
            'ReportAutomation',
            'BulkImport',
            'Count',
        ];
        let feedbackEnabled;
        const orgSetting = JSON.parse(localStorage.getItem('orgSetting'));

        const feedbackProperty = Util.filterArray(
            orgSetting.settings,
            'property',
            'feedback_enabled',
        );
        if (feedbackProperty) {
            feedbackEnabled = feedbackProperty.value;
            if (feedbackEnabled) {
                this.headerTabArr.push('FeedBack');
            }
        }
    }
    static getAdminHeaderTabIndex(tabName) {
        return this.headerTabArr.indexOf(tabName);
    }
    static getLanguageKey(langObj: any): string {
        const suppotedLang: any = [];
        langObj.filter((transObj) => {
            const getSupportedLanguageKey = Util.filterArray(
                this.languageService.getSupportedLanguage(),
                'value',
                transObj.language,
            ).languageKey;
            suppotedLang.push(getSupportedLanguageKey);
        });
        return suppotedLang;
    }
    static checkForRationale(choices) {
        for (const choice of choices) {
            if (choice.correct) {
                if (choice.translations.length > 0) {
                    if (choice.rationale_assets.length > 0) {
                        return false;
                    } else {
                        const hasDefaultTranslation = choice.translations.some(
                            (translation) => translation.default === true,
                        );
                        if (!hasDefaultTranslation) {
                            return true;
                        }
                        const hasDefaultWithNullRationale = choice.translations.some(
                            (translation) =>
                                translation.default === true && translation.rationale === null,
                        );
                        return hasDefaultWithNullRationale;
                    }
                } else {
                    return choice.rationale_assets.length === 0;
                }
            }
        }
    }

    static extractDaysAndHrs(totalHrs) {
        const hoursPerDay = 24;
        const days = Math.floor(totalHrs / hoursPerDay);
        const hrs = totalHrs % hoursPerDay;
        return `${days > 0 ? days : ''} ${days === 1 ? 'day' : days > 0 ? 'days' : ''} ${hrs > 0 ? hrs : ''} ${hrs === 1 ? 'hour' : hrs > 0 ? 'hours' : ''}`;
    }

    static getHashValue() {
        const url = new URL(window.location.href);
        return url.hash.substring(1);
    }

    static setSecureBackgroundImage(url, className, remoteConfig, size?) {
        const token = JSON.parse(localStorage.getItem('auth_token'));
        const authToken = `${token.token_type} ${token.access_token}`;
        let axiosConfig: any;
        axiosConfig = {
            headers: {
                Authorization: authToken,
            },
            responseType: 'blob',
        };
        axios.get(url, axiosConfig).then((response) => {
            const reader = new window.FileReader();
            reader.readAsDataURL(response.data);
            reader.onload = function () {
                const imageDataUrl: any = reader.result;
                const imageElement: any = document.querySelector(className);
                if (imageElement !== null) {
                    imageElement.style.backgroundImage = "url('" + imageDataUrl + "')";
                }
            };
        });
    }
}
