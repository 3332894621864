import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class TransService {
    translateLanguage = localStorage.getItem('translate_language') || 'EN';
    constructor(public translate: TranslateService) {}
    changeLang(langSelect: any) {
        if (!langSelect) {
            langSelect = 'EN';
        }
        localStorage.setItem('translate_language', langSelect);
        this.translateLanguage = langSelect;
        this.translate.use(langSelect);
    }
    getcurrentLang() {
        return this.translate.currentLang;
    }
}
